<template>
  <v-card>
    <v-card-title primary-title>
      <div class="H5-Primary-Left">Payment Method Exclusions</div>
    </v-card-title>
    <div style="padding-left: 24px; padding-right: 24px;">
      <v-layout row-wrap>
        <v-flex xs4>
          <v-checkbox :disabled="isSectionDisabled" v-model="creditCardProxy" label="Credit Card" />
        </v-flex>
        <v-flex xs4>
          <v-checkbox :disabled="isSectionDisabled" v-model="appleWallet" label="Apple Wallet" />
        </v-flex>
        <v-flex xs4>
          <v-checkbox :disabled="isSectionDisabled" v-model="googleWallet" label="Google Wallet" />
        </v-flex>
      </v-layout>
      <v-flex xs12>
        <div class="H6-Primary-Left">Cashless Pay</div>
        <v-select
          v-model="cashlessProxy"
          attach
          chips
          label="Excluded Tenders"
          multiple
          :items="cashlessTenderTypes"
          :disabled="isSectionDisabled"
        ></v-select>
      </v-flex>
      <v-layout v-if="tenders.length > 0">
        <v-flex xs12>
          <div class="H6-Primary-Left">Meal Plans</div>
          <v-select
            v-model="mealPlanProxy"
            :items="tenders"
            attach
            chips
            label="Excluded tenders"
            multiple
            :disabled="isSectionDisabled"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-layout v-if="swipeTenders.length > 0">
        <v-flex xs12>
          <div class="H6-Primary-Left">Meal Swipes</div>
          <v-select
            v-model="mealSwipesProxy"
            :items="swipeTenders"
            attach
            chips
            label="Excluded swipe tenders"
            multiple
            :disabled="isSectionDisabled"
          ></v-select>
        </v-flex>
      </v-layout>
    </div>
  </v-card>
</template>

<script>
import { WALLET_TYPES, CASHLESS_TENDER_TYPES } from '@/constants';
import { cloneDeep } from 'lodash';

export default {
  data: () => ({
    WALLET_TYPES,
    isTrue: true,
  }),
  props: {
    creditCard: {
      type: Boolean,
      default: false,
    },
    badge_pay: {
      type: Boolean,
    },
    cashless: {
      type: Array,
      default: () => {
        return [];
      },
    },
    digitalWalletPay: {
      default: [],
    },
    excludedTenders: {
      type: Object,
    },
    tenders: {
      default: [],
    },
    swipeTenders: {
      default: [],
    },
    isSectionDisabled: {
      type: Boolean,
    },
  },
  computed: {
    mealSwipesProxy: {
      get() {
        return this.excludedTenders.mealSwipes;
      },
      set(v) {
        this.$emit('updateMealSwipes', v);
      },
    },
    mealPlanProxy: {
      get() {
        return this.excludedTenders.mealplan;
      },
      set(v) {
        this.$emit('updateMealPlan', v);
      },
    },
    creditCardProxy: {
      get() {
        return this.creditCard;
      },
      set(v) {
        this.$emit('update:creditCard', v);
      },
    },
    cashlessProxy: {
      get() {
        return this.cashless;
      },
      set(v) {
        if (v.includes(this.cashlessTenderTypes[0]?.value)) {
          this.$emit('update:badge_pay', true);
        } else {
          this.$emit('update:badge_pay', false);
        }
        this.$emit('update:cashless', v);
      },
    },
    appleWallet: {
      get() {
        return !!(
          this.digitalWalletPay &&
          this.digitalWalletPay.find((w) => w === WALLET_TYPES.APPLE_WALLET)
        );
      },
      set(v) {
        this.updateDigitalWalletPay(v, WALLET_TYPES.APPLE_WALLET);
      },
    },
    googleWallet: {
      get() {
        return !!(
          this.digitalWalletPay &&
          this.digitalWalletPay.find((w) => w === WALLET_TYPES.GOOGLE_WALLET)
        );
      },
      set(v) {
        this.updateDigitalWalletPay(v, WALLET_TYPES.GOOGLE_WALLET);
      },
    },
    cashlessTenderTypes() {
      return CASHLESS_TENDER_TYPES;
    },
  },
  methods: {
    updateDigitalWalletPay(isExcluded, walletType) {
      const digitalWalletPay = cloneDeep(this.digitalWalletPay);
      if (walletType === WALLET_TYPES.APPLE_WALLET) {
        if (isExcluded) {
          // add to array
          if (this.appleWallet) return;
          digitalWalletPay.push(WALLET_TYPES.APPLE_WALLET);
        } else {
          // remove from array
          const indexToRemove = digitalWalletPay.indexOf(WALLET_TYPES.APPLE_WALLET);
          if (indexToRemove < 0) return;
          digitalWalletPay.splice(indexToRemove, 1);
        }
        this.$emit('updateDigitalWalletPay', digitalWalletPay);
        return;
      }

      if (walletType === WALLET_TYPES.GOOGLE_WALLET) {
        if (isExcluded) {
          // add to array
          if (this.googleWallet) return;
          digitalWalletPay.push(WALLET_TYPES.GOOGLE_WALLET);
        } else {
          // remove from array
          const indexToRemove = digitalWalletPay.indexOf(WALLET_TYPES.GOOGLE_WALLET);
          if (indexToRemove < 0) return;
          digitalWalletPay.splice(indexToRemove, 1);
        }
        this.$emit('updateDigitalWalletPay', digitalWalletPay);
      }
    },
  },
};
</script>
