<template>
  <v-card class="v-card-custom">
    <v-layout row wrap fill-height>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12>
            <div class="H5-Primary-Left">CentricOS Setup</div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-layout mt-1 xs12>
          <v-flex style="flex-direction: column;" v-if="!isNoAP3">
            <div>
              <h5 class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
                Use CentricOS menus? (will disable menu assignment below)
              </h5>
            </div>
            <toggle
              :position="'start'"
              class="ml-0"
              v-model="isCentricOSMenuEnabled"
              :disabled="isSectionDisabled"
            />
            <div v-if="enable">
              <v-flex xs12>
                <v-autocomplete
                  v-model="selectedLMGProxy"
                  :loading="isLoading"
                  :search-input.sync="searchMenuInput"
                  item-text="name"
                  item-value="id"
                  :items="allMenus"
                  label="Search CentricOS Menus"
                  :rules="requiredRules"
                  :disabled="isSectionDisabled"
                  :no-data-text="noDataSearchMenuText"
                ></v-autocomplete>
              </v-flex>
            </div>
          </v-flex>

          <!-- Keep only this after cleaning the FF -->
          <v-flex style="flex-direction: column;" v-else>
            <div class="Body-1-Black-High-Emphasis-Center ">
              <span>Centric Menus can now be assigned to a Station from within Centric OS.</span
              ><br /><br />
              <span
                >Once a <b>Local Menu Group</b> is mapped to a site, those brands/menus are
                available in Menu Scheduling.</span
              >
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import rules from '@/rules';
import { featureFlagNames } from '../../../../constants';

export default {
  data: () => ({
    requiredRules: [rules.required('This field is required')],
    isLoading: false,
    menuId: '',
    allMenus: [],
    menuLocalBrands: [],
    searchMenuInput: '',
    searchLocalBrandInput: '',
  }),
  props: {
    enable: {
      type: Boolean,
    },
    isSectionDisabled: {
      type: Boolean,
      required: true,
    },
    selectedLMG: {
      type: String,
      required: false,
    },
    selectedLocalBrand: {
      type: String,
      required: false,
    },
    mappedLocalBrands: {
      type: Object,
      default() {
        // format {ID:{value:'ID',text:'Name'}
        return {};
      },
    },
  },
  computed: {
    ...mapGetters('splitio', ['isFeatureOn']),
    isNoAP3() {
      return this.isFeatureOn(featureFlagNames.centricMenusNoAP3);
    },
    isCentricOSMenuEnabled: {
      get() {
        return this.enable;
      },
      set(b) {
        this.$emit('update:enable', b);
      },
    },
    selectedLMGProxy: {
      get() {
        return this.allMenus.find((menu) => menu.id === this.selectedLMG);
      },
      set(id) {
        this.menuId = id;
        this.$emit('update:selectedLMG', id);
        this.searchLocalBrandInput = '';
      },
    },
    selectedLocalBrandProxy: {
      get() {
        return this.menuLocalBrands.find((brand) => brand.id === this.selectedLocalBrand);
      },
      set(id) {
        this.$emit('update:selectedLocalBrand', id);
      },
    },
    noDataSearchMenuText: {
      get() {
        return this.searchMenuInput
          ? 'No data found for selected input'
          : 'Input search name to see results';
      },
    },
  },
  async mounted() {
    if (this.enable) {
      await this.loadCentricOSMenuInfo();
    }
  },
  methods: {
    ...mapActions('centricOSMenu', [
      'fetchCentricOSMenusByName',
      'fetchLocalBrand',
      'fetchMenuLocalBrandsByName',
      'getMenuLocalMenuGroup',
    ]),
    async loadCentricOSMenuInfo() {
      if (!this.selectedLMG) return;
      const local_menu_group = await this.getMenuLocalMenuGroup({ id: this.selectedLMG });
      this.allMenus = [local_menu_group];
      this.menuId = local_menu_group.id;
    },
  },
  watch: {
    searchMenuInput: debounce(async function searchMenuInput(val = '') {
      if (val === null) return;
      // Items have already been requested
      if (this.isLoading || val.length < 3) return;
      // skip search when input is selected from last search
      if (this.allMenus.find((m) => m.name === val)) return;

      this.isLoading = true;
      this.allMenus = await this.fetchCentricOSMenusByName(val);
      this.isLoading = false;
    }, 500),
    searchLocalBrandInput: debounce(async function searchLocalBrandInput(val = '') {
      if (val === null) return;
      if (!this.menuId) return;
      // Items have already been requested
      if (this.isLoading) return;
      // skip search when input is selected from last search
      if (this.menuLocalBrands.find((brand) => brand.name === val)) return;
      this.isLoading = true;
      this.menuLocalBrands = await this.fetchMenuLocalBrandsByName({
        menu_id: this.menuId,
        name: val,
      });
      this.selectedLocalBrandProxy = undefined;
      this.isLoading = false;
    }, 500),
    enable(newVal) {
      if (newVal) {
        this.loadCentricOSMenuInfo();
      }
    },
  },
};
</script>

<style scoped>
.svg-logo {
  float: left;
  margin-right: 5px;
}

.new-feature-container {
  display: flex;
  border-radius: 5px;
  display: block;
  width: auto;
  padding: 0px 10px;
}
</style>
