import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "v-card-custom" },
    [
      _c(
        "div",
        {
          staticClass: "H5-Primary-Left",
          staticStyle: { "margin-bottom": "24px" }
        },
        [_vm._v("Menu Information")]
      ),
      _c("label-lang", {
        attrs: {
          label: "Calorie Disclaimer",
          disabled: !this.isAdmin,
          value: _vm.disclaimer
        },
        on: {
          change: function($event) {
            return this.$emit("update:disclaimer", _vm.disclaimer)
          }
        },
        model: {
          value: _vm.disclaimer,
          callback: function($$v) {
            _vm.disclaimer = $$v
          },
          expression: "disclaimer"
        }
      }),
      _c(VCheckbox, {
        attrs: {
          label: "Display Calories",
          value: _vm.displayCalories,
          disabled: _vm.isSectionDisabled
        },
        on: {
          change: function($event) {
            return _vm.$emit("update:displayCalories", !!_vm.displayCalories)
          }
        },
        model: {
          value: _vm.displayCalories,
          callback: function($$v) {
            _vm.displayCalories = $$v
          },
          expression: "displayCalories"
        }
      }),
      _c(
        VLayout,
        { attrs: { "mt-1": "", xs12: "", wrap: "" } },
        [
          _c(
            VFlex,
            {
              staticClass: "d-flex",
              staticStyle: { "flex-direction": "column" },
              attrs: { xs6: "" }
            },
            [
              _c("div", [
                _c(
                  "h5",
                  {
                    staticClass:
                      "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
                  },
                  [
                    _vm._v(
                      "\n          Menu Requires Item Promo Exemptions:\n        "
                    )
                  ]
                ),
                _c("p", { staticClass: "note mt-1" }, [
                  _vm._v(
                    "\n          Note: if already set to YES, toggling NO will reset all items back to no promo\n          exemptions and remove the functionality in the local menu\n        "
                  )
                ])
              ]),
              _c("toggle", {
                staticClass: "ml-0",
                staticStyle: { "margin-bottom": "0 !important" },
                attrs: {
                  value: _vm.exemptionsEnabledProxy,
                  position: "start",
                  disabled: _vm.isSectionDisabled
                },
                on: {
                  input: function($event) {
                    return _vm.$emit("update:exemptionsEnabled", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            VFlex,
            {
              staticClass: "d-flex",
              staticStyle: { "flex-direction": "column" },
              attrs: { xs6: "" }
            },
            [
              _c("div", [
                _c(
                  "h5",
                  {
                    staticClass:
                      "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
                  },
                  [_vm._v("\n          Menu Requires Item PLU's:\n        ")]
                ),
                _c("p", { staticClass: "note mt-1" }, [
                  _vm._v(
                    "\n          Note: when set to YES, all items will inherit the PLU fields that exist in the global\n          menu. If necessary a user can override the PLU fields in the local menu. Toggling NO\n          will remove the PLU field from the local menu.\n        "
                  )
                ])
              ]),
              _c("toggle", {
                staticClass: "ml-0",
                staticStyle: { "margin-bottom": "0 !important" },
                attrs: {
                  value: _vm.pluEnabledProxy,
                  position: "start",
                  disabled: _vm.isSectionDisabled
                },
                on: {
                  input: function($event) {
                    return _vm.$emit("update:pluEnabled", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            VFlex,
            {
              staticClass: "d-flex",
              staticStyle: { "flex-direction": "column" },
              attrs: { xs6: "" }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "h5",
                    {
                      staticClass:
                        "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
                    },
                    [_vm._v("\n          Showcase Items:\n        ")]
                  ),
                  _c("p", { staticClass: "note mt-1" }, [
                    _vm._v(
                      "\n          If Item Showcasing is active, disabling this function will hide showcased items in the\n          mobile app.\n        "
                    )
                  ]),
                  _c("toggle", {
                    staticClass: "ml-0",
                    staticStyle: { "margin-bottom": "0 !important" },
                    attrs: {
                      position: "start",
                      disabled: _vm.isSectionDisabled
                    },
                    model: {
                      value: _vm.itemShowcaseEnabledProxy,
                      callback: function($$v) {
                        _vm.itemShowcaseEnabledProxy = $$v
                      },
                      expression: "itemShowcaseEnabledProxy"
                    }
                  })
                ],
                1
              ),
              _c("br"),
              _vm.itemShowcaseEnabledProxy
                ? _c(
                    "div",
                    [
                      _c(
                        "h5",
                        {
                          staticClass:
                            "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
                        },
                        [
                          _vm._v(
                            "\n          Maximum Showcased Items:\n        "
                          )
                        ]
                      ),
                      _c("p", { staticClass: "note mt-1" }, [
                        _vm._v(
                          "\n          Note: maximum number of Items per daypart will show only if showcase items are enabled\n          above\n        "
                        )
                      ]),
                      _c(VTextField, {
                        staticClass: "maxShowcaseItems",
                        attrs: { type: "number", disabled: true },
                        model: {
                          value: _vm.itemMaxShowcaseItemsProxy,
                          callback: function($$v) {
                            _vm.itemMaxShowcaseItemsProxy = $$v
                          },
                          expression: "itemMaxShowcaseItemsProxy"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _c(
            VFlex,
            {
              staticClass: "d-flex",
              staticStyle: { "flex-direction": "column" },
              attrs: { xs6: "" }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "h5",
                    {
                      staticClass:
                        "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
                    },
                    [
                      _vm._v(
                        "\n          Allow Local Item Description Edits:\n        "
                      )
                    ]
                  ),
                  _c("p", { staticClass: "note mt-1" }, [
                    _vm._v(
                      "\n          Note: when set to YES, permitted site operators will be able to override item\n          description in local menus.\n        "
                    )
                  ]),
                  _c("toggle", {
                    staticClass: "ml-0",
                    staticStyle: { "margin-bottom": "0 !important" },
                    attrs: {
                      value: _vm.itemDescEditEnabledProxy,
                      position: "start",
                      disabled: _vm.isSectionDisabled
                    },
                    on: {
                      input: function($event) {
                        return _vm.$emit("update:itemDescEditEnabled", $event)
                      }
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            VFlex,
            {
              staticClass: "d-flex",
              staticStyle: { "flex-direction": "column" },
              attrs: { xs6: "" }
            },
            [
              _c("div", [
                _c(
                  "h5",
                  {
                    staticClass:
                      "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
                  },
                  [_vm._v("\n          Allow Local Menu Images:\n        ")]
                ),
                _c("p", { staticClass: "note mt-1" }, [
                  _vm._v(
                    "\n          Note: when set to YES, local menus will inherit the images that exist in the global\n          menu. Toggling NO will remove the images from the local menu.\n        "
                  )
                ])
              ]),
              _c("toggle", {
                staticClass: "ml-0",
                staticStyle: { "margin-bottom": "0 !important" },
                attrs: {
                  value: _vm.localImagesEnabledProxy,
                  position: "start",
                  disabled:
                    !_vm.globalImagesEnabledProxy || _vm.isSectionDisabled
                },
                on: {
                  input: function($event) {
                    return _vm.$emit("update:localImagesEnabled", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            VFlex,
            {
              staticClass: "d-flex",
              staticStyle: { "flex-direction": "column" },
              attrs: { xs6: "" }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "h5",
                    {
                      staticClass:
                        "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
                    },
                    [
                      _vm._v(
                        "\n          Allow Local Menu Calories Edits:\n        "
                      )
                    ]
                  ),
                  _c("p", { staticClass: "note mt-1" }, [
                    _vm._v(
                      "\n          Note: when set to YES, permitted site managers/delivery managers will be able to\n          override item calories in local menus if they have permissions.\n        "
                    )
                  ]),
                  _c("toggle", {
                    staticClass: "ml-0",
                    staticStyle: { "margin-bottom": "0 !important" },
                    attrs: {
                      value: _vm.caloriesEditEnabledProxy,
                      position: "start",
                      disabled: _vm.isSectionDisabled
                    },
                    on: {
                      input: function($event) {
                        return _vm.$emit("update:caloriesEditEnabled", $event)
                      }
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            VFlex,
            {
              staticClass: "d-flex",
              staticStyle: { "flex-direction": "column" },
              attrs: { xs6: "" }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "h5",
                    {
                      staticClass:
                        "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
                    },
                    [_vm._v("\n          Allow In App Item Naming:\n        ")]
                  ),
                  _c("p", { staticClass: "note mt-1" }, [
                    _vm._v(
                      "\n          Note: when set to YES, permitted site managers/delivery managers will be able to\n          override item names in local menus if they have permissions.\n        "
                    )
                  ]),
                  _c("toggle", {
                    staticClass: "ml-0",
                    staticStyle: { "margin-bottom": "0 !important" },
                    attrs: {
                      value: _vm.itemLabelEditEnabledProxy,
                      position: "start",
                      disabled: _vm.isSectionDisabled
                    },
                    on: {
                      input: function($event) {
                        return _vm.$emit("update:itemLabelEditEnabled", $event)
                      }
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            VFlex,
            {
              staticClass: "d-flex",
              staticStyle: { "flex-direction": "column" },
              attrs: { xs6: "" }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "h5",
                    {
                      staticClass:
                        "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
                    },
                    [_vm._v("\n          Allow Special Requests:\n        ")]
                  ),
                  _c("p", { staticClass: "note mt-1" }, [
                    _vm._v(
                      "\n          Note: When enabling Special Requests, mobile app users will be able to send item-level\n          requests to the kitchen for display on the KDS.\n        "
                    )
                  ]),
                  _c("toggle", {
                    staticClass: "ml-0",
                    staticStyle: { "margin-bottom": "0 !important" },
                    attrs: {
                      value: _vm.specialInstructionsEnabledProxy,
                      position: "start",
                      disabled: _vm.isSectionDisabled
                    },
                    on: {
                      input: function($event) {
                        return _vm.$emit(
                          "update:specialInstructionsEnabled",
                          $event
                        )
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }