<template>
  <v-card class="v-card-custom">
    <v-layout row wrap>
      <v-flex xs12>
        <v-layout justify-space-between>
          <v-flex xs10>
            <div class="H5-Primary-Left">Delivery Details</div>
          </v-flex>
          <v-flex v-if="enable && !isSectionDisabled">
            <v-btn flat class="Button-Primary-Right" color="#0d73d8" @click="dialog = true">
              <v-icon color="#090c9b" class="icon">mdi-cellphone-off</v-icon>DISABLE
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <template v-if="enable">
        <v-text-field
          v-if="enable && timeslotType === DELIVERY_TIMESLOT_TYPES.user_defined.value"
          :value="timeslots.delivery_user_defined ? timeslots.delivery_user_defined.length : 0"
          :rules="userDefinedTimeslotRules"
          class="custom-validation"
        />
        <v-flex v-if="!dh" xs12>
          <v-select
            class="deliveryInputs"
            label="Timeslot Type"
            :items="timeslotTypeOptions"
            v-model="timeslotType"
            :disabled="isSectionDisabled"
          />
        </v-flex>
        <v-flex v-if="dh || timeslotType === DELIVERY_TIMESLOT_TYPES.automatic.value" xs12>
          <v-text-field
            label="Timeslot Length"
            type="number"
            class="deliveryInputs"
            min="0"
            suffix="minutes"
            :rules="timeslotRules"
            v-model="time"
            :disabled="isSectionDisabled"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            label="Average Prep Time"
            type="number"
            class="deliveryInputs"
            min="0"
            suffix="minutes"
            :rules="averageRules"
            v-model="delivery_prep_time"
            :disabled="isSectionDisabled"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            label="Customers Per Slot"
            type="number"
            class="deliveryInputs"
            min="0"
            v-model="deliveryCustomersPerSlot"
            :rules="customerRules"
            :disabled="isSectionDisabled"
          />
        </v-flex>
        <v-flex
          xs12
          v-if="isMarketplaceDisabled || willEnableMarketplaceDelivery"
          class="Subtitle-1-Black-Medium-Emphasis-Left"
        >
          <p>Marketplace delivery settings override these station delivery settings</p>
        </v-flex>
        <v-flex xs12 v-if="timeslotType === DELIVERY_TIMESLOT_TYPES.automatic.value">
          <dropoff-locations
            :selectedLocations="selectedLocations"
            :siteDeliveryDestinations="siteDeliveryDestinations"
            :isTimeslotUserDefined="timeslotType === DELIVERY_TIMESLOT_TYPES.user_defined.value"
            @updateSelectedLocations="handleUpdateDropoffLocations"
            :isBrandSectionDisabled="isDisabled"
          ></dropoff-locations>
        </v-flex>
        <v-flex xs12 v-if="!dh && timeslotType === DELIVERY_TIMESLOT_TYPES.user_defined.value">
          <delivery-timeslots
            :timeslots="timeslots.delivery_user_defined"
            :siteDeliveryDestinations="siteDeliveryDestinations"
            @saveTimeslots="handleUserTimeslotsSave"
            :isBrandSectionDisabled="isSectionDisabled"
          ></delivery-timeslots>
        </v-flex>
        <v-flex xs12>
          <p class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
            Display timeslots as one time?
          </p>
          <toggle
            class="toggle"
            :value="isSingleTimeslot"
            @input="$emit('update:isSingleTimeslot', $event)"
            position="start"
            :disabled="isDisabled"
          />
        </v-flex>
        <v-flex xs12>
          <p class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
            Allow delivery instruction?
          </p>
          <toggle
            class="toggle"
            :value="showInstructions"
            @input="$emit('update:showInstructions', $event)"
            position="start"
            :disabled="isDisabled"
          />
        </v-flex>
        <v-flex xs12>
          <p class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
            Enable Bolter Delivery App?
          </p>
          <toggle
            class="toggle"
            :value="runnerAppEnabled"
            @input="$emit('update:runnerAppEnabled', $event)"
            position="start"
            :disabled="isDisabled"
          />
        </v-flex>
      </template>
      <v-layout v-else justify-center align-center column>
        <template v-if="!siteDeliveryDestinations.length">
          <v-flex xs4>
            <v-img :src="require('./delivery-details-empty-state.svg')" width="215"></v-img>
          </v-flex>
          <v-flex shrink>
            <span class="Body-1-Black-High-Emphasis-Center"
              >There are no drop-off locations set up.</span
            >
          </v-flex>
          <v-flex shrink>
            <span class="Body-1-Black-High-Emphasis-Center"
              >Please go to Site Configuration to add drop-off locations first.</span
            >
          </v-flex>
          <v-flex>
            <v-btn color="primary" :disabled="isSectionDisabled" @click="goToSiteConfig">
              SITE CONFIGURATION
            </v-btn>
          </v-flex>
        </template>
        <template v-else>
          <v-img :src="require('./site-delivery-present.svg')" width="25.67%"></v-img>
          <v-flex shrink>
            <span class="Body-1-Black-High-Emphasis-Center"
              >Drop-off locations are added at the site level.</span
            >
          </v-flex>
          <v-flex shrink>
            <span class="Subtitle-1-Black-Medium-Emphasis-Center"
              >Would you like to setup details?</span
            >
          </v-flex>
          <v-flex>
            <toggle v-model="delivery" :disabled="isSectionDisabled" />
          </v-flex>
        </template>
      </v-layout>
    </v-layout>
    <v-dialog v-model="dialog" width="560" @keydown.esc="dialog = false">
      <disable-delivery
        @dismissDialog="closeDialog"
        @handlePickupDisable="handleDeliveryStatusChange"
        :brand.sync="brand"
        serviceType="delivery"
      ></disable-delivery>
    </v-dialog>
  </v-card>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import rules from '@/rules';
import { DELIVERY_TIMESLOT_TYPES } from '@/constants';
import DeliveryTimeslots from '@/components/BrandSettings/DeliveryTimeslots';
import DropoffLocations from '@/components/BrandSettings/DropoffLocations';
import disableDelivery from '@/components/BrandSettings/DisableServiceDialog';

export default {
  components: {
    'delivery-timeslots': DeliveryTimeslots,
    'dropoff-locations': DropoffLocations,
    'disable-delivery': disableDelivery,
  },
  props: {
    isSectionDisabled: { type: Boolean },
    enable: Boolean,
    selectedLocations: {
      type: Array,
      default: () => [],
    },
    selectedLocationsAutomaticTimeslot: {
      type: Array,
      default: () => [],
    },
    dh: Boolean,
    timeslots: {
      type: Object,
      default: () => ({
        averagePrepTime: '00:00:00',
        time: '00:00:00',
        customers_per_slot: 1,
        delivery_time: '9:12:1',
        delivery_prep_time: '00:00:00',
        delivery_customers_per_slot: 1,
        delivery_menu_items_per_slot: 1,
        delivery_is_user_defined: false,
        delivery_user_defined: [],
      }),
    },
    isSingleTimeslot: {
      type: Boolean,
      default: false,
    },
    isMarketplaceDisabled: {
      type: Boolean,
      default: false,
    },
    showInstructions: {
      type: Boolean,
      default: false,
    },
    runnerAppEnabled: {
      type: Boolean,
      default: false,
    },
    brand: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    siteDeliveryDestinations: Array,
    willEnableMarketplaceDelivery: { type: Boolean },
  },
  data: () => ({
    DELIVERY_TIMESLOT_TYPES,
    dialog: false,
    timeslotRules: [
      rules.required('Timeslot length is required'),
      rules.min(1, 'Timeslot length must be at least 1 minute'),
      rules.max(1000, 'Timeslot length must be no more than 1000 minutes'),
    ],
    averageRules: [
      rules.required('Average Prep length is required'),
      rules.min(1, 'Average Prep length must be at least 1 minute'),
      rules.max(1000, 'Average Prep length must be no more than 1000 minutes'),
    ],
    customerRules: [
      rules.required('Customers per slot is required'),
      rules.min(1, 'Customers per slot must be at least 1'),
      rules.max(100, 'Customers per slot must be no more than 100'),
    ],
    timeslotTypeOptions: [
      {
        text: DELIVERY_TIMESLOT_TYPES.automatic.name,
        value: DELIVERY_TIMESLOT_TYPES.automatic.value,
      },
      {
        text: DELIVERY_TIMESLOT_TYPES.user_defined.name,
        value: DELIVERY_TIMESLOT_TYPES.user_defined.value,
      },
    ],
  }),
  computed: {
    userDefinedTimeslotRules() {
      const eachTimeslotHasLocationRule = () => {
        if (
          this.timeslots.delivery_user_defined.find(
            (timeslot) => !timeslot.delivery_destinations || !timeslot.delivery_destinations.length,
          )
        ) {
          return 'Each user-defined timeslot must have at least one dropoff location.';
        }

        return true;
      };
      return [
        rules.min(1, 'There must be at least one timeslot created'),
        eachTimeslotHasLocationRule,
      ];
    },
    time: {
      get() {
        return this.convertTimeToMinutes(this.timeslots.delivery_time);
      },
      set(v) {
        this.$emit('update:timeslots', {
          ...this.timeslots,
          delivery_time: this.convertMinutesToTime(v),
        });
      },
    },
    delivery_prep_time: {
      get() {
        // Default to average prep time if not set
        return this.convertTimeToMinutes(
          this.timeslots.delivery_prep_time || this.timeslots.averagePrepTime,
        );
      },
      set(v) {
        // DH uses averagePrepTime
        const prepTime = this.dh ? 'averagePrepTime' : 'delivery_prep_time';

        this.$emit('update:timeslots', {
          ...this.timeslots,
          [prepTime]: this.convertMinutesToTime(v),
        });
      },
    },
    deliveryCustomersPerSlot: {
      get() {
        return this.timeslots.delivery_customers_per_slot;
      },
      set(v) {
        this.$emit('update:timeslots', {
          ...this.timeslots,
          delivery_customers_per_slot: this.toNumber(v),
        });
      },
    },
    delivery: {
      get() {
        return this.enabled;
      },
      set(b) {
        this.$emit('update:enable', b);
      },
    },
    timeslotType: {
      get() {
        return this.timeslots.delivery_is_user_defined
          ? DELIVERY_TIMESLOT_TYPES.user_defined.value
          : DELIVERY_TIMESLOT_TYPES.automatic.value;
      },
      set(v) {
        if (
          v === DELIVERY_TIMESLOT_TYPES.user_defined.value &&
          this.timeslots.delivery_user_defined
        ) {
          const selectedLocationsUserGenerated = this.mapTimeslotSelectedLocations(
            this.timeslots.delivery_user_defined,
          );
          this.$emit('update:selectedLocations', selectedLocationsUserGenerated);
        } else if (v === DELIVERY_TIMESLOT_TYPES.automatic.value) {
          this.$emit('update:selectedLocations', this.selectedLocationsAutomaticTimeslot);
        }
        this.$emit('update:timeslots', {
          ...this.timeslots,
          delivery_is_user_defined: v === DELIVERY_TIMESLOT_TYPES.user_defined.value,
        });
      },
    },
    isDisabled() {
      return (
        this.isSectionDisabled || this.isMarketplaceDisabled || this.willEnableMarketplaceDelivery
      );
    },
  },
  methods: {
    modifyTimeslotsOnLocationUpdate(locations, modifications) {
      if (!this.timeslots.delivery_user_defined) return;

      let updTimeslots = cloneDeep(this.timeslots.delivery_user_defined);
      modifications.forEach((modification) => {
        // If the timeslot already has all locations added
        // add this one as well
        if (modification.type === 'add') {
          updTimeslots = updTimeslots.map((timeslot) => {
            if (
              timeslot.delivery_destinations &&
              timeslot.delivery_destinations.length &&
              timeslot.delivery_destinations.length === this.destinations.length
            ) {
              return {
                ...timeslot,
                delivery_destinations: [...timeslot.delivery_destinations, modification.location],
              };
            }

            return timeslot;
          });
        }

        // Remove location from all timeslots that have it
        // Remove timeslot entirely if all locations removed
        // Edge case.. keep if the timeslot never had destinations attached to it
        if (modification.type === 'remove') {
          updTimeslots = updTimeslots
            .map((timeslot) => {
              if (
                timeslot.delivery_destinations &&
                timeslot.delivery_destinations.includes(modification.location)
              ) {
                const index = timeslot.delivery_destinations.findIndex(
                  (location) => location === modification.location,
                );
                timeslot.delivery_destinations.splice(index, 1);
              }

              return timeslot;
            })
            .filter((timeslot) =>
              timeslot.delivery_destinations ? timeslot.delivery_destinations.length : true,
            );
        }
      });

      // rearrange destination within timeslot objects
      updTimeslots = updTimeslots.map((timeslot) => {
        const delivery_destinations = locations.filter((location) =>
          timeslot.delivery_destinations.includes(location),
        );

        return {
          ...timeslot,
          delivery_destinations,
        };
      });
      // Update
      this.$emit('update:timeslots', {
        ...this.timeslots,
        delivery_user_defined: updTimeslots,
      });
    },
    handleUpdateDropoffLocations({ locations }) {
      this.$emit('update:selectedLocations', locations);
      this.$emit('update:selectedLocationsAutomaticTimeslot', locations);
    },
    handleUserTimeslotsSave(newTimeslots) {
      this.$emit('update:timeslots', {
        ...this.timeslots,
        delivery_user_defined: newTimeslots,
      });
      const selectedLocationsUserGenerated = this.mapTimeslotSelectedLocations(newTimeslots);
      this.$emit('update:selectedLocations', selectedLocationsUserGenerated);
    },
    convertTimeToMinutes(time) {
      if (time === null || time === undefined) {
        return '';
      }
      let [hours, minutes] = time.split(':');
      hours = parseInt(hours, 10);
      minutes = parseInt(minutes, 10);
      return hours * 60 + minutes;
    },
    convertMinutesToTime(minutes = 0) {
      let hours = parseInt(minutes / 60, 10);
      if (hours < 10) {
        hours = `0${hours}`;
      }
      let remainingMinutes = minutes % 60;
      if (remainingMinutes < 10) {
        remainingMinutes = `0${remainingMinutes}`;
      }
      return `${hours}:${remainingMinutes}:00`;
    },
    toNumber(value) {
      if (value) {
        return parseInt(value, 10);
      }
      return null;
    },
    closeDialog() {
      this.dialog = false;
    },
    handleDeliveryStatusChange() {
      this.$emit('update:enable', !this.enable);
    },
    goToSiteConfig() {
      this.$router.push({
        name: 'site-settings',
        params: {
          site_id: this.$route.params.site_id,
        },
      });
    },
    mapTimeslotSelectedLocations(timeslots) {
      if (!timeslots) {
        return [];
      }
      const timeslotSelectedLocations = new Set();
      timeslots.forEach((timeslot) => {
        timeslot.delivery_destinations.forEach((delivery_destination) => {
          timeslotSelectedLocations.add(delivery_destination);
        });
      });
      return [...timeslotSelectedLocations];
    },
  },
};
</script>

<style scoped>
.custom-validation {
  margin: 0;
  padding: 8px 0 0 12px;
  position: relative;
  top: -30px;
}

.deliveryInputs {
  max-width: 592px;
}

.hoverline:hover {
  text-decoration: underline;
}

.toggle {
  margin: 0 0 20px !important;
}
.icon {
  margin-right: 10px;
}
</style>
