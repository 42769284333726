<template>
  <v-layout row wrap>
    <v-flex xs9>
      <v-data-table
        class="timeslot-table"
        ref="timeslot-table"
        hide-actions
        :headers="headers"
        :items="timeslots"
        :custom-sort="sortTable"
      >
        <template slot="no-data">
          <p class="Body-2-Black-High-Emphasis-Center">No data available</p>
        </template>
        <template v-slot:items="{ item, index }">
          <tr class="timeslot-row" @click="editTimeslot(item, index)">
            <td>{{ formatTime(item.start_time, item.end_time) }}</td>
            <td v-if="item.delivery_destinations">
              {{ formatLocation(item.delivery_destinations) }}
            </td>
            <td v-else>
              <a
                v-if="!isBrandSectionDisabled"
                class="add-location"
                @click.stop="editTimeslot(item, index)"
                >Add Location</a
              >
            </td>
            <td>
              <v-btn
                v-if="!isBrandSectionDisabled"
                color="primary"
                @click.stop="removeTimeslot(item)"
                flat
              >
                <v-icon class="mr-2" left>mdi-delete-outline</v-icon>Remove
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
    <v-dialog
      v-if="!isBrandSectionDisabled"
      v-model="open"
      width="914px"
      @keydown.esc="handleDialogCancel"
    >
      <template v-slot:activator="{ on }">
        <v-flex xs12 style="padding-bottom: 32px;">
          <v-btn color="primary" v-on="on" width="188px" @click="resetDialog">
            <v-icon left dark>mdi-plus</v-icon>Add Timeslot
          </v-btn>
        </v-flex>
      </template>
      <timeslot-dialog
        @cancelDialog="handleDialogCancel"
        @saveDialog="handleDialogSave"
        :siteDeliveryDestinations="siteDeliveryDestinations"
        :timeslot="activeTimeslot && activeTimeslot.timeslot"
      ></timeslot-dialog>
    </v-dialog>
  </v-layout>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';
import { DateTime } from 'luxon';
import TimeslotDialog from '@/components/BrandSettings/DeliveryTimeslots/Dialog';

export default {
  components: {
    'timeslot-dialog': TimeslotDialog,
  },
  data: () => ({
    open: false,
    activeTimeslot: null,
    headers: [
      {
        text: 'Time Slot',
        align: 'left',
        value: 'timeslot',
      },
      {
        text: 'Drop-off Locations',
        value: 'dropoff_locations',
        align: 'left',
      },
      {
        text: '',
        align: 'left',
        sortable: false,
      },
    ],
  }),
  props: {
    timeslots: {
      type: Array,
      default: () => [],
    },
    siteDeliveryDestinations: {
      type: Array,
      required: true,
    },
    isBrandSectionDisabled: {
      type: Boolean,
    },
  },
  methods: {
    editTimeslot(timeslot, index) {
      if (this.isBrandSectionDisabled) return;
      this.activeTimeslot = {
        timeslot,
        index,
      };
      this.open = true;
    },
    resetDialog() {
      this.open = false;
      this.activeTimeslot = null;
    },
    handleDialogCancel() {
      this.resetDialog();
    },
    handleDialogSave(timeslot) {
      let updTimeslots = cloneDeep(this.$refs['timeslot-table'].filteredItems);

      // Check if we have another timeslot with same time range
      const duplicateIndex = updTimeslots.findIndex((updSlot, idx) => {
        // Don't match with original
        if (this.activeTimeslot && this.activeTimeslot.index === idx) {
          return false;
        }

        return updSlot.start_time === timeslot.start_time && updSlot.end_time === timeslot.end_time;
      });

      // Combine them
      if (duplicateIndex !== -1) {
        const locations = [
          ...(updTimeslots[duplicateIndex].delivery_destinations || []),
          ...timeslot.delivery_destinations,
        ];

        updTimeslots.splice(duplicateIndex, 1, {
          ...updTimeslots[duplicateIndex],
          delivery_destinations: uniq(locations),
        });
      }

      if (!this.activeTimeslot) {
        // Add only if new and uncombined
        if (duplicateIndex === -1) {
          updTimeslots = [...updTimeslots, timeslot];
        }
      } else if (this.activeTimeslot) {
        // Regular edit
        if (duplicateIndex === -1) {
          updTimeslots.splice(this.activeTimeslot.index, 1, timeslot);
        } else {
          // Edit already captured with combining, can delete
          updTimeslots.splice(this.activeTimeslot.index, 1);
        }
      }

      // Save
      this.$emit('saveTimeslots', updTimeslots);
      this.open = false;
      this.activeTimeslot = null;
    },
    removeTimeslot(timeslot) {
      const updatedArr = cloneDeep(this.timeslots);
      const timeslot_idx = this.timeslots.indexOf(timeslot);
      updatedArr.splice(timeslot_idx, 1);
      this.$emit('saveTimeslots', updatedArr);
    },
    formatLocation(destinations = []) {
      if (!destinations.length) {
        return 'Add Location';
      }

      if (destinations.length === 1) {
        return destinations[0];
      }

      if (destinations.length === this.siteDeliveryDestinations.length) {
        return 'All Dropoff Locations';
      }

      return 'Multiple Dropoff Locations';
    },
    formatTime(start_time, end_time) {
      return `
        ${DateTime.fromFormat(start_time, 'HH:mm').toLocaleString(DateTime.TIME_SIMPLE)}
        - ${DateTime.fromFormat(end_time, 'HH:mm').toLocaleString(DateTime.TIME_SIMPLE)}
      `;
    },

    sortTable(items, index, isDescending) {
      if (index === 'timeslot') {
        items.sort((a, b) => {
          if (
            DateTime.fromFormat(b.start_time, 'HH:mm') - DateTime.fromFormat(a.start_time, 'HH:mm')
          ) {
            return (
              DateTime.fromFormat(b.start_time, 'HH:mm') -
              DateTime.fromFormat(a.start_time, 'HH:mm')
            );
          }

          return (
            DateTime.fromFormat(b.end_time, 'HH:mm') - DateTime.fromFormat(a.end_time, 'HH:mm')
          );
        });
      }

      if (index === 'dropoff_locations') {
        items.sort((a, b) => {
          const aLocation = this.formatLocation(a.delivery_destinations).toLowerCase();
          const bLocation = this.formatLocation(b.delivery_destinations).toLowerCase();

          if (aLocation === 'add location') {
            return 1;
          }

          if (bLocation === 'add location') {
            return -1;
          }

          return aLocation.localeCompare(bLocation);
        });
      }

      if (isDescending) {
        items.reverse();
      }

      return items;
    },
  },
};
</script>

<style scoped>
.add-location {
  text-decoration: underline;
}

.custom-validation {
  margin-top: 0;
  padding-top: 0;
}

.timeslot-table {
  width: 100%;
}

.timeslot-table >>> .v-datatable thead tr:first-child {
  border-bottom: 1px solid #d1d3d4 !important;
}

.timeslot-table >>> .v-datatable thead tr:first-child th,
.timeslot-table >>> .v-datatable thead tr:first-child th.column.sortable.active {
  color: #090c9b;
  font-size: 18px;
}

.timeslot-table >>> .v-datatable thead tr:first-child th .v-icon {
  font-size: 24px !important;
  position: relative;
}

.timeslot-table >>> .v-datatable tbody tr.timeslot-row td .v-icon {
  color: #090c9b;
}

.timeslot-table >>> .v-datatable tbody tr.timeslot-row {
  cursor: pointer;
}
</style>
