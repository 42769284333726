<template>
  <v-card class="v-card-custom">
    <v-layout row wrap>
      <v-flex xs12>
        <v-layout justify-space-between>
          <v-flex>
            <div class="H5-Primary-Left">Integration Type</div>
          </v-flex>
          <v-flex shrink v-if="enabled">
            <v-btn :disabled="isSectionDisabled" flat color="secondary" @click="enabled = false">
              <v-icon>mdi-delete-outline</v-icon>remove
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout v-if="enabled" row wrap>
      <v-flex md7>
        <v-layout wrap>
          <v-flex xs12>
            <v-select
              label="KDS Type"
              :items="kdsOptions"
              v-on:input="selectIntegration"
              :value="typeOfKds"
              style="max-width: 500px;"
              :disabled="isSectionDisabled"
            />
            <v-text-field
              label="KDS Device Email"
              style="max-width: 500px;"
              :value="email"
              :browser-autocomplete="'off'"
              @input="$emit('update:email', $event)"
              :rules="emailRules"
              :disabled="isSectionDisabled"
            />
          </v-flex>
        </v-layout>
        <v-layout v-if="isKdsCdl" wrap>
          <v-flex xs12>
            <v-text-field
              label="KDS Admin Passcode"
              style="max-width: 500px;"
              :value="passcode"
              :rules="passcodeRules"
              :browser-autocomplete="'off'"
              @input="$emit('update:passcode', $event)"
              :disabled="isSectionDisabled"
            />
          </v-flex>
          <v-flex xs12>
            <v-text-field
              label="Station Phone Number"
              style="max-width: 500px;"
              mask="(###) ### - ####"
              :rules="numberRules"
              :value="number"
              @input="$emit('update:number', $event)"
              :disabled="isSectionDisabled"
            />
          </v-flex>
          <v-flex xs12>
            <v-text-field
              label="Message On Receipt (Thanks for Ordering) "
              :value="message"
              style="max-width: 500px;"
              @input="$emit('update:message', $event)"
              :disabled="isSectionDisabled"
            />
          </v-flex>
          <v-flex mt-1 xs12>
            <div class="Subtitle-1-Black-Medium-Emphasis-Left">Allow for KDS Refunds</div>
            <toggle
              :disabled="isSectionDisabled"
              :value="refund"
              @input="$emit('update:refund', $event)"
              :position="'start'"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex v-if="isKdsCdl">
        <croppa
          v-model="receiptImage"
          :width="300"
          :height="210"
          :quality="1"
          :prevent-white-space="true"
          remove-button-color="black"
          placeholder="Upload an image for the reciept"
          :placeholder-font-size="12"
          @file-type-mismatch="imageIssue"
          @file-choose="imageChanged"
          @move="imageChanged"
          @zoom="imageChanged"
          @image-remove="imageChanged"
          :disabled="isSectionDisabled"
        >
          <img v-if="!!imageProxy" crossorigin="anonymous" :src="imageProxy" slot="initial" />
        </croppa>
        <div class="Caption-Black-Medium-Emphasis">Receipt Image</div>
      </v-flex>
    </v-layout>
    <v-layout v-show="!enabled" justify-center align-center column>
      <v-flex xs4>
        <v-img :src="require('./kds-img.svg')" width="150"></v-img>
      </v-flex>
      <v-flex shrink>
        <span class="Body-1-Black-High-Emphasis-Center">KDS is not enabled for this brand.</span>
      </v-flex>
      <v-flex shrink>
        <span class="Subtitle-1-Black-Medium-Emphasis-Center">Would you like to setup KDS?</span>
      </v-flex>
      <v-flex>
        <toggle v-model="enabled" :disabled="isSectionDisabled" />
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import rules from '@/rules';
import { TypeOfKds } from '../../../../constants';

export default {
  name: 'KdsInfo',
  props: [
    'email',
    'hasKds',
    'passcode',
    'number',
    'message',
    'image',
    'refund',
    'meta',
    'isSectionDisabled',
  ],
  data: () => ({
    kdsConst: TypeOfKds,
    emailRules: [
      rules.email('KDS Device must be a valid email'),
      // rules.required('Email is required'),
    ],
    kdsOptions: [
      { text: TypeOfKds.cdl.name, value: TypeOfKds.cdl.value },
      { text: TypeOfKds.agilysys.name, value: TypeOfKds.agilysys.value },
      { text: TypeOfKds.volante.name, value: TypeOfKds.volante.value },
      { text: TypeOfKds.nextep.name, value: TypeOfKds.nextep.value },
    ],
    kdsRules: [
      (v) => {
        const msg = 'Integration type is required';
        if (!v) return true;
        if (
          v === TypeOfKds.cdl.value ||
          v === TypeOfKds.agilysys.value ||
          v === TypeOfKds.volante.value ||
          v === TypeOfKds.nextep.value
        )
          return true;
        return msg;
      },
      rules.required('Integration type is required'),
    ],
    passcodeRules: [
      (v) => {
        const msg = 'Passcode must be 4 numbers';
        if (!v) return true;
        if (v.length !== 4) return msg;
        if (!/\d{4}/.test(v)) return msg;
        return true;
      },
      rules.required('Passcode is required'),
    ],
    numberRules: [rules.phoneNumber()],
    receiptImage: {},
    imageProxy: null,
    altered: false,
  }),
  watch: {
    image(value) {
      if (!this.altered) {
        this.imageProxy = value;
      }
      if (value === this.imageProxy) {
        this.altered = false;
        this.imageProxy = value;
        if (this.receiptImage && this.receiptImage.refresh) {
          this.receiptImage.refresh();
        }
      }
    },
  },
  computed: {
    isKdsCdl() {
      return this.meta.has_kds && this.typeOfKds === TypeOfKds.cdl.value;
    },
    typeOfKds: {
      get() {
        if (this.meta.type_of_kds) {
          if (this.meta.type_of_kds.nextep) {
            return TypeOfKds.nextep.value;
          }
          if (this.meta.type_of_kds.agilysys) {
            return TypeOfKds.agilysys.value;
          }
          if (this.meta.type_of_kds.volante) {
            return TypeOfKds.volante.value;
          }
          if (this.meta.type_of_kds.cdl) {
            return TypeOfKds.cdl.value;
          }
        }

        // Default to CDL
        return TypeOfKds.cdl.value;
      },
      set(v) {
        const { type_of_kds = {}, ...meta } = { ...this.meta };
        let { has_kds } = meta;

        // Set all types to false
        has_kds = false;
        type_of_kds.nextep = false;
        type_of_kds.agilysys = false;
        type_of_kds.volante = false;
        type_of_kds.cdl = false;

        switch (v) {
          case TypeOfKds.nextep.value:
            type_of_kds.nextep = true;
            break;
          case TypeOfKds.agilysys.value:
            type_of_kds.agilysys = true;
            break;
          case TypeOfKds.volante.value:
            type_of_kds.volante = true;
            break;
          case TypeOfKds.cdl.value:
            type_of_kds.cdl = true;
            has_kds = true;
            break;
          default:
            console.error('Invalid Integration type specified.');
            break;
        }

        this.$emit('update:meta', { ...meta, has_kds, type_of_kds });
        this.$emit('update:hasKds', has_kds);
      },
    },
    enabled: {
      get() {
        return this.meta.has_kds || Object.values(this.meta.type_of_kds || {}).some((type) => type);
      },
      set(v) {
        const { type_of_kds = {}, ...meta } = { ...this.meta };
        let { has_kds } = meta;

        if (v) {
          has_kds = true;
          type_of_kds.nextep = false;
          type_of_kds.agilysys = false;
          type_of_kds.volante = false;
          type_of_kds.cdl = true;
        } else {
          this.$emit('update:email', null);
          this.$emit('update:passcode', null);
          this.$emit('update:number', null);
          this.$emit('update:message', null);

          type_of_kds.nextep = false;
          type_of_kds.agilysys = false;
          type_of_kds.volante = false;
          type_of_kds.cdl = false;
          has_kds = false;

          if (this.receiptImage.remove) {
            this.receiptImage.remove();
          }
        }
        this.$emit('update:meta', { ...meta, has_kds, type_of_kds });
        this.$emit('update:hasKds', has_kds);
      },
    },
  },
  methods: {
    selectIntegration(id) {
      this.typeOfKds = id;
    },
    imageIssue() {
      this.$toast('Invalid file type, choose a jpg or png file.');
    },
    imageChanged() {
      this.altered = true;
      this.$emit('update:image', `${this.imageProxy}changed`);
    },
    async save() {
      if (!this.altered) return;
      const base64 = this.receiptImage.generateDataUrl();
      if (base64) {
        const { data } = await this.api.post('/file', {
          file_base64: base64.replace('data:image/png;base64,', ''),
        });
        this.$emit('update:image', data.url);
      } else {
        this.$emit('update:image', null);
      }
    },
  },
};
</script>

<style></style>
