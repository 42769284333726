<template>
  <v-card ref="container">
    <v-card-title class="H4-Secondary-Center px-4 pt-4 delivery-timeslots-title"
      >{{ timeslot ? 'Edit' : 'Add New' }} Timeslot</v-card-title
    >
    <v-card-text class="Body-2-Selected-On-Surface-Medium-Emphasis-Left"
      >Set the time range for the timeslot</v-card-text
    >
    <v-card-text class="card-text">
      <v-form ref="timeslot-form" @submit.prevent="">
        <v-form class="timerange-form" ref="timerange-form">
          <v-flex xs3>
            <time-select
              autocomplete
              label="Start Time"
              :value.sync="start_time"
              :rules="startTimeRules"
            ></time-select>
          </v-flex>
          <v-flex xs1>
            <v-layout class="offset" justify-center>
              <v-icon>mdi-arrow-right</v-icon>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <time-select
              autocomplete
              label="End Time"
              :value.sync="end_time"
              :rules="endTimeRules"
            ></time-select>
          </v-flex>
        </v-form>
        <p class="H6-Secondary-Left assign-title">Assign Drop-off Locations</p>
        <p class="Body-2-Selected-On-Surface-Medium-Emphasis-Left assign-subtitle">
          Choose locations to assign to this timeslot.
        </p>
        <assign-dropoff-locations
          :siteDeliveryDestinations="siteDeliveryDestinations"
          :selectedLocations="selectedLocations"
          @updateSelectedLocations="updateSelectedLocations"
        />
        <v-text-field
          disabled
          :value="selectedLocations.length"
          :rules="selectedLocationsRules"
          class="custom-validation"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" flat @click="handleDialogCancel">cancel</v-btn>
      <v-btn color="primary" flat :disabled="isAddButtonDisabled" @click="handleDialogSave">
        {{ timeslot ? 'save' : 'add' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import DateTime from 'luxon/src/datetime.js';
import rules from '@/rules';
import TimeSelect from '@/components/timeSelect.vue';
import AssignDropoffLocations from '@/components/BrandSettings/AssignDropoffLocations';

export default {
  components: { 'time-select': TimeSelect, 'assign-dropoff-locations': AssignDropoffLocations },
  props: {
    timeslot: {
      type: Object,
    },
    siteDeliveryDestinations: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    start_time: null,
    end_time: null,
    selectedLocationsRules: [
      rules.required('At least one dropoff location must be selected'),
      rules.min(1, 'At least one dropoff location must be selected'),
    ],
    selectedLocations: [],
  }),
  computed: {
    startTimeRules() {
      return [rules.required('Select a start time')];
    },
    endTimeRules() {
      return [rules.required('Select a end time'), this.endTimeAfterRule];
    },
    isAddButtonDisabled() {
      // check for valid timeslot and selected location
      if (!this.start_time || !this.end_time) {
        return true;
      }
      const start_time = DateTime.fromFormat(this.start_time, 'HH:mm');
      const end_time = DateTime.fromFormat(this.end_time, 'HH:mm');
      const inValidTimeslot = end_time <= start_time;
      const noLocationsSelected = this.selectedLocations.length === 0;
      return inValidTimeslot || noLocationsSelected;
    },
  },
  methods: {
    endTimeAfterRule() {
      if (!this.start_time || !this.end_time) {
        return false;
      }
      const start_time = DateTime.fromFormat(this.start_time, 'HH:mm');
      const end_time = DateTime.fromFormat(this.end_time, 'HH:mm');
      return end_time > start_time || 'End time cannot be before start time.';
    },
    handleDialogCancel() {
      this.$emit('cancelDialog');
      this.resetValues();
    },
    handleDialogSave() {
      if (this.$refs['timeslot-form'].validate()) {
        this.$emit('saveDialog', {
          start_time: this.start_time,
          end_time: this.end_time,
          delivery_destinations: this.selectedLocations,
        });
        this.resetValues();
      }
    },
    resetValues() {
      this.$refs['timeslot-form'].reset();
      this.$refs['timerange-form'].reset();
      this.start_time = null;
      this.end_time = null;
      this.selectedLocations = [];
    },
    updateSelectedLocations(selectedLocations) {
      this.selectedLocations = selectedLocations;
    },
  },
  watch: {
    timeslot(newTimeslot) {
      if (!newTimeslot) {
        this.resetValues();
        return;
      }
      this.start_time = newTimeslot.start_time;
      this.end_time = newTimeslot.end_time;
      this.selectedLocations = newTimeslot.delivery_destinations || [];
    },
    start_time: {
      async handler() {
        if (this.start_time) {
          await this.$nextTick();
          this.$refs['timerange-form'].validate();
        }
      },
    },
    end_time: {
      async handler() {
        if (this.end_time) {
          await this.$nextTick();
          this.$refs['timerange-form'].validate();
        }
      },
    },
  },
};
</script>

<style scoped>
.custom-validation {
  padding: 0;
}

.offset {
  position: relative;
  top: 10px;
}

.timerange-form {
  display: flex;
  padding-bottom: 8px;
}

.delivery-timeslots-title {
  padding-bottom: 8px;
}

.assign-title {
  margin-bottom: 0;
  padding-bottom: 8px;
}

.assign-subtitle {
  margin-bottom: 24px;
}

.card-text {
  padding-bottom: 0;
}
</style>
