import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "v-card-custom" },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "", "fill-height": "" } },
        [
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(
                VLayout,
                { attrs: { row: "", wrap: "" } },
                [
                  _c(VFlex, { attrs: { xs12: "" } }, [
                    _c("div", { staticClass: "H5-Primary-Left" }, [
                      _vm._v("CentricOS Setup")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(
                VLayout,
                { attrs: { "mt-1": "", xs12: "" } },
                [
                  !_vm.isNoAP3
                    ? _c(
                        VFlex,
                        { staticStyle: { "flex-direction": "column" } },
                        [
                          _c("div", [
                            _c(
                              "h5",
                              {
                                staticClass:
                                  "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
                              },
                              [
                                _vm._v(
                                  "\n              Use CentricOS menus? (will disable menu assignment below)\n            "
                                )
                              ]
                            )
                          ]),
                          _c("toggle", {
                            staticClass: "ml-0",
                            attrs: {
                              position: "start",
                              disabled: _vm.isSectionDisabled
                            },
                            model: {
                              value: _vm.isCentricOSMenuEnabled,
                              callback: function($$v) {
                                _vm.isCentricOSMenuEnabled = $$v
                              },
                              expression: "isCentricOSMenuEnabled"
                            }
                          }),
                          _vm.enable
                            ? _c(
                                "div",
                                [
                                  _c(
                                    VFlex,
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(VAutocomplete, {
                                        attrs: {
                                          loading: _vm.isLoading,
                                          "search-input": _vm.searchMenuInput,
                                          "item-text": "name",
                                          "item-value": "id",
                                          items: _vm.allMenus,
                                          label: "Search CentricOS Menus",
                                          rules: _vm.requiredRules,
                                          disabled: _vm.isSectionDisabled,
                                          "no-data-text":
                                            _vm.noDataSearchMenuText
                                        },
                                        on: {
                                          "update:searchInput": function(
                                            $event
                                          ) {
                                            _vm.searchMenuInput = $event
                                          },
                                          "update:search-input": function(
                                            $event
                                          ) {
                                            _vm.searchMenuInput = $event
                                          }
                                        },
                                        model: {
                                          value: _vm.selectedLMGProxy,
                                          callback: function($$v) {
                                            _vm.selectedLMGProxy = $$v
                                          },
                                          expression: "selectedLMGProxy"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _c(
                        VFlex,
                        { staticStyle: { "flex-direction": "column" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "Body-1-Black-High-Emphasis-Center "
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Centric Menus can now be assigned to a Station from within Centric OS."
                                )
                              ]),
                              _c("br"),
                              _c("br"),
                              _c("span", [
                                _vm._v("Once a "),
                                _c("b", [_vm._v("Local Menu Group")]),
                                _vm._v(
                                  " is mapped to a site, those brands/menus are\n              available in Menu Scheduling."
                                )
                              ])
                            ]
                          )
                        ]
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }