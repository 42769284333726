import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { attrs: { row: "", wrap: "", "fill-height": "" } },
    [
      _c(
        VFlex,
        { attrs: { xs12: "" } },
        _vm._l(_vm.assignedCategories, function(category, index) {
          return _c(
            VLayout,
            { key: index, attrs: { row: "", nowrap: "" } },
            [
              _c(
                VFlex,
                { attrs: { shrink: "" } },
                [
                  !_vm.menuDisabled
                    ? _c(
                        VBtn,
                        {
                          attrs: {
                            disabled: _vm.isBrandSectionDisabled,
                            icon: "",
                            ripple: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.deleteCategory(index)
                            }
                          }
                        },
                        [
                          _c(VIcon, { attrs: { color: "grey lighten-1" } }, [
                            _vm._v("mdi-close")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                VFlex,
                { attrs: { xs6: "" } },
                [
                  _c("assign-category-select", {
                    attrs: {
                      assignedCategories: _vm.assignedCategories,
                      label: "Category Name",
                      placeholder: "",
                      categoryRules: _vm.categoryRules,
                      category_id: category.id,
                      disabled:
                        _vm.isCategoryDisabled(category.id) ||
                        _vm.isBrandSectionDisabled ||
                        _vm.isCentricOS,
                      allCategories: _vm.allCategories
                    },
                    on: {
                      "update:assignedCategories": function($event) {
                        _vm.assignedCategories = $event
                      },
                      "update:assigned-categories": function($event) {
                        _vm.assignedCategories = $event
                      },
                      "update:category_id": function($event) {
                        return _vm.$set(category, "id", $event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c(
        VFlex,
        { attrs: { xs12: "" } },
        [
          !_vm.menuDisabled
            ? _c(
                VBtn,
                {
                  staticClass: "ml-0 pl-0",
                  attrs: {
                    disabled: _vm.isBrandSectionDisabled || _vm.isCentricOS,
                    flat: "",
                    color: "#0d73d8"
                  },
                  on: { click: _vm.addCategory }
                },
                [
                  _c(VIcon, { staticClass: "mr-1" }, [_vm._v("mdi-plus")]),
                  _vm._v("Add category\n    ")
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }