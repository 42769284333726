<template>
  <v-card class="v-card-custom">
    <v-layout row wrap fill-height>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12>
            <div class="H5-Primary-Left">Assign Menus</div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex>
        <loading-wrapper :loading="isLoadingMenus" mt="1vh">
          <v-layout v-if="isScanAndGoToggledOn">
            <v-flex>
              <span class="Subtitle-1-Black-Medium-Emphasis-Center">
                Menu for unit will be auto-assigned for the first time after you save. <br />
                Please allow up to 30 sec to see the change. <br />
                If no menu appears after that, we probably don't have items for this unit, so you'll
                be able to assign menus manually.
              </span>
            </v-flex>
          </v-layout>
          <v-layout v-else row wrap fill-height>
            <v-flex xs12>
              <v-layout row nowrap v-for="(menu, index) in assignedMenus" v-bind:key="index">
                <v-flex xs6>
                  <assign-menu-select
                    :assignedMenus.sync="assignedMenus"
                    label="Menu Name"
                    placeholder
                    :menuRules="menuRules"
                    :menu_id.sync="menu.parent_id"
                    :disabled="isMenuDisabled(menu.parent_id) || isCentricOS"
                    :isCentricOS="isCentricOS"
                    :isBrandSectionDisabled="isSectionDisabled"
                    :allMenus="availableGlobalMenus"
                    :currentBrandMenus="currentBrandMenus"
                    :menuIndex="index"
                    @menu:delete="deleteMenu(index)"
                    ref="assignedMenuSelectComponent"
                  ></assign-menu-select>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 v-if="!isCompany">
              <span class="Body-1-Black-High-Emphasis-Center"
                >Please select a station in Station Information.</span
              >
            </v-flex>
            <v-flex xs12 v-else>
              <v-btn
                :disabled="isSectionDisabled || isCentricOS"
                flat
                color="#0d73d8"
                class="ml-0 pl-0"
                v-on:click="addMenu"
              >
                <v-icon class="mr-1">mdi-plus</v-icon>Add Menu
              </v-btn>
            </v-flex>
            <v-flex xs12 v-if="isCompany">
              <v-layout row wrap>
                <v-flex shrink xs12>
                  <v-btn
                    :disabled="isSectionDisabled || isCentricOS"
                    flat
                    color="#0d73d8"
                    class="ml-0 pl-0"
                    v-on:click="showImportMenuControls"
                  >
                    <v-icon class="mr-1">mdi-plus</v-icon>Import Menu
                  </v-btn>
                </v-flex>
                <v-flex shrink xs4 v-if="showImportMenuDialog"
                  ><v-select
                    :items="importMenusStations"
                    label="Location Stations"
                    placeholder
                    @change="loadMenusForStation"
                    :disabled="isSectionDisabled || isCentricOS"
                  ></v-select>
                </v-flex>
                <v-flex shrink xs4 v-if="showImportMenuDialog"
                  ><v-select
                    :rules="importMenuRules"
                    :items="importMenusSource"
                    label="Menus"
                    placeholder
                    multiple
                    v-model="menusToBeImportedProxy"
                    :disabled="isSectionDisabled || isCentricOS"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </loading-wrapper>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import rules from '@/rules';
import assignMenuSelect from './assignMenuSelect';

export default {
  props: [
    'company',
    'assignedMenus',
    'allMenus',
    'menusToBeImported',
    'showImportMenuDialog',
    'isSectionDisabled',
    'isScanAndGoToggledOn',
    'isLoadingMenus',
    'isCentricOS',
  ],
  data: () => ({
    defaultMenu: {
      id: null,
      groups: [],
    },
    menuRules: [rules.required('Menu is Required')],
    importMenusSource: [],
    importMenuRules: [(v) => v.length > 0 || 'Menu is required'],
  }),
  computed: {
    ...mapGetters('sectors', ['getCompany']),
    isCompany() {
      return this.company || false;
    },
    currentBrandMenus() {
      return this.allMenus.filter(
        (e) => e.location_brand === this.$route.params.brand_id && e.parent_id,
      );
    },
    availableGlobalMenus() {
      let global = cloneDeep(this.allMenus.filter((e) => !e.parent_id));
      // sanity on label
      global = global.map((e) => {
        if (!e.label || !e.label.en) {
          e.label = { en: 'Untitled' };
        }
        if (!e.is || !e.is.disabled) {
          e.is = { disabled: false, hidden: (e.is && e.is.hidden) || false };
        }
        if (!e.is || !e.is.hidden) {
          e.is = { disabled: e.is.disabled, hidden: e.is.hidden || false };
        }
        return {
          value: e.id,
          text: e.label.en,
          is: e.is,
          groups: [],
          allGroups: [],
        };
      });

      return global
        .filter((e) => e.text !== 'Untitled')
        .sort((a, b) => a.text && a.text.localeCompare(b.text));
    },
    companyDetails() {
      if (!this.company) return {};
      const company = this.getCompany(this.company);
      if (!company) return {};
      return company || {};
    },
    menusToBeImportedProxy: {
      get() {
        return this.menusToBeImported;
      },
      async set(v) {
        this.$emit('update:menusToBeImported', v);
      },
    },
    importMenusStations() {
      if (!this.showImportMenuDialog) return [];
      const menusLocationStations = [];
      try {
        this.allMenus.forEach((menu) => {
          const isMenuLinked = menu.parent_id && menu.is && menu.is.linked;
          if (!isMenuLinked) return;
          if (menusLocationStations.find((b) => b && b.id === menu.location_brand)) return;
          const location =
            this.companyDetails &&
            this.companyDetails.locations &&
            this.companyDetails.locations.find((l) =>
              l.brands.find((b) => b && b.id === menu.location_brand),
            );
          if (!location) return;
          location.brands.forEach((station) => {
            if (station.id === this.$route.params.brand_id || station.id !== menu.location_brand)
              return;
            if (!menusLocationStations.find((b) => b && b.id === station.id)) {
              station.location_name = location.label && location.label.en;
              menusLocationStations.push(station);
            }
          });
        });
      } catch (e) {
        console.error(e);
      }
      return menusLocationStations.map((e) => {
        return {
          text: `${e.location_name} - ${e.label.en} - ${e.location_description}`,
          value: e.id,
        };
      });
    },
  },
  methods: {
    isMenuDisabled(menu_id) {
      const menu = this.availableGlobalMenus.find((m) => m.value === menu_id);
      if (!menu) return false;
      return menu.is.disabled;
    },
    async addMenu() {
      this.assignedMenus.push(cloneDeep(this.defaultMenu));
    },
    deleteMenu(index) {
      this.assignedMenus.splice(index, 1);
    },
    async showImportMenuControls() {
      this.$emit('update:showImportMenuDialog', !this.showImportMenuDialog);
      this.$emit('update:menusToBeImported', []);
    },
    async loadMenusForStation(station_id) {
      this.$emit('update:menusToBeImported', []);
      this.importMenusSource = this.allMenus
        .filter(
          (menu) =>
            menu.parent_id && menu.is && menu.is.linked && menu.location_brand === station_id,
        )
        .map((menu) => ({ text: menu.label.en, value: menu.id }));
    },
  },
  components: { assignMenuSelect },
};
</script>

<style></style>
