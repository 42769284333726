<template>
  <v-select
    :items="unassignedCategories"
    :label="label"
    placeholder
    :rules="categoryRules"
    v-model="category_id_sync"
    :disabled="disabled"
  ></v-select>
</template>

<script>
export default {
  props: {
    category_id: String,
    label: String,
    categoryRules: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
    assignedCategories: Array,
    allCategories: Array,
  },
  computed: {
    unassignedCategories() {
      if (!this.allCategories || this.allCategories.length === 0) return [];
      const unassignedCategories = this.allCategories.filter(
        (category) =>
          !this.assignedCategories.find((c) => c.id === category.id) && !category.isDisabled,
      );
      const currentCategory = this.allCategories.find((c) => c.id === this.category_id);
      if (currentCategory) {
        unassignedCategories.push(currentCategory);
      }
      return unassignedCategories;
    },
    category_id_sync: {
      get() {
        return this.category_id;
      },
      set(v) {
        this.$emit('update:category_id', v);
      },
    },
  },
};
</script>
