import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "v-card-custom" },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "", "fill-height": "" } },
        [
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(
                VLayout,
                { attrs: { row: "", wrap: "" } },
                [
                  _c(VFlex, { attrs: { xs12: "" } }, [
                    _c("div", { staticClass: "H5-Primary-Left" }, [
                      _vm._v("Assign Menus")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            VFlex,
            [
              _c(
                "loading-wrapper",
                { attrs: { loading: _vm.isLoadingMenus, mt: "1vh" } },
                [
                  _vm.isScanAndGoToggledOn
                    ? _c(
                        VLayout,
                        [
                          _c(VFlex, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "Subtitle-1-Black-Medium-Emphasis-Center"
                              },
                              [
                                _vm._v(
                                  "\n              Menu for unit will be auto-assigned for the first time after you save. "
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n              Please allow up to 30 sec to see the change. "
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n              If no menu appears after that, we probably don't have items for this unit, so you'll\n              be able to assign menus manually.\n            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    : _c(
                        VLayout,
                        { attrs: { row: "", wrap: "", "fill-height": "" } },
                        [
                          _c(
                            VFlex,
                            { attrs: { xs12: "" } },
                            _vm._l(_vm.assignedMenus, function(menu, index) {
                              return _c(
                                VLayout,
                                { key: index, attrs: { row: "", nowrap: "" } },
                                [
                                  _c(
                                    VFlex,
                                    { attrs: { xs6: "" } },
                                    [
                                      _c("assign-menu-select", {
                                        ref: "assignedMenuSelectComponent",
                                        refInFor: true,
                                        attrs: {
                                          assignedMenus: _vm.assignedMenus,
                                          label: "Menu Name",
                                          placeholder: "",
                                          menuRules: _vm.menuRules,
                                          menu_id: menu.parent_id,
                                          disabled:
                                            _vm.isMenuDisabled(
                                              menu.parent_id
                                            ) || _vm.isCentricOS,
                                          isCentricOS: _vm.isCentricOS,
                                          isBrandSectionDisabled:
                                            _vm.isSectionDisabled,
                                          allMenus: _vm.availableGlobalMenus,
                                          currentBrandMenus:
                                            _vm.currentBrandMenus,
                                          menuIndex: index
                                        },
                                        on: {
                                          "update:assignedMenus": function(
                                            $event
                                          ) {
                                            _vm.assignedMenus = $event
                                          },
                                          "update:assigned-menus": function(
                                            $event
                                          ) {
                                            _vm.assignedMenus = $event
                                          },
                                          "update:menu_id": function($event) {
                                            return _vm.$set(
                                              menu,
                                              "parent_id",
                                              $event
                                            )
                                          },
                                          "menu:delete": function($event) {
                                            return _vm.deleteMenu(index)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          !_vm.isCompany
                            ? _c(VFlex, { attrs: { xs12: "" } }, [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "Body-1-Black-High-Emphasis-Center"
                                  },
                                  [
                                    _vm._v(
                                      "Please select a station in Station Information."
                                    )
                                  ]
                                )
                              ])
                            : _c(
                                VFlex,
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "ml-0 pl-0",
                                      attrs: {
                                        disabled:
                                          _vm.isSectionDisabled ||
                                          _vm.isCentricOS,
                                        flat: "",
                                        color: "#0d73d8"
                                      },
                                      on: { click: _vm.addMenu }
                                    },
                                    [
                                      _c(VIcon, { staticClass: "mr-1" }, [
                                        _vm._v("mdi-plus")
                                      ]),
                                      _vm._v("Add Menu\n            ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                          _vm.isCompany
                            ? _c(
                                VFlex,
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    VLayout,
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        VFlex,
                                        { attrs: { shrink: "", xs12: "" } },
                                        [
                                          _c(
                                            VBtn,
                                            {
                                              staticClass: "ml-0 pl-0",
                                              attrs: {
                                                disabled:
                                                  _vm.isSectionDisabled ||
                                                  _vm.isCentricOS,
                                                flat: "",
                                                color: "#0d73d8"
                                              },
                                              on: {
                                                click:
                                                  _vm.showImportMenuControls
                                              }
                                            },
                                            [
                                              _c(
                                                VIcon,
                                                { staticClass: "mr-1" },
                                                [_vm._v("mdi-plus")]
                                              ),
                                              _vm._v(
                                                "Import Menu\n                "
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.showImportMenuDialog
                                        ? _c(
                                            VFlex,
                                            { attrs: { shrink: "", xs4: "" } },
                                            [
                                              _c(VSelect, {
                                                attrs: {
                                                  items:
                                                    _vm.importMenusStations,
                                                  label: "Location Stations",
                                                  placeholder: "",
                                                  disabled:
                                                    _vm.isSectionDisabled ||
                                                    _vm.isCentricOS
                                                },
                                                on: {
                                                  change:
                                                    _vm.loadMenusForStation
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.showImportMenuDialog
                                        ? _c(
                                            VFlex,
                                            { attrs: { shrink: "", xs4: "" } },
                                            [
                                              _c(VSelect, {
                                                attrs: {
                                                  rules: _vm.importMenuRules,
                                                  items: _vm.importMenusSource,
                                                  label: "Menus",
                                                  placeholder: "",
                                                  multiple: "",
                                                  disabled:
                                                    _vm.isSectionDisabled ||
                                                    _vm.isCentricOS
                                                },
                                                model: {
                                                  value:
                                                    _vm.menusToBeImportedProxy,
                                                  callback: function($$v) {
                                                    _vm.menusToBeImportedProxy = $$v
                                                  },
                                                  expression:
                                                    "menusToBeImportedProxy"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }