<template>
  <v-card class="v-card-custom">
    <v-layout row wrap fill-height>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12>
            <div class="H5-Primary-Left">Third Party Integration</div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-layout mt-1 xs12>
          <v-flex xs6 class="d-flex" style="flex-direction: column;">
            <div>
              <h5 class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
                Request Customer Phone Number at Checkout
              </h5>
            </div>
            <toggle
              :value="getPhoneNumber"
              @input="$emit('update:getPhoneNumber', $event)"
              :position="'start'"
              class="ml-0"
              :disabled="isSectionDisabled"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  props: ['getPhoneNumber', 'isSectionDisabled'],
  computed: {},
};
</script>

<style></style>
