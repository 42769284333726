import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { ref: "container" },
    [
      _c(
        VCardTitle,
        {
          staticClass: "H4-Secondary-Center px-4 pt-4 delivery-timeslots-title"
        },
        [_vm._v(_vm._s(_vm.timeslot ? "Edit" : "Add New") + " Timeslot")]
      ),
      _c(
        VCardText,
        { staticClass: "Body-2-Selected-On-Surface-Medium-Emphasis-Left" },
        [_vm._v("Set the time range for the timeslot")]
      ),
      _c(
        VCardText,
        { staticClass: "card-text" },
        [
          _c(
            VForm,
            {
              ref: "timeslot-form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                VForm,
                { ref: "timerange-form", staticClass: "timerange-form" },
                [
                  _c(
                    VFlex,
                    { attrs: { xs3: "" } },
                    [
                      _c("time-select", {
                        attrs: {
                          autocomplete: "",
                          label: "Start Time",
                          value: _vm.start_time,
                          rules: _vm.startTimeRules
                        },
                        on: {
                          "update:value": function($event) {
                            _vm.start_time = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    { attrs: { xs1: "" } },
                    [
                      _c(
                        VLayout,
                        {
                          staticClass: "offset",
                          attrs: { "justify-center": "" }
                        },
                        [_c(VIcon, [_vm._v("mdi-arrow-right")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    { attrs: { xs3: "" } },
                    [
                      _c("time-select", {
                        attrs: {
                          autocomplete: "",
                          label: "End Time",
                          value: _vm.end_time,
                          rules: _vm.endTimeRules
                        },
                        on: {
                          "update:value": function($event) {
                            _vm.end_time = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("p", { staticClass: "H6-Secondary-Left assign-title" }, [
                _vm._v("Assign Drop-off Locations")
              ]),
              _c(
                "p",
                {
                  staticClass:
                    "Body-2-Selected-On-Surface-Medium-Emphasis-Left assign-subtitle"
                },
                [
                  _vm._v(
                    "\n        Choose locations to assign to this timeslot.\n      "
                  )
                ]
              ),
              _c("assign-dropoff-locations", {
                attrs: {
                  siteDeliveryDestinations: _vm.siteDeliveryDestinations,
                  selectedLocations: _vm.selectedLocations
                },
                on: { updateSelectedLocations: _vm.updateSelectedLocations }
              }),
              _c(VTextField, {
                staticClass: "custom-validation",
                attrs: {
                  disabled: "",
                  value: _vm.selectedLocations.length,
                  rules: _vm.selectedLocationsRules
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        VCardActions,
        [
          _c(VSpacer),
          _c(
            VBtn,
            {
              attrs: { color: "primary", flat: "" },
              on: { click: _vm.handleDialogCancel }
            },
            [_vm._v("cancel")]
          ),
          _c(
            VBtn,
            {
              attrs: {
                color: "primary",
                flat: "",
                disabled: _vm.isAddButtonDisabled
              },
              on: { click: _vm.handleDialogSave }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.timeslot ? "save" : "add") + "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }