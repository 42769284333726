<template>
  <v-card class="v-card-custom">
    <v-layout row wrap fill-height>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12>
            <div class="H5-Primary-Left">Fee Setup</div>
          </v-flex>
        </v-layout>
        <div v-if="isMSOStation">
          <h5 class="Subtitle-1-Black-Medium-Emphasis-Left">
            Marketplace fees are overriding these fees
          </h5>
        </div>
      </v-flex>
      <v-flex xs12>
        <v-layout>
          <v-flex offset-xs3 xs3 class="pa-0">
            <span class="Subtitle-1-Black-Medium-Emphasis-Left">Enable Fee</span>
          </v-flex>
        </v-layout>
        <v-layout wrap align-center>
          <v-flex xs3>
            <span class="Subtitle-1-Black-High-Emphasis-Left">Service Fee</span>
          </v-flex>
          <v-flex xs3>
            <v-checkbox
              v-model="feeEnabled"
              :disabled="isSectionDisabled || isMarketplacePickupEnabled"
            />
          </v-flex>
          <v-flex xs3 v-if="feeEnabled">
            <v-text-field
              :label="label('serviceFeeProxy')"
              type="number"
              :prefix="prefix('serviceFeeProxy')"
              :rules="fieldRules"
              :value="serviceFeeProxy.value"
              @input="onFeeChange('serviceFeeProxy', $event)"
              :disabled="isSectionDisabled || isMarketplacePickupEnabled"
            />
          </v-flex>
        </v-layout>
        <v-layout wrap align-center>
          <v-flex xs3>
            <span class="Subtitle-1-Black-High-Emphasis-Left">Delivery Fee</span>
          </v-flex>
          <v-flex xs3>
            <v-checkbox v-model="deliveryFeeEnabled" :disabled="isDeliveryFeeDisabled" />
          </v-flex>
          <v-flex xs3 v-if="deliveryFeeEnabled">
            <v-text-field
              :label="label('deliveryFeeProxy')"
              type="number"
              :prefix="prefix('deliveryFeeProxy')"
              :rules="deliveryFieldRules"
              :value="deliveryFeeProxy.value"
              @input="onFeeChange('deliveryFeeProxy', $event)"
              :disabled="isDeliveryFeeDisabled"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import rules from '@/rules';

export default {
  props: {
    delivery_fee: { type: Object },
    service_fee: { type: Object },
    isSectionDisabled: { type: Boolean },
    isMSOStation: { type: Boolean },
    isMarketplacePickupEnabled: { type: Boolean },
    isMarketplaceDelEnabled: { type: Boolean },
    brandDeliverySettings: { type: Object },
  },
  data: () => ({
    fee_base: {
      type: 'dollar',
      value: '',
    },
    original_pickup_fee: {
      type: 'dollar',
      value: '',
    },
    original_del_fee: {
      type: 'dollar',
      value: '',
    },
  }),
  watch: {
    isDeliveryFeeDisabled(newIsDelFeeDisabled) {
      if (newIsDelFeeDisabled) {
        this.$emit('update:delivery_fee', this.original_del_fee);
      }
    },
  },
  mounted() {
    this.original_pickup_fee = this.service_fee;
    this.original_del_fee = this.delivery_fee;
  },
  computed: {
    deliveryFeeProxy: {
      get() {
        return this.delivery_fee || this.fee_base;
      },
      set(v) {
        this.$emit('update:delivery_fee', v);
      },
    },
    serviceFeeProxy: {
      get() {
        return this.service_fee || this.fee_base;
      },
      set(v) {
        this.$emit('update:service_fee', v);
      },
    },
    feeEnabled: {
      get() {
        return this.service_fee;
      },
      set(v) {
        if (v) {
          this.$emit('update:service_fee', this.fee_base);
        } else {
          this.service_fee.value = '';
          this.$emit('update:service_fee', null);
        }
      },
    },
    deliveryFeeEnabled: {
      get() {
        return this.delivery_fee;
      },
      set(v) {
        if (v) {
          this.$emit('update:delivery_fee', this.fee_base);
        } else {
          this.delivery_fee.value = '';
          this.$emit('update:delivery_fee', null);
        }
      },
    },
    fieldRules() {
      if (this.serviceFeeProxy.value !== null) {
        return [
          rules.required('Flat service fee is required'),
          rules.min(0.01, 'Service fee must be higher than 0'),
        ];
      }
      return [];
    },
    deliveryFieldRules() {
      if (this.deliveryFeeProxy.value !== null) {
        return [
          rules.required('Flat delivery fee is required'),
          rules.min(0.01, 'Delivery fee must be higher than 0'),
        ];
      }
      return [];
    },
    isDeliveryFeeDisabled() {
      const { isEnabled, isUserDefined } = this.brandDeliverySettings;
      return (
        this.isSectionDisabled || (this.isMarketplaceDelEnabled && isEnabled && !isUserDefined)
      );
    },
  },
  methods: {
    onFeeChange(type, value) {
      this[type] = { ...this[type], value: parseFloat(value, 10) || 0 };
    },
    label(type) {
      if (this[type].value === null) {
        return '$ Flat Fee';
      }
      return 'Flat Fee';
    },
    prefix(type) {
      if (this[type].value === null) {
        return '';
      }
      return '$';
    },
  },
};
</script>

<style></style>
