import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { attrs: { row: "", wrap: "" } },
    [
      _c(
        VFlex,
        { attrs: { shrink: "", xs2: "" } },
        [
          _c(
            VBtn,
            {
              attrs: {
                disabled: _vm.isBrandSectionDisabled,
                icon: "",
                ripple: ""
              },
              on: {
                click: function($event) {
                  return _vm.$emit("menu:delete")
                }
              }
            },
            [
              _c(VIcon, { attrs: { color: "grey lighten-1" } }, [
                _vm._v("mdi-close")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        VFlex,
        { attrs: { xs10: "" } },
        [
          _c(VSelect, {
            attrs: {
              items: _vm.getMenusToDisplay,
              label: _vm.label,
              placeholder: "",
              rules: _vm.menuRules,
              disabled:
                _vm.disabled || _vm.isBrandSectionDisabled || _vm.isCentricOS
            },
            model: {
              value: _vm.menu_id_sync,
              callback: function($$v) {
                _vm.menu_id_sync = $$v
              },
              expression: "menu_id_sync"
            }
          })
        ],
        1
      ),
      _c(
        VFlex,
        { attrs: { xs12: "" } },
        [
          _vm.isMenuSelected
            ? _c(
                VLayout,
                { attrs: { row: "", wrap: "", "justify-end": "" } },
                [
                  _c(
                    VFlex,
                    { attrs: { xs10: "" } },
                    [
                      _c("assign-categories", {
                        ref: "assignedCategoriesComponent",
                        attrs: {
                          assignedCategories: this.assignedMenus[this.menuIndex]
                            .groups,
                          allCategories: this.assignedMenus[this.menuIndex]
                            .allGroups,
                          isBrandSectionDisabled: _vm.isBrandSectionDisabled,
                          menuDisabled: _vm.isMenuDisabled,
                          isCentricOS: _vm.isCentricOS
                        },
                        on: {
                          "update:assignedCategories": function($event) {
                            return _vm.$set(
                              this.assignedMenus[this.menuIndex],
                              "groups",
                              $event
                            )
                          },
                          "update:assigned-categories": function($event) {
                            return _vm.$set(
                              this.assignedMenus[this.menuIndex],
                              "groups",
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }