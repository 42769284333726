<template>
  <v-card>
    <v-card-title class="H4-Secondary-Center dropoff-title"
      >Disable
      {{ serviceType.charAt(0).toUpperCase() + serviceType.slice(1) }} Program</v-card-title
    >
    <v-card-text>
      <v-flex xs12>
        <p class="Body-1-Black-High-Emphasis-Left">
          Disabling {{ serviceType }} saves your settings and can be undone. {{ brand.name }} at
          {{ brand.address.address }} will no longer provide {{ serviceType }} services to our
          customers.
        </p>
      </v-flex>
      <v-flex xs12>
        <p class="Body-1-Black-High-Emphasis-Left">
          To continue with removal please enter DISABLE in the field below and continue.
        </p>
      </v-flex>
      <v-flex xs6>
        <div>
          <v-form ref="confirmationRef">
            <v-text-field
              v-model="confirmationString"
              label="Confirm Disabling"
              outline
              flat
              :rules="rules"
            ></v-text-field>
          </v-form>
        </div>
      </v-flex>
      <v-layout> </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" flat @click="handleDialogCancel">cancel</v-btn>
      <v-btn color="primary" flat @click="disablePickup">save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import rules from '@/rules';

export default {
  props: {
    open: Boolean,
    brand: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    serviceType: {
      type: String,
      required: true,
      default: '',
    },
  },
  data: () => ({
    confirmationString: '',
  }),
  computed: {
    rules() {
      const incorrectString = (v) => {
        if (v !== 'DISABLE') {
          return 'Must input the word "DISABLE" ';
        }

        return true;
      };

      const deliveryDisabled = () => {
        if (
          (this.brand.is.delivery_supported && !this.brand.is.pickup_supported) ||
          (!this.brand.is.delivery_supported && this.brand.is.pickup_supported)
        ) {
          return 'Cannot disable both delivery and pickup services';
        }
        return true;
      };

      return [
        rules.required('Confirmation cannot be left empty.'),
        incorrectString,
        deliveryDisabled,
      ];
    },
  },
  methods: {
    disablePickup() {
      if (this.$refs.confirmationRef.validate()) {
        this.handleDialogSave();
      }
      return null;
    },

    handleDialogCancel() {
      this.$emit('dismissDialog');
      this.$refs.confirmationRef.reset();
      return null;
    },
    handleDialogSave() {
      this.$emit('handlePickupDisable');
      this.$emit('dismissDialog');
      this.$refs.confirmationRef.reset();
      return null;
    },
  },
};
</script>
