<template>
  <v-card class="v-card-custom">
    <v-layout row wrap fill-height>
      <v-flex xs12 md7>
        <v-layout row wrap>
          <v-flex xs12>
            <div class="H5-Primary-Left">Station Information</div>
          </v-flex>
          <v-flex xs12 v-if="!dh">
            <v-select
              :items="sectors"
              label="Group"
              placeholder
              :value="sector"
              :rules="sectorRules"
              :disabled="!isNew || isSectionDisabled"
              @input="$emit('update:sector', $event)"
            ></v-select>
          </v-flex>
          <v-flex xs12 v-if="!dh">
            <v-select
              :items="companies"
              label="Global Menu Assignation"
              placeholder
              :value="company"
              :rules="companyRules"
              :disabled="!isNew || isSectionDisabled"
              @input="$emit('update:company', $event)"
            ></v-select>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              label="Station Name"
              :value="name"
              @input="$emit('update:name', $event)"
              :rules="nameRules"
              :disabled="dh || isSectionDisabled"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              ref="description"
              label="Station Location Description"
              :value="desc"
              :disabled="isSectionDisabled"
              @input="$emit('update:desc', $event)"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-select
              :items="locations"
              v-model="costCentreProxy"
              :disabled="!isNew || isSectionDisabled"
              :rules="costCentreRules"
              label="Location [Cost Centre]"
            ></v-select>
          </v-flex>
          <v-flex xs12 v-if="!dh && isCanadianSite && displayJDECategory">
            <v-select
              :items="jdeCategories"
              item-text="name"
              item-value="id"
              label="JDE Category"
              placeholder
              :value="jdeCategory"
              :disabled="!isCompany || isSectionDisabled"
              @input="$emit('update:jdeCategory', $event)"
            ></v-select>
          </v-flex>
          <v-flex xs12>
            <v-layout row wrap>
              <v-flex xs6>
                <v-text-field
                  type="number"
                  label="Tax Rate"
                  :value="tax"
                  v-model="taxProxy"
                  suffix="%"
                  style="max-width: 100px;"
                  min="0"
                  :rules="taxRules"
                  persistent-hint
                  :disabled="isSectionDisabled"
                  :hint="taxrate ? `Default Tax Rate is ${(taxrate * 100).toFixed(2)}%` : ''"
                ></v-text-field>
              </v-flex>
              <v-flex xs6></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 md5>
        <v-layout
          row
          wrap
          class="phonepreview"
          :style="{
            marginTop: '30px',
            backgroundImage: 'url(' + require('@/assets/i-phone-xs-max.svg') + ')',
          }"
        >
          <v-flex xs12 md12>
            <v-layout row warp justify-center style="margin-top: 60px;">
              <croppa
                v-model="mycroppa"
                :width="250"
                :height="117"
                :quality="3"
                :prevent-white-space="true"
                remove-button-color="black"
                placeholder="Upload an image for mobile"
                :placeholder-font-size="12"
                :disabled="dh || isSectionDisabled"
                :show-remove-button="!dh"
                @file-type-mismatch="imageIssue"
                @file-choose="imageChanged"
                @move="imageChanged"
                @zoom="imageChanged"
                @image-remove="imageRemove"
              >
                <img
                  v-if="!!image"
                  :crossorigin="anonymous"
                  :src="image"
                  slot="initial"
                  alt="brand image"
                />
              </croppa>
            </v-layout>
          </v-flex>
          <v-flex xs12 md12>
            <v-layout row warp justify-center>
              <div style="width: 250px;">
                <span class="H5-Black-High-Emphasis-Left">{{ name }}</span>
                <br />
                <span class="H6-Black-Medium-Emphasis-Left">{{ desc }}</span>
              </div>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { DEFAULT_JDECONFIG_ID } from '@/constants';
import rules from '@/rules';
import getStage from '../../../../store/modules/adminPanel.js';

export default {
  props: [
    'name',
    'desc',
    'tax',
    'taxrate',
    'imagestyle',
    'dh',
    'company',
    'isNew',
    'sector',
    'locationID',
    'isSectionDisabled',
    'jdeCategory',
  ],
  data: () => ({
    nameRules: [rules.required('Station name is required')],
    taxRules: [rules.min('0', 'Must be equal or greater than 0')],
    companyRules: [rules.required('You must assign a global menu')],
    sectorRules: [rules.required('Group is Required')],
    costCentreRules: [rules.required('Location [Cost Centre] is Required')],
    mycroppa: {},
    altered: false,
    jdeCategories: [],
  }),
  watch: {
    imagestyle() {
      this.mycroppa.refresh();
    },
    sector: {
      handler(sector_id) {
        if (!sector_id) return;
        this.$store.dispatch('sectors/fetchSector', { sector_id, expanded: true });
        if (this.isNew) {
          this.$emit('company', '');
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('sectors', ['mappedSectors', 'getMappedSector']),
    ...mapState('sites', ['active_brand', 'active_site']),
    sectors() {
      if (this.isNew) {
        return this.mappedSectors;
      }
      if (!this.brandSector) return [];
      return [this.brandSector];
    },
    brandSector() {
      return this.getMappedSector(this.sector);
    },
    companies() {
      return (this.brandSector && this.brandSector.companies) || [];
    },
    costCentreProxy: {
      get() {
        return this.locationID;
      },
      set(v) {
        this.$emit('update:locationID', v);
      },
    },
    taxProxy: {
      get() {
        if (this.tax === 0) return 0;
        if (!this.tax) return null;
        return parseFloat((this.tax * 100).toFixed(4));
      },
      set(v) {
        if (v === '') {
          this.$emit('update:tax', null);
        } else {
          this.$emit('update:tax', parseFloat((v / 100).toFixed(6)));
        }
      },
    },
    image() {
      if (!this.imagestyle.logo || this.imagestyle.logo === '--image---') {
        return null;
      }

      return this.imagestyle.logo;
    },
    anonymous() {
      if (this.dh) return false;
      return 'anonymous';
    },
    locations() {
      if (!this.active_site || !this.active_site.locations) return [];
      return this.active_site.locations.map((e) => ({
        text: `${e.name} [${(e.meta && e.meta.unit) || 'not yet assigned'}]`,
        value: e.id,
      }));
    },
    currentLocation() {
      return this.$store.state.sites.brandMap[this.active_brand.id].parentLocation;
    },
    isCompany() {
      return this.company || this.company !== '';
    },
    isCanadianSite() {
      if (this.active_site && this.active_site.address && this.active_site.address.country) {
        return this.active_site.address.country === 'CA';
      }
      return false;
    },
    displayJDECategory() {
      return getStage.state.stage === 'v1';
    },
  },
  methods: {
    imageChanged() {
      this.$set(this.imagestyle, 'main_logo', `--changed${new Date().valueOf()}--`);
      this.altered = true;
    },
    imageRemove() {
      this.altered = true;
      this.$emit('update:imagestyle', { logo: '', main_logo: '', alt_logo: '' });
    },
    imageIssue() {
      this.$toast('Invalid file type, choose a jpg or png file.');
    },
    async save() {
      // determine if location has been changed
      if (this.altered) {
        const base64 = this.mycroppa.generateDataUrl();
        if (base64) {
          const { data } = await this.api.post('/file', {
            file_base64: base64.replace('data:image/png;base64,', ''),
          });
          this.$emit('update:imagestyle', {
            logo: data.url,
            main_logo: data.url,
            alt_logo: data.url,
          });
        } else {
          this.$emit('update:imagestyle', { ...this.imagestyle, main_logo: this.imagestyle.logo });
        }
      }
    },
    async getJDECategories() {
      if (!this.isCanadianSite || getStage.state.stage !== 'v1') return;
      try {
        const { data } = await this.api.get(`/config/${DEFAULT_JDECONFIG_ID.ID}`);
        this.jdeCategories = [...data.jde_categories];
      } catch (err) {
        this.$toast.error('Could not load JDE Categories from Config.');
        console.error('Error With JDE Categories', err);
      }
    },
  },
  async mounted() {
    this.mycroppa.refresh();
    this.getJDECategories();
    if (this.isNew) {
      this.$store.dispatch('sectors/fetchSectors');
    }
    if (this.$route.query.focus) {
      if (this.$route.query.focus === 'location_description') {
        this.$refs.description.isFocused = true;
      } else {
        console.error('Unsupported value for focus query parameter');
      }
    }
  },
};
</script>

<style>
.phonepreview {
  background-position: center top;
  margin-top: 10px;
}
</style>
