import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "v-card-custom" },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "", "fill-height": "" } },
        [
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(
                VLayout,
                { attrs: { row: "", wrap: "" } },
                [
                  _c(VFlex, { attrs: { xs12: "" } }, [
                    _c("div", { staticClass: "H5-Primary-Left" }, [
                      _vm._v("Fee Setup")
                    ])
                  ])
                ],
                1
              ),
              _vm.isMSOStation
                ? _c("div", [
                    _c(
                      "h5",
                      { staticClass: "Subtitle-1-Black-Medium-Emphasis-Left" },
                      [
                        _vm._v(
                          "\n          Marketplace fees are overriding these fees\n        "
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(
                VLayout,
                [
                  _c(
                    VFlex,
                    {
                      staticClass: "pa-0",
                      attrs: { "offset-xs3": "", xs3: "" }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "Subtitle-1-Black-Medium-Emphasis-Left"
                        },
                        [_vm._v("Enable Fee")]
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                VLayout,
                { attrs: { wrap: "", "align-center": "" } },
                [
                  _c(VFlex, { attrs: { xs3: "" } }, [
                    _c(
                      "span",
                      { staticClass: "Subtitle-1-Black-High-Emphasis-Left" },
                      [_vm._v("Service Fee")]
                    )
                  ]),
                  _c(
                    VFlex,
                    { attrs: { xs3: "" } },
                    [
                      _c(VCheckbox, {
                        attrs: {
                          disabled:
                            _vm.isSectionDisabled ||
                            _vm.isMarketplacePickupEnabled
                        },
                        model: {
                          value: _vm.feeEnabled,
                          callback: function($$v) {
                            _vm.feeEnabled = $$v
                          },
                          expression: "feeEnabled"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.feeEnabled
                    ? _c(
                        VFlex,
                        { attrs: { xs3: "" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              label: _vm.label("serviceFeeProxy"),
                              type: "number",
                              prefix: _vm.prefix("serviceFeeProxy"),
                              rules: _vm.fieldRules,
                              value: _vm.serviceFeeProxy.value,
                              disabled:
                                _vm.isSectionDisabled ||
                                _vm.isMarketplacePickupEnabled
                            },
                            on: {
                              input: function($event) {
                                return _vm.onFeeChange(
                                  "serviceFeeProxy",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                VLayout,
                { attrs: { wrap: "", "align-center": "" } },
                [
                  _c(VFlex, { attrs: { xs3: "" } }, [
                    _c(
                      "span",
                      { staticClass: "Subtitle-1-Black-High-Emphasis-Left" },
                      [_vm._v("Delivery Fee")]
                    )
                  ]),
                  _c(
                    VFlex,
                    { attrs: { xs3: "" } },
                    [
                      _c(VCheckbox, {
                        attrs: { disabled: _vm.isDeliveryFeeDisabled },
                        model: {
                          value: _vm.deliveryFeeEnabled,
                          callback: function($$v) {
                            _vm.deliveryFeeEnabled = $$v
                          },
                          expression: "deliveryFeeEnabled"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.deliveryFeeEnabled
                    ? _c(
                        VFlex,
                        { attrs: { xs3: "" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              label: _vm.label("deliveryFeeProxy"),
                              type: "number",
                              prefix: _vm.prefix("deliveryFeeProxy"),
                              rules: _vm.deliveryFieldRules,
                              value: _vm.deliveryFeeProxy.value,
                              disabled: _vm.isDeliveryFeeDisabled
                            },
                            on: {
                              input: function($event) {
                                return _vm.onFeeChange(
                                  "deliveryFeeProxy",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }