<template>
  <v-card class="v-card-custom">
    <div class="H5-Primary-Left" style="margin-bottom: 24px">Menu Information</div>
    <label-lang
      label="Calorie Disclaimer"
      v-model="disclaimer"
      :disabled="!this.isAdmin"
      :value="disclaimer"
      @change="this.$emit('update:disclaimer', disclaimer)"
    />
    <v-checkbox
      label="Display Calories"
      v-model="displayCalories"
      :value="displayCalories"
      :disabled="isSectionDisabled"
      @change="$emit('update:displayCalories', !!displayCalories)"
    ></v-checkbox>
    <v-layout mt-1 xs12 wrap>
      <v-flex xs6 class="d-flex" style="flex-direction: column;">
        <div>
          <h5 class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
            Menu Requires Item Promo Exemptions:
          </h5>
          <p class="note mt-1">
            Note: if already set to YES, toggling NO will reset all items back to no promo
            exemptions and remove the functionality in the local menu
          </p>
        </div>
        <toggle
          :value="exemptionsEnabledProxy"
          @input="$emit('update:exemptionsEnabled', $event)"
          :position="'start'"
          class="ml-0"
          style="margin-bottom: 0 !important;"
          :disabled="isSectionDisabled"
        />
      </v-flex>
      <v-flex xs6 class="d-flex" style="flex-direction: column;">
        <div>
          <h5 class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
            Menu Requires Item PLU's:
          </h5>
          <p class="note mt-1">
            Note: when set to YES, all items will inherit the PLU fields that exist in the global
            menu. If necessary a user can override the PLU fields in the local menu. Toggling NO
            will remove the PLU field from the local menu.
          </p>
        </div>
        <toggle
          :value="pluEnabledProxy"
          @input="$emit('update:pluEnabled', $event)"
          :position="'start'"
          class="ml-0"
          style="margin-bottom: 0 !important;"
          :disabled="isSectionDisabled"
        />
      </v-flex>
      <v-flex xs6 class="d-flex" style="flex-direction: column;">
        <div>
          <h5 class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
            Showcase Items:
          </h5>
          <p class="note mt-1">
            If Item Showcasing is active, disabling this function will hide showcased items in the
            mobile app.
          </p>
          <toggle
            v-model="itemShowcaseEnabledProxy"
            :position="'start'"
            class="ml-0"
            style="margin-bottom: 0 !important;"
            :disabled="isSectionDisabled"
          />
        </div>
        <br />
        <div v-if="itemShowcaseEnabledProxy">
          <h5 class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
            Maximum Showcased Items:
          </h5>
          <p class="note mt-1">
            Note: maximum number of Items per daypart will show only if showcase items are enabled
            above
          </p>
          <v-text-field
            type="number"
            class="maxShowcaseItems"
            :disabled="true"
            v-model="itemMaxShowcaseItemsProxy"
          />
        </div>
      </v-flex>
      <v-flex xs6 class="d-flex" style="flex-direction: column;">
        <div>
          <h5 class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
            Allow Local Item Description Edits:
          </h5>
          <p class="note mt-1">
            Note: when set to YES, permitted site operators will be able to override item
            description in local menus.
          </p>
          <toggle
            :value="itemDescEditEnabledProxy"
            @input="$emit('update:itemDescEditEnabled', $event)"
            :position="'start'"
            class="ml-0"
            style="margin-bottom: 0 !important;"
            :disabled="isSectionDisabled"
          />
        </div>
      </v-flex>
      <v-flex xs6 class="d-flex" style="flex-direction: column;">
        <div>
          <h5 class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
            Allow Local Menu Images:
          </h5>
          <p class="note mt-1">
            Note: when set to YES, local menus will inherit the images that exist in the global
            menu. Toggling NO will remove the images from the local menu.
          </p>
        </div>
        <toggle
          :value="localImagesEnabledProxy"
          @input="$emit('update:localImagesEnabled', $event)"
          :position="'start'"
          class="ml-0"
          style="margin-bottom: 0 !important;"
          :disabled="!globalImagesEnabledProxy || isSectionDisabled"
        />
      </v-flex>
      <v-flex xs6 class="d-flex" style="flex-direction: column;">
        <div>
          <h5 class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
            Allow Local Menu Calories Edits:
          </h5>
          <p class="note mt-1">
            Note: when set to YES, permitted site managers/delivery managers will be able to
            override item calories in local menus if they have permissions.
          </p>
          <toggle
            :value="caloriesEditEnabledProxy"
            @input="$emit('update:caloriesEditEnabled', $event)"
            :position="'start'"
            class="ml-0"
            style="margin-bottom: 0 !important;"
            :disabled="isSectionDisabled"
          />
        </div>
      </v-flex>
      <v-flex xs6 class="d-flex" style="flex-direction: column;">
        <div>
          <h5 class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
            Allow In App Item Naming:
          </h5>
          <p class="note mt-1">
            Note: when set to YES, permitted site managers/delivery managers will be able to
            override item names in local menus if they have permissions.
          </p>
          <toggle
            :value="itemLabelEditEnabledProxy"
            @input="$emit('update:itemLabelEditEnabled', $event)"
            :position="'start'"
            class="ml-0"
            style="margin-bottom: 0 !important;"
            :disabled="isSectionDisabled"
          />
        </div>
      </v-flex>
      <v-flex xs6 class="d-flex" style="flex-direction: column;">
        <div>
          <h5 class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
            Allow Special Requests:
          </h5>
          <p class="note mt-1">
            Note: When enabling Special Requests, mobile app users will be able to send item-level
            requests to the kitchen for display on the KDS.
          </p>
          <toggle
            :value="specialInstructionsEnabledProxy"
            @input="$emit('update:specialInstructionsEnabled', $event)"
            :position="'start'"
            class="ml-0"
            style="margin-bottom: 0 !important;"
            :disabled="isSectionDisabled"
          />
        </div>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import labelLang from '@/components/labelLang';

export default {
  props: [
    'disclaimer',
    'displayCalories',
    'exemptionsEnabled',
    'pluEnabled',
    'localImagesEnabled',
    'itemDescEditEnabled',
    'caloriesEditEnabled',
    'itemLabelEditEnabled',
    'globalImagesEnabled',
    'itemShowcaseEnabled',
    'itemMaxShowcaseItems',
    'isSectionDisabled',
    'specialInstructionsEnabled',
  ],
  components: {
    labelLang,
  },
  data: () => ({ isFrictionlessEnabled: false }),
  computed: {
    ...mapGetters('adminPanel', ['isAdmin']),
    ...mapActions('menus', ['saveFrictionless']),
    exemptionsEnabledProxy: {
      get() {
        if (typeof this.exemptionsEnabled === 'boolean') {
          return this.exemptionsEnabled;
        }
        return true; // default
      },
    },
    pluEnabledProxy: {
      get() {
        if (typeof this.pluEnabled === 'boolean') {
          return this.pluEnabled;
        }
        return false; // default
      },
    },
    itemShowcaseEnabledProxy: {
      get() {
        if (typeof this.itemShowcaseEnabled === 'boolean') {
          return this.itemShowcaseEnabled;
        }
        return false; // default
      },
      set(v) {
        this.$emit('update:itemShowcaseEnabled', v);
        if (v) {
          this.itemMaxShowcaseItemsProxy = 5;
        } else {
          this.itemMaxShowcaseItemsProxy = undefined;
        }
      },
    },
    itemMaxShowcaseItemsProxy: {
      get() {
        if (typeof this.itemMaxShowcaseItems === 'number') {
          return this.itemMaxShowcaseItems;
        }
        return 5; // default
      },
      set(v) {
        this.$emit('update:itemMaxShowcaseItems', v);
      },
    },
    localImagesEnabledProxy: {
      get() {
        if (this.globalImagesEnabledProxy === false) {
          return false;
        }
        if (typeof this.localImagesEnabled === 'boolean') {
          return this.localImagesEnabled;
        }
        return false; // default
      },
    },
    globalImagesEnabledProxy: {
      get() {
        if (typeof this.globalImagesEnabled === 'boolean') {
          return this.globalImagesEnabled;
        }
        return false; // default
      },
    },
    itemDescEditEnabledProxy: {
      get() {
        if (typeof this.itemDescEditEnabled === 'boolean') {
          return this.itemDescEditEnabled;
        }
        return false; // default
      },
    },
    caloriesEditEnabledProxy: {
      get() {
        if (typeof this.caloriesEditEnabled === 'boolean') {
          return this.caloriesEditEnabled;
        }
        return false; // default
      },
    },
    itemLabelEditEnabledProxy: {
      get() {
        if (typeof this.itemLabelEditEnabled === 'boolean') {
          return this.itemLabelEditEnabled;
        }
        return false; // default
      },
    },
    specialInstructionsEnabledProxy: {
      get() {
        if (typeof this.specialInstructionsEnabled === 'boolean') {
          return this.specialInstructionsEnabled;
        }
        return false; // default
      },
    },
  },

  methods: {
    async onFrictionlessToggle(enabled) {
      const activeBrand = { ...this.active_brand };

      activeBrand.is = {
        ...activeBrand.is,
        frictionless_supported: enabled,
      };

      if (enabled) {
        this.isFrictionlessEnabled = true;
        this.saveFrictionless(activeBrand);
        this.$toast.success(`Frictionless for ${this.active_brand.name} enabled successfully`);
      } else {
        const input = await this.$confirm({
          title: 'Warning',
          message: 'Are you sure want to remove Frictionless?',
          buttonTrueText: 'OK',
          buttonFalseText: 'CANCEL',
        });

        if (input) {
          try {
            this.saveFrictionless(activeBrand);
            this.isFrictionlessEnabled = enabled;
            this.$toast.success(`Frictionless for ${this.active_brand.name} disabled successfully`);
          } catch (err) {
            this.isFrictionlessEnabled = !enabled;
            this.$toast.error(`Could not update ${activeBrand.name} station`);
          }
        } else {
          this.isFrictionlessEnabled = !enabled;
        }
      }
    },
  },
};
</script>

<style scoped>
.maxShowcaseItems {
  max-width: 500px;
}
.note {
  font-weight: bold;
  font-style: italic;
  font-size: 11px;
  line-height: 1.2;
  color: rgba(0, 0, 0, 0.6);
  height: 30px;
}
</style>
