<template>
  <v-card class="v-card-custom">
    <v-layout row wrap>
      <v-flex xs12>
        <v-layout>
          <v-flex xs10>
            <div class="H5-Primary-Left">Pickup Details</div>
          </v-flex>
          <v-flex v-if="pickupStatus && !isSectionDisabled">
            <v-btn flat class="Button-Primary-Right" color="#0d73d8" @click="dialog = true">
              <v-icon color="#090c9b" class="icon">mdi-cellphone-off</v-icon>DISABLE
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <template v-if="pickupStatus">
        <v-flex xs12>
          <v-text-field
            :label="timeLabels.label"
            class="pickup"
            v-model="time"
            :rules="timeslotRules"
            type="number"
            min="0"
            :suffix="timeLabels.suffix"
            :disabled="isSectionDisabled"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            :label="prepLabels.label"
            class="pickup"
            :rules="averageRules"
            v-model="averagePrepTime"
            type="number"
            min="0"
            :suffix="prepLabels.suffix"
            :disabled="isSectionDisabled"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            label="Customers Per Slot"
            class="pickup"
            type="number"
            min="0"
            v-model="customersPerSlot"
            :rules="customerRules"
            :disabled="isSectionDisabled"
          />
        </v-flex>
      </template>
      <template v-else>
        <v-flex xs12>
          <v-layout>
            <v-img
              :src="require('@/assets/undraw-pickup.svg')"
              :height="164"
              :width="215"
              clss="svg-image"
              contain
            ></v-img>
          </v-layout>
        </v-flex>

        <v-flex xs12>
          <div class="Body-1-Black-High-Emphasis-Center">Pickup is not enabled for this brand</div>
        </v-flex>
        <v-flex xs12>
          <div class="Subtitle-1-Black-Medium-Emphasis-Center">
            Would you like to enable Pickup?
            <toggle
              class="toggle"
              :value="this.pickupStatus"
              @input="$emit('update:pickupStatus', !pickupStatus)"
              position="center"
              :disabled="isSectionDisabled"
            />
          </div>
        </v-flex>
      </template>
    </v-layout>

    <v-dialog v-model="dialog" width="560" @keydown.esc="dialog = false">
      <disable-pickup
        @dismissDialog="closeDialog"
        @handlePickupDisable="handlePickupStatusChange"
        :brand.sync="brand"
        serviceType="pickup"
      ></disable-pickup>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import rules from '@/rules';
import disablePickup from '@/components/BrandSettings/DisableServiceDialog';

export default {
  name: 'brand-pickup',
  components: { 'disable-pickup': disablePickup },
  props: {
    isSectionDisabled: { type: Boolean },
    timeslots: {
      type: Object,
      default: () => ({
        averagePrepTime: '00:00:00',
        time: '00:00:00',
        customers_per_slot: 1,
        delivery_time: '9:12:1',
        delivery_customers_per_slot: 1,
        delivery_menu_items_per_slot: 1,
      }),
    },
    dh: {
      type: Boolean,
      default: false,
    },
    brand: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    pickupStatus: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data: () => ({
    timeslotRules: [
      rules.required('Timeslot length is required'),
      rules.min(1, 'Timeslot length must be at least 1 minute'),
      rules.max(1000, 'Timeslot length must be no more than 1000 minutes'),
    ],
    averageRules: [
      rules.required('Average Prep length is required'),
      rules.min(1, 'Average Prep length must be at least 1 minute'),
      rules.max(1000, 'Average Prep length must be no more than 1000 minutes'),
    ],
    customerRules: [
      rules.required('Customers per slot is required'),
      rules.min(1, 'Customers per slot must be at least 1'),
      rules.max(100, 'Customers per slot must be no more than 100'),
    ],
    dialog: false,
  }),
  computed: {
    ...mapGetters('sites', {
      multigroups: 'getCdlMultigroups',
    }),
    time: {
      get() {
        return this.convertTimeToMinutes(this.timeslots.time);
      },
      set(v) {
        this.$emit('update:timeslots', { ...this.timeslots, time: this.convertMinutesToTime(v) });
      },
    },
    timeLabels() {
      if (Number.isFinite(this.time)) {
        return {
          label: 'Timeslot Length',
          suffix: 'minutes',
        };
      }
      return {
        label: 'Timeslot Length (minutes)',
        suffix: '',
      };
    },
    prepLabels() {
      if (Number.isFinite(this.averagePrepTime)) {
        return {
          label: 'Average Prep Time',
          suffix: 'minutes',
        };
      }
      return {
        label: 'Average Prep Length (minutes)',
        suffix: '',
      };
    },
    averagePrepTime: {
      get() {
        return this.convertTimeToMinutes(this.timeslots.averagePrepTime);
      },
      set(v) {
        this.$emit('update:timeslots', {
          ...this.timeslots,
          averagePrepTime: this.convertMinutesToTime(v),
        });
      },
    },
    customersPerSlot: {
      get() {
        return this.timeslots.customers_per_slot;
      },
      set(v) {
        this.$emit('update:timeslots', { ...this.timeslots, customers_per_slot: this.toNumber(v) });
      },
    },
  },
  methods: {
    convertTimeToMinutes(time) {
      if (time === null || time === undefined) {
        return '';
      }
      let [hours, minutes] = time.split(':');
      hours = parseInt(hours, 10);
      minutes = parseInt(minutes, 10);
      return hours * 60 + minutes;
    },
    convertMinutesToTime(minutes = 0) {
      let hours = parseInt(minutes / 60, 10);
      if (hours < 10) {
        hours = `0${hours}`;
      }
      let remainingMinutes = minutes % 60;
      if (remainingMinutes < 10) {
        remainingMinutes = `0${remainingMinutes}`;
      }
      return `${hours}:${remainingMinutes}:00`;
    },

    toNumber(value) {
      if (value) {
        return parseInt(value, 10);
      }
      return null;
    },
    closeDialog() {
      this.dialog = false;
    },
    handlePickupStatusChange() {
      this.$emit('update:pickupStatus', !this.pickupStatus);
    },
  },
};
</script>

<style scoped>
.pickup {
  max-width: 500px;
}
.icon {
  margin-right: 10px;
}
</style>
