import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(VCardTitle, { staticClass: "H4-Secondary-Center dropoff-title" }, [
        _vm._v(
          "Disable\n    " +
            _vm._s(
              _vm.serviceType.charAt(0).toUpperCase() + _vm.serviceType.slice(1)
            ) +
            " Program"
        )
      ]),
      _c(
        VCardText,
        [
          _c(VFlex, { attrs: { xs12: "" } }, [
            _c("p", { staticClass: "Body-1-Black-High-Emphasis-Left" }, [
              _vm._v(
                "\n        Disabling " +
                  _vm._s(_vm.serviceType) +
                  " saves your settings and can be undone. " +
                  _vm._s(_vm.brand.name) +
                  " at\n        " +
                  _vm._s(_vm.brand.address.address) +
                  " will no longer provide " +
                  _vm._s(_vm.serviceType) +
                  " services to our\n        customers.\n      "
              )
            ])
          ]),
          _c(VFlex, { attrs: { xs12: "" } }, [
            _c("p", { staticClass: "Body-1-Black-High-Emphasis-Left" }, [
              _vm._v(
                "\n        To continue with removal please enter DISABLE in the field below and continue.\n      "
              )
            ])
          ]),
          _c(VFlex, { attrs: { xs6: "" } }, [
            _c(
              "div",
              [
                _c(
                  VForm,
                  { ref: "confirmationRef" },
                  [
                    _c(VTextField, {
                      attrs: {
                        label: "Confirm Disabling",
                        outline: "",
                        flat: "",
                        rules: _vm.rules
                      },
                      model: {
                        value: _vm.confirmationString,
                        callback: function($$v) {
                          _vm.confirmationString = $$v
                        },
                        expression: "confirmationString"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(VLayout)
        ],
        1
      ),
      _c(
        VCardActions,
        [
          _c(VSpacer),
          _c(
            VBtn,
            {
              attrs: { color: "primary", flat: "" },
              on: { click: _vm.handleDialogCancel }
            },
            [_vm._v("cancel")]
          ),
          _c(
            VBtn,
            {
              attrs: { color: "primary", flat: "" },
              on: { click: _vm.disablePickup }
            },
            [_vm._v("save")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }