<template>
  <v-card class="v-card-custom">
    <v-layout row wrap fill-height>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12>
            <div class="H5-Primary-Left">Dining Hall</div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-layout mt-1 xs12>
          <v-flex style="flex-direction: column;">
            <div>
              <h5 class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
                Enable Dining Hall? (Available only for Higher Education sites. Menu items needs to
                be $0)
              </h5>
            </div>
            <toggle
              :position="'start'"
              class="ml-0"
              v-model="toggleDiningHall"
              :disabled="isSectionDisabled"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
export default {
  props: {
    enable: {
      type: Boolean,
    },
    isSectionDisabled: {
      type: Boolean,
    },
  },
  computed: {
    toggleDiningHall: {
      get() {
        return this.enable;
      },
      set(b) {
        this.$emit('update:enable', b);
      },
    },
  },
};
</script>
