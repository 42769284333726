import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "v-card-custom" },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "", "fill-height": "" } },
        [
          _c(
            VFlex,
            { attrs: { xs12: "", md7: "" } },
            [
              _c(
                VLayout,
                { attrs: { row: "", wrap: "" } },
                [
                  _c(VFlex, { attrs: { xs12: "" } }, [
                    _c("div", { staticClass: "H5-Primary-Left" }, [
                      _vm._v("Station Information")
                    ])
                  ]),
                  !_vm.dh
                    ? _c(
                        VFlex,
                        { attrs: { xs12: "" } },
                        [
                          _c(VSelect, {
                            attrs: {
                              items: _vm.sectors,
                              label: "Group",
                              placeholder: "",
                              value: _vm.sector,
                              rules: _vm.sectorRules,
                              disabled: !_vm.isNew || _vm.isSectionDisabled
                            },
                            on: {
                              input: function($event) {
                                return _vm.$emit("update:sector", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.dh
                    ? _c(
                        VFlex,
                        { attrs: { xs12: "" } },
                        [
                          _c(VSelect, {
                            attrs: {
                              items: _vm.companies,
                              label: "Global Menu Assignation",
                              placeholder: "",
                              value: _vm.company,
                              rules: _vm.companyRules,
                              disabled: !_vm.isNew || _vm.isSectionDisabled
                            },
                            on: {
                              input: function($event) {
                                return _vm.$emit("update:company", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c(VTextField, {
                        attrs: {
                          label: "Station Name",
                          value: _vm.name,
                          rules: _vm.nameRules,
                          disabled: _vm.dh || _vm.isSectionDisabled
                        },
                        on: {
                          input: function($event) {
                            return _vm.$emit("update:name", $event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c(VTextField, {
                        ref: "description",
                        attrs: {
                          label: "Station Location Description",
                          value: _vm.desc,
                          disabled: _vm.isSectionDisabled
                        },
                        on: {
                          input: function($event) {
                            return _vm.$emit("update:desc", $event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c(VSelect, {
                        attrs: {
                          items: _vm.locations,
                          disabled: !_vm.isNew || _vm.isSectionDisabled,
                          rules: _vm.costCentreRules,
                          label: "Location [Cost Centre]"
                        },
                        model: {
                          value: _vm.costCentreProxy,
                          callback: function($$v) {
                            _vm.costCentreProxy = $$v
                          },
                          expression: "costCentreProxy"
                        }
                      })
                    ],
                    1
                  ),
                  !_vm.dh && _vm.isCanadianSite && _vm.displayJDECategory
                    ? _c(
                        VFlex,
                        { attrs: { xs12: "" } },
                        [
                          _c(VSelect, {
                            attrs: {
                              items: _vm.jdeCategories,
                              "item-text": "name",
                              "item-value": "id",
                              label: "JDE Category",
                              placeholder: "",
                              value: _vm.jdeCategory,
                              disabled: !_vm.isCompany || _vm.isSectionDisabled
                            },
                            on: {
                              input: function($event) {
                                return _vm.$emit("update:jdeCategory", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        VLayout,
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            VFlex,
                            { attrs: { xs6: "" } },
                            [
                              _c(VTextField, {
                                staticStyle: { "max-width": "100px" },
                                attrs: {
                                  type: "number",
                                  label: "Tax Rate",
                                  value: _vm.tax,
                                  suffix: "%",
                                  min: "0",
                                  rules: _vm.taxRules,
                                  "persistent-hint": "",
                                  disabled: _vm.isSectionDisabled,
                                  hint: _vm.taxrate
                                    ? "Default Tax Rate is " +
                                      (_vm.taxrate * 100).toFixed(2) +
                                      "%"
                                    : ""
                                },
                                model: {
                                  value: _vm.taxProxy,
                                  callback: function($$v) {
                                    _vm.taxProxy = $$v
                                  },
                                  expression: "taxProxy"
                                }
                              })
                            ],
                            1
                          ),
                          _c(VFlex, { attrs: { xs6: "" } })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VFlex,
            { attrs: { xs12: "", md5: "" } },
            [
              _c(
                VLayout,
                {
                  staticClass: "phonepreview",
                  style: {
                    marginTop: "30px",
                    backgroundImage:
                      "url(" + require("@/assets/i-phone-xs-max.svg") + ")"
                  },
                  attrs: { row: "", wrap: "" }
                },
                [
                  _c(
                    VFlex,
                    { attrs: { xs12: "", md12: "" } },
                    [
                      _c(
                        VLayout,
                        {
                          staticStyle: { "margin-top": "60px" },
                          attrs: { row: "", warp: "", "justify-center": "" }
                        },
                        [
                          _c(
                            "croppa",
                            {
                              attrs: {
                                width: 250,
                                height: 117,
                                quality: 3,
                                "prevent-white-space": true,
                                "remove-button-color": "black",
                                placeholder: "Upload an image for mobile",
                                "placeholder-font-size": 12,
                                disabled: _vm.dh || _vm.isSectionDisabled,
                                "show-remove-button": !_vm.dh
                              },
                              on: {
                                "file-type-mismatch": _vm.imageIssue,
                                "file-choose": _vm.imageChanged,
                                move: _vm.imageChanged,
                                zoom: _vm.imageChanged,
                                "image-remove": _vm.imageRemove
                              },
                              model: {
                                value: _vm.mycroppa,
                                callback: function($$v) {
                                  _vm.mycroppa = $$v
                                },
                                expression: "mycroppa"
                              }
                            },
                            [
                              !!_vm.image
                                ? _c("img", {
                                    attrs: {
                                      slot: "initial",
                                      crossorigin: _vm.anonymous,
                                      src: _vm.image,
                                      alt: "brand image"
                                    },
                                    slot: "initial"
                                  })
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    { attrs: { xs12: "", md12: "" } },
                    [
                      _c(
                        VLayout,
                        { attrs: { row: "", warp: "", "justify-center": "" } },
                        [
                          _c("div", { staticStyle: { width: "250px" } }, [
                            _c(
                              "span",
                              { staticClass: "H5-Black-High-Emphasis-Left" },
                              [_vm._v(_vm._s(_vm.name))]
                            ),
                            _c("br"),
                            _c(
                              "span",
                              { staticClass: "H6-Black-Medium-Emphasis-Left" },
                              [_vm._v(_vm._s(_vm.desc))]
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }