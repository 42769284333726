import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "v-card-custom" },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(
                VLayout,
                { attrs: { "justify-space-between": "" } },
                [
                  _c(VFlex, [
                    _c("div", { staticClass: "H5-Primary-Left" }, [
                      _vm._v("Integration Type")
                    ])
                  ]),
                  _vm.enabled
                    ? _c(
                        VFlex,
                        { attrs: { shrink: "" } },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: {
                                disabled: _vm.isSectionDisabled,
                                flat: "",
                                color: "secondary"
                              },
                              on: {
                                click: function($event) {
                                  _vm.enabled = false
                                }
                              }
                            },
                            [
                              _c(VIcon, [_vm._v("mdi-delete-outline")]),
                              _vm._v("remove\n          ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.enabled
        ? _c(
            VLayout,
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                VFlex,
                { attrs: { md7: "" } },
                [
                  _c(
                    VLayout,
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        VFlex,
                        { attrs: { xs12: "" } },
                        [
                          _c(VSelect, {
                            staticStyle: { "max-width": "500px" },
                            attrs: {
                              label: "KDS Type",
                              items: _vm.kdsOptions,
                              value: _vm.typeOfKds,
                              disabled: _vm.isSectionDisabled
                            },
                            on: { input: _vm.selectIntegration }
                          }),
                          _c(VTextField, {
                            staticStyle: { "max-width": "500px" },
                            attrs: {
                              label: "KDS Device Email",
                              value: _vm.email,
                              "browser-autocomplete": "off",
                              rules: _vm.emailRules,
                              disabled: _vm.isSectionDisabled
                            },
                            on: {
                              input: function($event) {
                                return _vm.$emit("update:email", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.isKdsCdl
                    ? _c(
                        VLayout,
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            VFlex,
                            { attrs: { xs12: "" } },
                            [
                              _c(VTextField, {
                                staticStyle: { "max-width": "500px" },
                                attrs: {
                                  label: "KDS Admin Passcode",
                                  value: _vm.passcode,
                                  rules: _vm.passcodeRules,
                                  "browser-autocomplete": "off",
                                  disabled: _vm.isSectionDisabled
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.$emit("update:passcode", $event)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            VFlex,
                            { attrs: { xs12: "" } },
                            [
                              _c(VTextField, {
                                staticStyle: { "max-width": "500px" },
                                attrs: {
                                  label: "Station Phone Number",
                                  mask: "(###) ### - ####",
                                  rules: _vm.numberRules,
                                  value: _vm.number,
                                  disabled: _vm.isSectionDisabled
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.$emit("update:number", $event)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            VFlex,
                            { attrs: { xs12: "" } },
                            [
                              _c(VTextField, {
                                staticStyle: { "max-width": "500px" },
                                attrs: {
                                  label:
                                    "Message On Receipt (Thanks for Ordering) ",
                                  value: _vm.message,
                                  disabled: _vm.isSectionDisabled
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.$emit("update:message", $event)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            VFlex,
                            { attrs: { "mt-1": "", xs12: "" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "Subtitle-1-Black-Medium-Emphasis-Left"
                                },
                                [_vm._v("Allow for KDS Refunds")]
                              ),
                              _c("toggle", {
                                attrs: {
                                  disabled: _vm.isSectionDisabled,
                                  value: _vm.refund,
                                  position: "start"
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.$emit("update:refund", $event)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.isKdsCdl
                ? _c(
                    VFlex,
                    [
                      _c(
                        "croppa",
                        {
                          attrs: {
                            width: 300,
                            height: 210,
                            quality: 1,
                            "prevent-white-space": true,
                            "remove-button-color": "black",
                            placeholder: "Upload an image for the reciept",
                            "placeholder-font-size": 12,
                            disabled: _vm.isSectionDisabled
                          },
                          on: {
                            "file-type-mismatch": _vm.imageIssue,
                            "file-choose": _vm.imageChanged,
                            move: _vm.imageChanged,
                            zoom: _vm.imageChanged,
                            "image-remove": _vm.imageChanged
                          },
                          model: {
                            value: _vm.receiptImage,
                            callback: function($$v) {
                              _vm.receiptImage = $$v
                            },
                            expression: "receiptImage"
                          }
                        },
                        [
                          !!_vm.imageProxy
                            ? _c("img", {
                                attrs: {
                                  slot: "initial",
                                  crossorigin: "anonymous",
                                  src: _vm.imageProxy
                                },
                                slot: "initial"
                              })
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "Caption-Black-Medium-Emphasis" },
                        [_vm._v("Receipt Image")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        VLayout,
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.enabled,
              expression: "!enabled"
            }
          ],
          attrs: { "justify-center": "", "align-center": "", column: "" }
        },
        [
          _c(
            VFlex,
            { attrs: { xs4: "" } },
            [
              _c(VImg, {
                attrs: { src: require("./kds-img.svg"), width: "150" }
              })
            ],
            1
          ),
          _c(VFlex, { attrs: { shrink: "" } }, [
            _c("span", { staticClass: "Body-1-Black-High-Emphasis-Center" }, [
              _vm._v("KDS is not enabled for this brand.")
            ])
          ]),
          _c(VFlex, { attrs: { shrink: "" } }, [
            _c(
              "span",
              { staticClass: "Subtitle-1-Black-Medium-Emphasis-Center" },
              [_vm._v("Would you like to setup KDS?")]
            )
          ]),
          _c(
            VFlex,
            [
              _c("toggle", {
                attrs: { disabled: _vm.isSectionDisabled },
                model: {
                  value: _vm.enabled,
                  callback: function($$v) {
                    _vm.enabled = $$v
                  },
                  expression: "enabled"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }