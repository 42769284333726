<template>
  <v-layout row wrap fill-height>
    <v-flex xs12>
      <v-layout row nowrap v-for="(category, index) in assignedCategories" v-bind:key="index">
        <v-flex shrink>
          <v-btn
            :disabled="isBrandSectionDisabled"
            icon
            ripple
            @click="deleteCategory(index)"
            v-if="!menuDisabled"
          >
            <v-icon color="grey lighten-1">mdi-close</v-icon>
          </v-btn>
        </v-flex>
        <v-flex xs6>
          <assign-category-select
            :assignedCategories.sync="assignedCategories"
            label="Category Name"
            placeholder
            :categoryRules="categoryRules"
            :category_id.sync="category.id"
            :disabled="isCategoryDisabled(category.id) || isBrandSectionDisabled || isCentricOS"
            :allCategories="allCategories"
          ></assign-category-select>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12>
      <v-btn
        :disabled="isBrandSectionDisabled || isCentricOS"
        flat
        color="#0d73d8"
        class="ml-0 pl-0"
        v-on:click="addCategory"
        v-if="!menuDisabled"
      >
        <v-icon class="mr-1">mdi-plus</v-icon>Add category
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import rules from '@/rules';
import assignCategorySelect from './assignCategorySelect';

export default {
  props: [
    'assignedCategories',
    'allCategories',
    'menuDisabled',
    'isBrandSectionDisabled',
    'isCentricOS',
  ],
  data: () => ({
    defaultCategory: {
      value: null,
      text: '',
      is: null,
      groups: [],
      allGroups: [],
    },
    categoryRules: [rules.required('Category is Required')],
    localBrandMenuCatgories: [],
  }),
  computed: {},
  methods: {
    deleteCategory(index) {
      this.assignedCategories.splice(index, 1);
    },
    isCategoryDisabled(category_id) {
      const category = this.allCategories.find((c) => c.id === category_id);
      if (!category) return false;
      return category.isDisabled;
    },
    getDefaultCategory() {
      return cloneDeep(this.defaultCategory);
    },
    async addCategory() {
      this.assignedCategories.push(this.getDefaultCategory());
    },
  },
  watch: {},
  components: { assignCategorySelect },
};
</script>

<style></style>
