import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "v-card-custom" },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "", "fill-height": "" } },
        [
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(
                VLayout,
                { attrs: { row: "", wrap: "" } },
                [
                  _c(VFlex, { attrs: { xs12: "" } }, [
                    _c("div", { staticClass: "H5-Primary-Left" }, [
                      _vm._v("Dining Hall")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(
                VLayout,
                { attrs: { "mt-1": "", xs12: "" } },
                [
                  _c(
                    VFlex,
                    { staticStyle: { "flex-direction": "column" } },
                    [
                      _c("div", [
                        _c(
                          "h5",
                          {
                            staticClass:
                              "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
                          },
                          [
                            _vm._v(
                              "\n              Enable Dining Hall? (Available only for Higher Education sites. Menu items needs to\n              be $0)\n            "
                            )
                          ]
                        )
                      ]),
                      _c("toggle", {
                        staticClass: "ml-0",
                        attrs: {
                          position: "start",
                          disabled: _vm.isSectionDisabled
                        },
                        model: {
                          value: _vm.toggleDiningHall,
                          callback: function($$v) {
                            _vm.toggleDiningHall = $$v
                          },
                          expression: "toggleDiningHall"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }