import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "v-card-custom" },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(
                VLayout,
                { attrs: { "justify-space-between": "" } },
                [
                  _c(VFlex, { attrs: { xs10: "" } }, [
                    _c("div", { staticClass: "H5-Primary-Left" }, [
                      _vm._v("Delivery Details")
                    ])
                  ]),
                  _vm.enable && !_vm.isSectionDisabled
                    ? _c(
                        VFlex,
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "Button-Primary-Right",
                              attrs: { flat: "", color: "#0d73d8" },
                              on: {
                                click: function($event) {
                                  _vm.dialog = true
                                }
                              }
                            },
                            [
                              _c(
                                VIcon,
                                {
                                  staticClass: "icon",
                                  attrs: { color: "#090c9b" }
                                },
                                [_vm._v("mdi-cellphone-off")]
                              ),
                              _vm._v("DISABLE\n          ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.enable
            ? [
                _vm.enable &&
                _vm.timeslotType ===
                  _vm.DELIVERY_TIMESLOT_TYPES.user_defined.value
                  ? _c(VTextField, {
                      staticClass: "custom-validation",
                      attrs: {
                        value: _vm.timeslots.delivery_user_defined
                          ? _vm.timeslots.delivery_user_defined.length
                          : 0,
                        rules: _vm.userDefinedTimeslotRules
                      }
                    })
                  : _vm._e(),
                !_vm.dh
                  ? _c(
                      VFlex,
                      { attrs: { xs12: "" } },
                      [
                        _c(VSelect, {
                          staticClass: "deliveryInputs",
                          attrs: {
                            label: "Timeslot Type",
                            items: _vm.timeslotTypeOptions,
                            disabled: _vm.isSectionDisabled
                          },
                          model: {
                            value: _vm.timeslotType,
                            callback: function($$v) {
                              _vm.timeslotType = $$v
                            },
                            expression: "timeslotType"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.dh ||
                _vm.timeslotType === _vm.DELIVERY_TIMESLOT_TYPES.automatic.value
                  ? _c(
                      VFlex,
                      { attrs: { xs12: "" } },
                      [
                        _c(VTextField, {
                          staticClass: "deliveryInputs",
                          attrs: {
                            label: "Timeslot Length",
                            type: "number",
                            min: "0",
                            suffix: "minutes",
                            rules: _vm.timeslotRules,
                            disabled: _vm.isSectionDisabled
                          },
                          model: {
                            value: _vm.time,
                            callback: function($$v) {
                              _vm.time = $$v
                            },
                            expression: "time"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  VFlex,
                  { attrs: { xs12: "" } },
                  [
                    _c(VTextField, {
                      staticClass: "deliveryInputs",
                      attrs: {
                        label: "Average Prep Time",
                        type: "number",
                        min: "0",
                        suffix: "minutes",
                        rules: _vm.averageRules,
                        disabled: _vm.isSectionDisabled
                      },
                      model: {
                        value: _vm.delivery_prep_time,
                        callback: function($$v) {
                          _vm.delivery_prep_time = $$v
                        },
                        expression: "delivery_prep_time"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  VFlex,
                  { attrs: { xs12: "" } },
                  [
                    _c(VTextField, {
                      staticClass: "deliveryInputs",
                      attrs: {
                        label: "Customers Per Slot",
                        type: "number",
                        min: "0",
                        rules: _vm.customerRules,
                        disabled: _vm.isSectionDisabled
                      },
                      model: {
                        value: _vm.deliveryCustomersPerSlot,
                        callback: function($$v) {
                          _vm.deliveryCustomersPerSlot = $$v
                        },
                        expression: "deliveryCustomersPerSlot"
                      }
                    })
                  ],
                  1
                ),
                _vm.isMarketplaceDisabled || _vm.willEnableMarketplaceDelivery
                  ? _c(
                      VFlex,
                      {
                        staticClass: "Subtitle-1-Black-Medium-Emphasis-Left",
                        attrs: { xs12: "" }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            "Marketplace delivery settings override these station delivery settings"
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm.timeslotType === _vm.DELIVERY_TIMESLOT_TYPES.automatic.value
                  ? _c(
                      VFlex,
                      { attrs: { xs12: "" } },
                      [
                        _c("dropoff-locations", {
                          attrs: {
                            selectedLocations: _vm.selectedLocations,
                            siteDeliveryDestinations:
                              _vm.siteDeliveryDestinations,
                            isTimeslotUserDefined:
                              _vm.timeslotType ===
                              _vm.DELIVERY_TIMESLOT_TYPES.user_defined.value,
                            isBrandSectionDisabled: _vm.isDisabled
                          },
                          on: {
                            updateSelectedLocations:
                              _vm.handleUpdateDropoffLocations
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.dh &&
                _vm.timeslotType ===
                  _vm.DELIVERY_TIMESLOT_TYPES.user_defined.value
                  ? _c(
                      VFlex,
                      { attrs: { xs12: "" } },
                      [
                        _c("delivery-timeslots", {
                          attrs: {
                            timeslots: _vm.timeslots.delivery_user_defined,
                            siteDeliveryDestinations:
                              _vm.siteDeliveryDestinations,
                            isBrandSectionDisabled: _vm.isSectionDisabled
                          },
                          on: { saveTimeslots: _vm.handleUserTimeslotsSave }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  VFlex,
                  { attrs: { xs12: "" } },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
                      },
                      [
                        _vm._v(
                          "\n          Display timeslots as one time?\n        "
                        )
                      ]
                    ),
                    _c("toggle", {
                      staticClass: "toggle",
                      attrs: {
                        value: _vm.isSingleTimeslot,
                        position: "start",
                        disabled: _vm.isDisabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.$emit("update:isSingleTimeslot", $event)
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  VFlex,
                  { attrs: { xs12: "" } },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
                      },
                      [
                        _vm._v(
                          "\n          Allow delivery instruction?\n        "
                        )
                      ]
                    ),
                    _c("toggle", {
                      staticClass: "toggle",
                      attrs: {
                        value: _vm.showInstructions,
                        position: "start",
                        disabled: _vm.isDisabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.$emit("update:showInstructions", $event)
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  VFlex,
                  { attrs: { xs12: "" } },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
                      },
                      [
                        _vm._v(
                          "\n          Enable Bolter Delivery App?\n        "
                        )
                      ]
                    ),
                    _c("toggle", {
                      staticClass: "toggle",
                      attrs: {
                        value: _vm.runnerAppEnabled,
                        position: "start",
                        disabled: _vm.isDisabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.$emit("update:runnerAppEnabled", $event)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            : _c(
                VLayout,
                {
                  attrs: {
                    "justify-center": "",
                    "align-center": "",
                    column: ""
                  }
                },
                [
                  !_vm.siteDeliveryDestinations.length
                    ? [
                        _c(
                          VFlex,
                          { attrs: { xs4: "" } },
                          [
                            _c(VImg, {
                              attrs: {
                                src: require("./delivery-details-empty-state.svg"),
                                width: "215"
                              }
                            })
                          ],
                          1
                        ),
                        _c(VFlex, { attrs: { shrink: "" } }, [
                          _c(
                            "span",
                            {
                              staticClass: "Body-1-Black-High-Emphasis-Center"
                            },
                            [_vm._v("There are no drop-off locations set up.")]
                          )
                        ]),
                        _c(VFlex, { attrs: { shrink: "" } }, [
                          _c(
                            "span",
                            {
                              staticClass: "Body-1-Black-High-Emphasis-Center"
                            },
                            [
                              _vm._v(
                                "Please go to Site Configuration to add drop-off locations first."
                              )
                            ]
                          )
                        ]),
                        _c(
                          VFlex,
                          [
                            _c(
                              VBtn,
                              {
                                attrs: {
                                  color: "primary",
                                  disabled: _vm.isSectionDisabled
                                },
                                on: { click: _vm.goToSiteConfig }
                              },
                              [
                                _vm._v(
                                  "\n            SITE CONFIGURATION\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    : [
                        _c(VImg, {
                          attrs: {
                            src: require("./site-delivery-present.svg"),
                            width: "25.67%"
                          }
                        }),
                        _c(VFlex, { attrs: { shrink: "" } }, [
                          _c(
                            "span",
                            {
                              staticClass: "Body-1-Black-High-Emphasis-Center"
                            },
                            [
                              _vm._v(
                                "Drop-off locations are added at the site level."
                              )
                            ]
                          )
                        ]),
                        _c(VFlex, { attrs: { shrink: "" } }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "Subtitle-1-Black-Medium-Emphasis-Center"
                            },
                            [_vm._v("Would you like to setup details?")]
                          )
                        ]),
                        _c(
                          VFlex,
                          [
                            _c("toggle", {
                              attrs: { disabled: _vm.isSectionDisabled },
                              model: {
                                value: _vm.delivery,
                                callback: function($$v) {
                                  _vm.delivery = $$v
                                },
                                expression: "delivery"
                              }
                            })
                          ],
                          1
                        )
                      ]
                ],
                2
              )
        ],
        2
      ),
      _c(
        VDialog,
        {
          attrs: { width: "560" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.dialog = false
            }
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c("disable-delivery", {
            attrs: { brand: _vm.brand, serviceType: "delivery" },
            on: {
              dismissDialog: _vm.closeDialog,
              handlePickupDisable: _vm.handleDeliveryStatusChange,
              "update:brand": function($event) {
                _vm.brand = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }