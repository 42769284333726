import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(VSelect, {
    attrs: {
      items: _vm.unassignedCategories,
      label: _vm.label,
      placeholder: "",
      rules: _vm.categoryRules,
      disabled: _vm.disabled
    },
    model: {
      value: _vm.category_id_sync,
      callback: function($$v) {
        _vm.category_id_sync = $$v
      },
      expression: "category_id_sync"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }