<template>
  <v-container grid-list-xl style="margin-bottom: 50px">
    <v-form ref="form" class="settings" v-model="valid">
      <v-layout row wrap>
        <v-flex xs12>
          <view-title />
          <intersection-observer @intersect="intersect" />
        </v-flex>

        <loading-wrapper :loading="loading" mt="20vh" :size="100">
          <v-flex xs9>
            <v-layout row wrap>
              <v-flex xs12>
                <information
                  :name.sync="brand.name"
                  :desc.sync="brandLocationDescription"
                  :imagestyle.sync="brand.style"
                  :tax.sync="privateConfig.tax_rate"
                  :taxrate="brand.tax_rate"
                  :company.sync="brand.company"
                  :sector.sync="brand.sector"
                  :locationID.sync="currentLocation"
                  :isSectionDisabled="isBrandConfigEditDisabled"
                  :jdeCategory.sync="jdeCategory"
                  :dh="isDH(brand)"
                  :isNew="isNew"
                  :isCentricOS="isCentricOSToggledOn"
                  ref="brandInfo"
                  id="toc-information"
                  v-observe-visibility="visibilityChanged"
                />
              </v-flex>
              <v-flex xs12>
                <service-fee
                  :delivery_fee.sync="privateConfig.delivery_fee"
                  :service_fee.sync="privateConfig.service_fee"
                  id="toc-serviceFee"
                  v-observe-visibility="visibilityChanged"
                  :isSectionDisabled="isBrandConfigEditDisabled"
                  :isMSOStation="isMSOStation"
                  :isMarketplacePickupEnabled="isMarketplacePickupEnabled"
                  :isMarketplaceDelEnabled="
                    isMarketplaceDelEnabled || willEnableMarketplaceDelivery
                  "
                  :brandDeliverySettings.sync="brandDeliverySettings"
                />
              </v-flex>
              <v-flex xs12>
                <pickup
                  :timeslots.sync="brand.timeslots"
                  :pickupStatus.sync="brand.is.pickup_supported"
                  :dh="isDH(brand)"
                  id="toc-pickup"
                  v-observe-visibility="visibilityChanged"
                  :brand.sync="brand"
                  :isSectionDisabled="isTimeslotEditDisabled"
                />
              </v-flex>
              <v-flex xs12 v-if="!frictionlessState.brand_frictionless_supported">
                <delivery
                  :enable.sync="brand.is.delivery_supported"
                  :selectedLocations.sync="publicConfig.delivery_destinations"
                  :selectedLocationsAutomaticTimeslot.sync="
                    publicConfig.delivery_destinations_automatic_timeslot
                  "
                  :dh="isDH(brand)"
                  :timeslots.sync="brand.timeslots"
                  :isSingleTimeslot.sync="publicConfig.show_single_timeslot"
                  :showInstructions.sync="publicConfig.show_instructions"
                  :runnerAppEnabled.sync="publicConfig.runner_app_enabled"
                  id="toc-delivery"
                  v-observe-visibility="visibilityChanged"
                  :isSectionDisabled="isTimeslotEditDisabled"
                  :isMarketplaceDisabled="isMarketplaceDisabled"
                  :brand.sync="brand"
                  :siteDeliveryDestinations="siteDeliveryDestinations"
                  :willEnableMarketplaceDelivery="willEnableMarketplaceDelivery"
                />
              </v-flex>
              <v-flex xs12>
                <centricOS
                  id="toc-CentricOS"
                  :enable.sync="brand.is.cafe360"
                  :selectedLocalBrand.sync="selectedCentricOSbrand"
                  :selectedLMG.sync="selectedCentricOSLMG"
                  v-observe-visibility="visibilityChanged"
                  :isSectionDisabled="isBrandConfigEditDisabled"
                />
              </v-flex>
              <v-flex xs12 v-if="isBoost">
                <DiningHall
                  id="toc-diningHall"
                  :enable.sync="brand.is.dining_hall"
                  v-observe-visibility="visibilityChanged"
                  :isSectionDisabled="isBrandConfigEditDisabled"
                />
              </v-flex>
              <v-flex xs12>
                <scan-and-go
                  id="toc-ScanAndGo"
                  :enable.sync="brand.is.scan_and_go_supported"
                  v-observe-visibility="visibilityChanged"
                  :isSectionDisabled="isBrandConfigEditDisabled"
                />
              </v-flex>
              <v-flex xs12 v-if="!isDH(brand)">
                <assign-menu
                  :allMenus="allMenus"
                  :assignedMenus.sync="assignedMenus"
                  :menusToBeImported.sync="menusToBeImported"
                  :showImportMenuDialog.sync="showImportMenuDialog"
                  v-observe-visibility="visibilityChanged"
                  :company="brandCompany"
                  ref="assignedMenuComponent"
                  id="toc-assignMenus"
                  :isCentricOS="isCentricOSToggledOn"
                  :isSectionDisabled="isMenuAssignationDisabled"
                  :isScanAndGoToggledOn="isScanAndGoToggledOn"
                  :isLoadingMenus="isLoadingMenus"
                />
              </v-flex>
              <v-flex xs12>
                <kds
                  :email.sync="privateConfig.email"
                  :passcode.sync="privateConfig.kds.admin_passcode"
                  id="toc-kds"
                  ref="kds"
                  :number.sync="publicConfig.phone_number"
                  :message.sync="publicConfig.message"
                  :image.sync="publicConfig.logos.print"
                  :refund.sync="privateConfig.payment.refund"
                  :meta.sync="brand.meta"
                  :hasKds.sync="publicConfig.has_kds"
                  :isSectionDisabled="isBrandConfigEditDisabled"
                />
              </v-flex>
              <v-flex xs12>
                <thirdParty
                  :getPhoneNumber.sync="publicConfig.get_phone_number"
                  id="toc-thirdParty"
                  v-observe-visibility="visibilityChanged"
                  :isSectionDisabled="isBrandConfigEditDisabled"
                />
              </v-flex>
              <v-flex xs12>
                <menu-info
                  :disclaimer.sync="publicConfig.calorie_disclaimer"
                  :displayCalories.sync="publicConfig.display_calories"
                  :exemptionsEnabled.sync="brand.is.promo_exemptions_enabled"
                  :pluEnabled.sync="brand.is.plu_enabled"
                  :localImagesEnabled.sync="brand.is.local_images_enabled"
                  :itemDescEditEnabled.sync="brand.is.item_desc_edit_enabled"
                  :caloriesEditEnabled.sync="brand.is.calories_edit_enabled"
                  :itemLabelEditEnabled.sync="brand.is.item_label_edit_enabled"
                  :globalImagesEnabled="isGlobalImagesEnabled"
                  :itemShowcaseEnabled.sync="brand.is.item_showcase_enabled"
                  :itemMaxShowcaseItems.sync="brandMaxShowcaseItems"
                  :specialInstructionsEnabled.sync="brand.is.special_instructions_enabled"
                  id="toc-menu-info"
                  v-observe-visibility="visibilityChanged"
                  :isSectionDisabled="isBrandConfigEditDisabled"
                />
              </v-flex>
              <v-flex xs12>
                <payment
                  :brand="true"
                  :payment="privateConfig.payment"
                  :keystore="privateConfig.keystore"
                  :isBrandConfig="true"
                  id="toc-payment"
                  v-observe-visibility="visibilityChanged"
                  :isSectionDisabled="isBrandConfigEditDisabled"
                  :refund.sync="privateConfig.payment.refund"
                  @editKeystore="editKeystore"
                  @editPayment="editPayment"
                />
              </v-flex>
              <v-flex xs12>
                <mealplan
                  :mealplan.sync="publicConfig.mealplan"
                  id="toc-mealplan"
                  v-observe-visibility="visibilityChanged"
                  :isSectionDisabled="isBrandConfigEditDisabled"
                  configLevel="BRAND"
                  :sitePublicConfig="sitePublicConfig"
                  :sitePrivateConfig="sitePrivateConfig"
                />
              </v-flex>
              <v-flex xs12>
                <loyalty
                  v-model="privateConfig.loyalty"
                  id="toc-loyalty"
                  v-observe-visibility="visibilityChanged"
                  :isSectionDisabled="isBrandConfigEditDisabled"
                />
              </v-flex>
              <v-flex xs12 v-if="!isMSOStation">
                <payment-exclusions
                  :creditCard.sync="privateConfig.excluded_payment_methods.credit_card"
                  :cashless.sync="privateConfig.excluded_payment_methods.cashless"
                  :badge_pay.sync="privateConfig.excluded_payment_methods.badge_pay"
                  :excludedTenders="excludedTenders"
                  :digitalWalletPay="privateConfig.excluded_payment_methods.digital_wallet_pay"
                  v-on:updateDigitalWalletPay="updateDigitalWalletPay"
                  v-on:updateMealSwipes="updateMealSwipes"
                  v-on:updateMealPlan="updateMealPlan"
                  :tenders="availableTenders"
                  :swipeTenders="availableMealSwipes"
                  id="toc-payment-exclusions"
                  v-observe-visibility="visibilityChanged"
                  :isSectionDisabled="isBrandConfigEditDisabled"
                />
              </v-flex>
              <v-flex xs12 v-if="frictionlessState.site_frictionless_supported">
                <frictionless
                  id="frictionless-detail"
                  v-observe-visibility="visibilityChanged"
                  :frictionlessProps.sync="frictionlessBrandData"
                  :isSectionDisabled="isBrandConfigEditDisabled"
                  configLevel="BRAND"
                  @submitFrictionlessStore="submitFrictionlessStore"
                  @changeThirdPartyPartner="changeThirdPartyPartner"
                  @changeThirdPartyTechnology="changeThirdPartyTechnology"
                  @changeFrictionlessAllowed="changeFrictionlessAllowed"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs3 class="side-menu">
            <tableOfContents
              v-model="tocItems"
              :customClass="tocCustomClass"
              :items="tocItems"
              :excludedItems="excludedTOCItems"
              ref="toc"
            />
          </v-flex>
        </loading-wrapper>
      </v-layout>
      <save-footer
        v-if="modified && canSaveBrandConfig"
        :cancelAction="reset"
        :saveLabel="isNew ? 'Create Brand' : 'Save Changes'"
        :saveAction="isNew ? create : save"
      />
    </v-form>
  </v-container>
</template>

<script>
import axios from 'axios';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import isEqualWith from 'lodash/isEqualWith';
import some from 'lodash/some';
import DateTime from 'luxon/src/datetime.js';
import { v4 as UUid } from 'uuid';
import ID from '@compassdigital/id';

import tableOfContents from '@/components/tableOfContents';
import intersectionObserver from '@/components/intersectionObserver';
import { MEALPLAN_TYPES, TENDER_TYPES } from '@/constants';
import MealplanHelperModule from '@/helpers/mealplan-helper-module';
import Mealplan from '../../mealplan';
import Payment from '../../payment';
import Loyalty from '../../loyalty';
import Frictionless from '../../frictionless';

import serviceFee from './serviceFee';
import thirdParty from './thirdParty.vue';
import Information from './brandInfo.vue';
import Pickup from './pickup.vue';
import Delivery from './delivery.vue';
import kds from './kds.vue';
import MenuInfo from './menu.vue';
import AssignMenu from './assign_menus';
import PaymentExclusions from './payment_exclusions';
import ScanAndGo from './scan&go';
import CentricOS from './centricOS';
import DiningHall from './dininghall.vue';

export default {
  name: 'brand-settings',
  async beforeRouteLeave(to, from, next) {
    if (this.modified && !this.skipRouteCheck) {
      const input = await this.$confirm({
        title: 'Leave without saving?',
        message: 'Changes will be lost if you do not save.',
        buttonTrueText: 'LEAVE',
        buttonFalseText: 'cancel',
      });
      if (input) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  components: {
    Mealplan,
    Payment,
    Information,
    Pickup,
    Delivery,
    kds,
    MenuInfo,
    Loyalty,
    serviceFee,
    tableOfContents,
    AssignMenu,
    thirdParty,
    PaymentExclusions,
    ScanAndGo,
    Frictionless,
    intersectionObserver,
    CentricOS,
    DiningHall,
  },
  data: () => ({
    /**
     * Validation
     */
    isSaving: false,
    valid: true,
    skipRouteCheck: false,
    createdBrandID: '',
    loading: true,
    brand: {
      address: {},
      deliveryHours: [],
      hours: [],
      name: '',
      is: {
        pickup_supported: true,
        delivery_supported: false,
        frictionless_supported: false,
        promo_exemptions_enabled: undefined,
        plu_enabled: undefined,
        local_images_enabled: undefined,
        web_order_enabled: false,
        scan_and_go_supported: false,
        special_instructions_enabled: false,
        cafe360: false,
        dining_hall: false,
      },
      label: {},
      latitude: null,
      longitude: null,
      location: null,
      location_description: null,
      payment_provider: null,
      pos: null,
      style: { logo: '' },
      terminals: [],
      timezone: null,
      timeslots: {
        time: null, // hh:mm:ss
        customers_per_slot: null, // number
        menu_items_per_slot: null, // number
        delivery_time: null, // hh:mm:ss
        delivery_customers_per_slot: null, // number
        delivery_menu_items_per_slot: null, // number
        delivery_prep_time: null, // hh:mm:ss
        averagePrepTime: null, // hh:mm:ss
        delivery_user_defined: [],
      },
      company: '',
      meta: {
        type_of_kds: {
          cdl: false,
          nextep: false,
          volante: false,
          agilysys: false,
        },
        has_kds: false,
        max_showcase_items: null,
        frictionless_store_uuid: null,
        /*
        menu field is for setting centricOS(or other) menu props,
        not setting it by default since don't want it to show up in non-centricOS stations
        Example:
        menu: {
          brand: '',
        },
        */
      },
      external_id: null,
    },
    frictionlessState: { site_frictionless_supported: false, brand_frictionless_supported: false },
    sitePublicConfig: {},
    sitePrivateConfig: {},
    publicConfigBackup: {},
    publicConfig: {
      brand_location_description: null,
      phone_number: null,
      message: null,
      logos: {
        print: null,
      },
      calorie_disclaimer: {
        en: null,
        fr: null,
      },
      display_calories: false,
      full_name: null,
      delivery_destinations: [],
      delivery_destinations_automatic_timeslot: [],
      mealplan: [],
      has_kds: false,
      get_phone_number: false,
      show_instructions: false,
      show_single_timeslot: false,
      runner_app_enabled: false,
    },
    privateConfigBackup: {},
    privateConfig: {
      terminal_id: null,
      payment: {
        freedompay: null,
      },
      keystore: {
        payment: {
          freedompay: null,
        },
      },
      email: null,
      tax_rate: null,
      kds: {
        admin_passcode: null,
      },
      delivery_fee: null,
      service_fee: null,
      loyalty: { provider_id: undefined, loyalty_emails: [], enabled: false },
      excluded_payment_methods: {
        mealplan: [],
        credit_card: false,
        digital_wallet_pay: [],
        meal_swipes: [],
      },
    },
    otherSettings: {
      newLocation: '',
    },
    assignedMenus: [],
    assignedMenusBackup: [],
    isLoadingMenus: false,
    allMenus: [],
    menusToBeImported: [],
    showImportMenuDialog: false,
    isHeaderVisible: false,

    /**
     *  TOC items
     */
    tocItems: [
      {
        id: 'toc-information',
        title: 'Station Information',
      },
      {
        id: 'toc-serviceFee',
        title: 'Fee Setup',
      },
      {
        id: 'toc-pickup',
        title: 'Pickup Details',
      },
      {
        id: 'toc-delivery',
        title: 'Delivery Details',
      },
      {
        id: 'toc-CentricOS',
        title: 'CentricOS',
      },
      {
        id: 'toc-diningHall',
        title: 'Dining Hall',
      },
      {
        id: 'toc-ScanAndGo',
        title: 'Scan & Go Setup',
      },
      {
        id: 'toc-assignMenus',
        title: 'Assign Menus',
      },
      {
        id: 'toc-kds',
        title: 'Integration Type',
      },
      {
        id: 'toc-thirdParty',
        title: 'Third Party Integration',
      },
      {
        id: 'toc-menu-info',
        title: 'Menu Information',
      },
      {
        id: 'toc-payment',
        title: 'Payment ID',
      },
      {
        id: 'toc-mealplan',
        title: 'Meal Plan',
      },
      {
        id: 'toc-loyalty',
        title: 'Loyalty Program',
      },
      {
        id: 'toc-payment-exclusions',
        title: 'Payment Method Exclusion',
      },
      {
        id: 'frictionless-detail',
        title: 'Frictionless',
      },
    ],
    siteDeliveryDestinations: [],
  }),
  computed: {
    ...mapState('users', ['customPermissions']),
    ...mapState('validateMeqSetup', ['isInValidMeq']),
    ...mapState('sites', [
      'siteMap',
      'multigroupMap',
      'locationMap',
      'active_brand',
      'active_site',
      'multigroups',
    ]),
    ...mapGetters('adminPanel', [
      'isSiteOperator',
      'isImUser',
      'isAdmin',
      'hasSpecificPermissions',
    ]),
    ...mapGetters('sites', ['isDHSite', 'isDHBrand']),
    ...mapGetters('sectors', ['getCompany']),
    excludedTOCItems() {
      const excludedItems = [];
      if (this.isMSOStation) {
        excludedItems.push('toc-payment-exclusions');
      }

      // If site is not frictionless enabled, disable config for brand
      if (!this.frictionlessState.site_frictionless_supported) {
        excludedItems.push('frictionless-detail');
      }

      // If frictionless brand, no delivery section shown up
      if (this.frictionlessState.brand_frictionless_supported) {
        excludedItems.push('toc-delivery');
      }

      // Hide dining hall toggle for non boost sites
      if (!this.isBoost) {
        excludedItems.push('toc-diningHall');
      }

      return excludedItems;
    },
    availableTenders() {
      const tenders = [];
      if (!this.publicConfig.mealplan) return tenders;
      this.publicConfig.mealplan.forEach((m) => {
        tenders.push(...this.getAvailableTendersByType(m, TENDER_TYPES.DECLINING_BALANCE));
      });
      if (this.isCbordConfig(this.publicConfig)) {
        this.publicConfig.mealplan.forEach((m) => {
          tenders.push(...this.getAvailableTendersByType(m, TENDER_TYPES.MEAL_EQUIVALENT));
          tenders.push(...this.getAvailableTendersByType(m, TENDER_TYPES.MEAL_EXCHANGE));
        });
      }
      return tenders;
    },
    availableMealSwipes() {
      const tenders = [];
      if (!this.publicConfig.mealplan) return tenders;
      this.publicConfig.mealplan.forEach((m) => {
        if (!m || m.type !== MEALPLAN_TYPES.ATRIUM) return;
        tenders.push(...this.getAvailableTendersByType(m, TENDER_TYPES.MEAL_EQUIVALENT));
      });
      return tenders;
    },
    isMSOStation() {
      const currentLocation = this.locationMap[this.currentLocation];
      return !!(currentLocation && currentLocation.market_place);
    },
    isMarketplacePickupEnabled() {
      const currentLocation = this.locationMap[this.currentLocation];
      return currentLocation?.market_place?.is?.pickup_supported;
    },
    isMarketplaceDelEnabled() {
      const currentLocation = this.locationMap[this.currentLocation];
      return currentLocation?.market_place?.is?.delivery_supported;
    },
    brandDeliverySettings() {
      return {
        isEnabled: this.brand.is.delivery_supported,
        isUserDefined: this.brand.timeslots.delivery_is_user_defined,
      };
    },
    excludedTenders() {
      const excludedTenders = {};
      excludedTenders.mealplan = [];
      excludedTenders.mealSwipes = [];
      if (
        this.publicConfig &&
        this.publicConfig.mealplan[0] &&
        this.publicConfig.mealplan[0].type &&
        this.mealplanType
      ) {
        const { mealplanType } = this;
        this.privateConfig.excluded_payment_methods.mealplan.forEach((tender_id) => {
          if (tender_id) {
            const id = mealplanType !== 'atrium' ? ID(tender_id) && ID(tender_id).id : tender_id;
            excludedTenders.mealplan.push(id);
          }
        });
        this.privateConfig.excluded_payment_methods.meal_swipes.forEach((tender_id) => {
          if (tender_id) {
            const id = mealplanType !== 'atrium' ? ID(tender_id).id : tender_id;
            excludedTenders.mealSwipes.push(id);
          }
        });
      }
      return excludedTenders;
    },
    currentLocation: {
      get() {
        if (this.isNew) {
          return this.otherSettings.newLocation || '';
        }
        return (this.active_brand && this.active_brand.location) || this.otherSettings.newLocation;
      },
      set(v) {
        this.otherSettings.newLocation = v;
      },
    },
    brand_id() {
      return this.$route.params.brand_id;
    },
    isNew() {
      return !this.brand_id;
    },
    willEnableMarketplaceDelivery() {
      if (!this.brand.is.delivery_supported || this.brand.timeslots.delivery_is_user_defined)
        return false;

      const currentLocation = this.locationMap[this.currentLocation];

      if (
        !currentLocation ||
        !currentLocation.market_place ||
        currentLocation.market_place.is.delivery_supported
      )
        return false;

      const meetsCriteria =
        currentLocation.brands
          .map((brand) => {
            if (this.brand.id === brand.id) {
              return this.brand;
            }
            return brand;
          })
          .filter(
            (brand) => brand.is.delivery_supported && !brand.timeslots.delivery_is_user_defined,
          ).length >= 2;

      return meetsCriteria;
    },
    isMarketplaceDisabled() {
      if (!this.active_site) {
        return false;
      }
      const parentLocation = this.active_site.locations.find((location) => {
        return location.id === this.currentLocation;
      });
      return !!(
        parentLocation &&
        parentLocation?.market_place?.is?.delivery_supported &&
        parentLocation.market_place.delivery_details &&
        this.brand.is.delivery_supported &&
        !this.brand.timeslots.delivery_is_user_defined
      );
    },
    marketplaceDeliveryDestinations() {
      const currentLocation = this.locationMap[this.currentLocation];
      if (
        currentLocation &&
        currentLocation?.market_place?.is?.delivery_supported &&
        Array.isArray(currentLocation.market_place.delivery_destinations)
      ) {
        return currentLocation.market_place.delivery_destinations;
      }
      return null;
    },
    modified() {
      if (this.loading) return false;
      return (
        this.modifiedPayload.brand ||
        this.modifiedPayload.private ||
        this.modifiedPayload.public ||
        this.modifiedPayload.other ||
        this.modifiedPayload.assignedMenus ||
        this.modifiedPayload.menusToBeImported
      );
    },
    brandLocationDescription: {
      get() {
        return (
          this.publicConfig.brand_location_description || this.brand.location_description || ''
        );
      },
      set(value) {
        this.publicConfig.brand_location_description = value;
        this.brand.location_description = value;
      },
    },
    jdeCategory: {
      get() {
        if (this.isNew) {
          const company = this.getCompany(this.brand.company);
          return (company && company.meta && company.meta.jde_category) || '';
        }

        return (this.brand.meta && this.brand.meta.jde_category) || '';
      },
      set(value) {
        if (!this.brand.meta) this.$set(this.brand, 'meta', { jde_category: value });
        this.$set(this.brand.meta, 'jde_category', value);
      },
    },
    brandCompany() {
      return this.brand && this.brand.company;
    },
    brandMaxShowcaseItems: {
      get() {
        return this.brand.meta && this.brand.meta.max_showcase_items;
      },
      set(value) {
        if (!this.brand.meta) this.brand.meta = { max_showcase_items: value };
        this.brand.meta.max_showcase_items = value;
      },
    },
    modifiedPayload() {
      const brandPayload = isEqualWith(this.brand, this.active_brand, (curr, old) =>
        Object.keys(curr).reduce((results, key) => {
          if (!results) {
            return false;
          }

          if (key === 'company') {
            return true;
          }
          if (!curr || !old) return true;
          if (curr[key] === null || curr[key] === undefined) {
            return true;
          }
          return isEqual(old[key], curr[key]);
        }, true),
      );
      const privatePayload = isEqual(this.privateConfig, this.privateConfigBackup);
      const publicPayload = isEqual(this.publicConfig, this.publicConfigBackup);
      const otherPayload = some(this.otherSettings, (v) => !v);
      const assignedMenusPayload = isEqual(this.assignedMenus, this.assignedMenusBackup);
      const menusToBeImportedPayload = this.menusToBeImported.length === 0;
      return {
        brand: !brandPayload,
        private: !privatePayload,
        public: !publicPayload,
        other: !otherPayload,
        assignedMenus: !assignedMenusPayload,
        menusToBeImported: !menusToBeImportedPayload,
      };
    },
    isGlobalImagesEnabled() {
      if (!this.brand.company) return false;
      const company = this.getCompany(this.brand.company);
      if (!company) return false;
      return (company && company.is && company.is.global_images_enabled) || false;
    },
    isBrandConfigEditDisabled() {
      return this.isSiteOperator || this.isImUser;
    },
    canSaveBrandConfig() {
      return (
        !this.isBrandConfigEditDisabled ||
        !this.isTimeslotEditDisabled ||
        !this.isMenuAssignationDisabled
      );
    },
    isTimeslotEditDisabled() {
      return (
        this.isBrandConfigEditDisabled &&
        !this.hasSpecificPermissions([this.customPermissions.editBrandTimeslots])
      );
    },
    isMenuAssignationDisabled() {
      return (
        this.isBrandConfigEditDisabled &&
        !this.hasSpecificPermissions([this.customPermissions.assignBrandMenus])
      );
    },
    isScanAndGoToggledOn() {
      const original_has_scan_and_go =
        this.active_brand && this.active_brand.is && this.active_brand.is.scan_and_go_supported;
      const updated_brand_has_scan_and_go = this.brand.is && this.brand.is.scan_and_go_supported;
      return updated_brand_has_scan_and_go && !original_has_scan_and_go;
    },
    isCentricOSToggledOn() {
      return this.brand?.is?.cafe360;
    },
    mealplanType() {
      return (
        (this.publicConfig &&
          this.publicConfig.mealplan &&
          this.publicConfig.mealplan[0] &&
          this.publicConfig.mealplan[0].type &&
          this.publicConfig.mealplan[0].type.toLowerCase()) ||
        ''
      );
    },
    tocCustomClass() {
      // Set setting menu class to fixed position if header is not visible on the viewport
      return this.isHeaderVisible ? '' : 'fixedStyle';
    },
    frictionlessBrandData: {
      get() {
        return {
          frictionless_supported: this.brand.is.frictionless_supported,
          frictionless_store_uuid: this.brand.meta.frictionless_store_uuid,
          frictionless_third_party_partner: this.brand.meta.frictionless_third_party_partner,
          frictionless_third_party_technology: this.brand.meta.frictionless_third_party_technology,
        };
      },
      set(obj) {
        const brand = { ...this.brand };
        const { frictionless_supported, frictionless_store_uuid } = obj;

        brand.is = {
          ...brand.is,
          frictionless_supported,
        };

        if (obj.frictionless_store_uuid !== undefined) {
          brand.meta = {
            ...brand.meta,
            frictionless_store_uuid,
          };
          brand.external_id = frictionless_store_uuid;
        }

        this.brand = brand;
      },
    },
    selectedCentricOSbrand: {
      get() {
        return this.brand.meta?.menu?.brand;
      },
    },
    selectedCentricOSLMG: {
      get() {
        return this.brand.meta?.menu?.local_menu_group;
      },
      set(local_menu_group) {
        if (!this.isCentricOSToggledOn) return;
        this.$set(this.brand, 'meta', {
          ...(this.brand.meta || {}),
          menu: { ...(this.brand.meta?.menu || {}), local_menu_group },
        });
      },
    },
    isBoost() {
      const activeMultigroup = this.multigroups.find((e) => e.id === this.$route.params.app);
      return activeMultigroup?.name?.toLocaleLowerCase() === 'boost';
    },
  },
  methods: {
    ...mapActions('centricOSMenu', ['getMenuBrand']),
    ...mapMutations('validateMeqSetup,', ['setInValidMeq']),
    ...mapActions('menus', [
      'postMenu',
      'fetchMenu',
      'putMenu',
      'patchMenu',
      'fetchCompanyMenu',
      'saveFrictionless',
    ]),
    ...mapActions('sites', [
      'postLocationBrand',
      'putLocationBrand',
      'patchLocation',
      'getConfigPublic',
      'getConfigPrivate',
      'getDeliveryDestinations',
      'isSiteFrictionless',
      'getLocationBrand',
    ]),
    ...MealplanHelperModule,
    isDH() {
      return this.isDHBrand(this.brand);
    },
    updateDigitalWalletPay(walletExclusions) {
      if (
        this.privateConfig &&
        this.privateConfig.excluded_payment_methods &&
        this.privateConfig.excluded_payment_methods.digital_wallet_pay
      ) {
        this.$set(
          this.privateConfig.excluded_payment_methods,
          'digital_wallet_pay',
          walletExclusions,
        );
      }
    },
    isTenderEqual(v1, v2) {
      if (!this.publicConfig.mealplan || !this.publicConfig.mealplan[0]) return false;
      let { mealplanType } = this;
      if (mealplanType && mealplanType === MEALPLAN_TYPES.CBORD_DIRECT.toLowerCase()) {
        mealplanType = MEALPLAN_TYPES.CBORD.toLowerCase(); // Mealplan type is cbord direct.. tenders should be encoded to provider "CBORD".
      }
      if (mealplanType && mealplanType === MEALPLAN_TYPES.BLACKBOARD_SAAS.toLowerCase()) {
        mealplanType = MEALPLAN_TYPES.BLACKBOARD.toLowerCase(); // set type for BLACKBOARD SAAS to BLACKBOARD to route to correct provider
      }
      const tenderValue1 = v1.value || v1;
      const encodedID1 = ID('mealplan', mealplanType, 'tender', v1.value || v1);
      const encodedID2 = ID('mealplan', mealplanType, 'tender', v2.value || v2);
      const tenderValue2 = v2.value || v2;

      return (
        tenderValue1 === tenderValue2 ||
        tenderValue1 === encodedID2 ||
        tenderValue2 === encodedID1 ||
        encodedID1 === encodedID2
      );
    },
    updateMealSwipes(walletExclusions) {
      let { mealplanType } = this;
      if (mealplanType && this.privateConfig && this.privateConfig.excluded_payment_methods) {
        const encodedTenders = [];
        walletExclusions.forEach((id) => {
          if (
            mealplanType === MEALPLAN_TYPES.ITCSTANDARD.toLowerCase() ||
            mealplanType === MEALPLAN_TYPES.ITCDIETARYRESTRICTION.toLowerCase() ||
            mealplanType === MEALPLAN_TYPES.ITCLUNCHBOX.toLowerCase() ||
            mealplanType === MEALPLAN_TYPES.ITCSSO.toLowerCase()
          ) {
            mealplanType = this.getMealplanProviderName(mealplanType);
          }
          encodedTenders.push(
            mealplanType !== 'atrium' ? ID('mealplan', mealplanType, 'tender', id) : id,
          );
        });
        this.$set(this.privateConfig.excluded_payment_methods, 'meal_swipes', encodedTenders);
      }
    },
    updateMealPlan(walletExclusions) {
      let { mealplanType } = this;
      if (mealplanType && mealplanType === MEALPLAN_TYPES.CBORD_DIRECT.toLowerCase()) {
        mealplanType = MEALPLAN_TYPES.CBORD.toLowerCase(); // Mealplan type is cbord direct.. tenders should be encoded to provider "CBORD".
      }
      if (mealplanType && mealplanType === MEALPLAN_TYPES.BLACKBOARD_SAAS.toLowerCase()) {
        mealplanType = MEALPLAN_TYPES.BLACKBOARD.toLowerCase(); // // set type for BLACKBOARD SAAS to BLACKBOARD to route to correct provider
      }
      if (mealplanType && this.privateConfig && this.privateConfig.excluded_payment_methods) {
        const encodedTenders = [];
        walletExclusions.forEach((id) => {
          if (
            mealplanType === MEALPLAN_TYPES.ITCSTANDARD.toLowerCase() ||
            mealplanType === MEALPLAN_TYPES.ITCDIETARYRESTRICTION.toLowerCase() ||
            mealplanType === MEALPLAN_TYPES.ITCLUNCHBOX.toLowerCase() ||
            mealplanType === MEALPLAN_TYPES.ITCSSO.toLowerCase()
          ) {
            mealplanType = this.getMealplanProviderName(mealplanType);
          }
          encodedTenders.push(
            mealplanType !== 'atrium' ? ID('mealplan', mealplanType, 'tender', id) : id,
          );
        });
        this.$set(this.privateConfig.excluded_payment_methods, 'mealplan', encodedTenders);
      }
    },
    async setTimezone() {
      const { data } = await axios.get('https://maps.googleapis.com/maps/api/timezone/json', {
        params: {
          location: `${this.brand.latitude},${this.brand.longitude}`,
          timestamp: DateTime.local().toFormat('X'),
          key: 'AIzaSyDRnRibHgCMGi4WqKwNItzDAg0bteGelvg',
        },
      });
      this.brand.timezone = data.timeZoneId;
    },
    async fetchPublicConfig(configid) {
      try {
        const publicConfig = await this.getConfigPublic({ id: configid });
        if (publicConfig) {
          this.publicConfig = Object.assign(this.publicConfig, publicConfig);
          this.publicConfigBackup = cloneDeep(this.publicConfig);
        }
      } catch {
        this.publicConfigBackup = cloneDeep(this.publicConfig);
      }
    },
    async fetchPrivateConfig(configid) {
      try {
        const privateConfig = await this.getConfigPrivate({ id: configid });

        if (privateConfig) {
          this.privateConfig = Object.assign(this.privateConfig, privateConfig);
          this.privateConfigBackup = cloneDeep(this.privateConfig);
        }
      } catch {
        this.privateConfigBackup = cloneDeep(this.privateConfig);
      }
    },
    saveConfig(type, config, brandID, retry = 0) {
      const configPredicate = (v) => {
        if (isNil(v)) {
          return true;
        }
        if (Array.isArray(v) && v.length === 0) {
          return true;
        }
        if (typeof v === 'object') {
          return isEmpty(omitBy(v, configPredicate));
        }
        return false;
      };
      const payload = omitBy(config, configPredicate);

      const url = type === 'public' ? `/config/public/${brandID}` : `/config/${brandID}`;
      return this.api.post(url, payload).catch((e) => {
        if (retry < 5) {
          return this.saveConfig(type, config, brandID, retry + 1);
        }
        return Promise.reject(e);
      });
    },
    async saveAssignedMenus(brandId) {
      try {
        if (this.isCentricOSToggledOn) {
          // we don't do linked=true for centricOS views
          // link centricOS converted v2 menus with location_brand for nextep event trigger
          const menuBrand = await this.getMenuBrand({
            id: this.selectedCentricOSbrand,
            params: { relationships: '["menus"]' },
          });
          await Promise.all(
            menuBrand?.menus?.map((menu) => {
              const payload = { id: menu.id, location_brand: brandId };
              return this.patchMenu({ payload });
            }),
          );
          return;
        }
        const addedMenus = this.assignedMenus.filter((menu) => isNil(menu.id));
        const deletedMenus = this.assignedMenusBackup.filter(
          (menu) => !this.assignedMenus.find((d) => d.parent_id === menu.parent_id),
        );
        const existingMenus = this.assignedMenus.filter(
          (menu) => !isNil(menu.id) && !deletedMenus.find((m) => menu.id === m.id),
        );

        const addedMenuRequests = addedMenus.map(async (menu) => {
          const existingLocalMenu = this.allMenus.filter(
            (e) => e.parent_id === menu.parent_id && e.location_brand === brandId,
          );

          if (existingLocalMenu.length > 0) {
            // we already have a local menu for this global menu so we set the menu id to local menu id.
            menu.id = existingLocalMenu[0].id;
            await this.linkMenu(menu, true);
          } else {
            const menuDetails = await this.postMenu({
              location_brand: brandId,
              company: this.brand.company,
              parent_id: menu.parent_id,
              sector: this.brand.sector,
            });
            menu.id = menuDetails.id;
            await this.linkMenu(menu, true);
          }
        });
        const deletedMenuRequests = deletedMenus.map(async (menu) => {
          await this.linkMenu(menu, false);
        });

        const existingMenuRequests = existingMenus.map(async (menu) => {
          await this.linkMenu(menu, true);
        });

        await Promise.all([...addedMenuRequests, ...deletedMenuRequests, ...existingMenuRequests]);
      } catch (error) {
        console.error(error);
      }
    },
    async linkMenu(menu, isLink) {
      try {
        const wasLinkedBefore = menu.is && menu.is.linked;
        const menuIsPayload = isLink === wasLinkedBefore ? undefined : { linked: isLink };
        const payload = {
          id: menu.id,
          is: menuIsPayload,
          groups: this.getUpdatedCategories(menu),
        };
        if (!payload.is && !payload.groups.length) return; // nothing changed
        await this.patchMenu({ payload, _query: '{id}' });
        this.fetchMenu({ id: menu.id, nocache: true, refreshCache: true, show_unlinked: true });
      } catch (error) {
        console.error(error);
        throw new Error('Could not assign menu.');
      }
    },
    getUpdatedCategories(menu) {
      const updatedGroups = [];
      const backupMenu = this.assignedMenusBackup.find((m) => m.id === menu.id);
      (menu.allGroups ?? []).forEach((group) => {
        const updatedGroup = menu.groups.find((g) => g.id === group.id);
        const backupGroup =
          backupMenu && backupMenu.groups && backupMenu.groups.find((g) => g.id === group.id);
        if (updatedGroup && backupGroup) return; // it was linked and remains linked
        if (!updatedGroup && !backupGroup) return; // it was unlinked and remains unlinked

        updatedGroups.push({
          id: group.id,
          is: {
            linked: Boolean(updatedGroup),
          },
        });
      });
      return updatedGroups;
    },
    async importMenus(brandId) {
      // we need to get all company menus
      const { menus } = await this.fetchCompanyMenu({ id: this.brandCompany, nocache: true });
      const importMenuRequests = this.menusToBeImported.map(async (menu) => {
        const menuToBeUpdated = await this.fetchMenu({
          id: menu,
          nocache: true,
          show_unlinked: true,
        });
        if (menuToBeUpdated) {
          const existingLocalMenu = menus.find(
            (e) => e.parent_id === menuToBeUpdated.parent_id && e.location_brand === brandId,
          );
          try {
            if (existingLocalMenu) {
              menuToBeUpdated.id = existingLocalMenu.id;
              menuToBeUpdated.location_brand = existingLocalMenu.location_brand;
              // we will overwrite the exsting with imported menu
              await this.putMenu({
                ...menuToBeUpdated,
              });
            } else {
              // we will create a new local menu for this brand
              menuToBeUpdated.location_brand = brandId;
              delete menuToBeUpdated.id;
              const newMenu = await this.postMenu({
                ...menuToBeUpdated,
              });
              if (newMenu) {
                menuToBeUpdated.id = newMenu.id;
                this.putMenu({
                  ...menuToBeUpdated,
                });
              }
            }
          } catch (error) {
            console.error(error);
            throw new Error('Could not import menu.');
          }
        }
      });
      await Promise.all(importMenuRequests);
      return true;
    },
    isBoolean(val) {
      return typeof val === 'boolean';
    },
    async save() {
      this.$refs.form.validate();
      if (!this.valid) {
        this.$toast('The form is not yet complete, please fix before saving');
        return;
      }
      // validate MEQ setup, check all tenders
      const isAtriumTenders =
        this.publicConfig &&
        this.publicConfig.mealplan &&
        this.publicConfig.mealplan.length > 0 &&
        this.publicConfig.mealplan[0].type === MEALPLAN_TYPES.ATRIUM;
      if (isAtriumTenders) {
        for (const tender of this.publicConfig.mealplan[0].private.config.tenders) {
          this.$store.commit('validateMeqSetup/setInValidMeq', tender);
          if (this.isInValidMeq) {
            this.$toast('Meal Equivalency Tender Setup Incomplete.');
            return;
          }
        }
      }
      this.isSaving = true;
      try {
        const promises = [];
        if (this.modifiedPayload.brand) {
          this.brand.label.en = this.brand.name;
          if (!this.brand.location_description) {
            this.brand.location_description = '';
          }
          // lookup timezone
          const brand_update_promise = Promise.all([
            this.setTimezone(),
            this.$refs.brandInfo.save(),
          ]).then(() => this.putLocationBrand(this.brand));
          promises.push(brand_update_promise);
        }

        // handle mealplan config split for ATRIUM
        const mealplanPublicConfig = this.polishMealplan(this.publicConfig.mealplan, 'public');
        const mealplanPrivateConfig = this.polishMealplan(this.publicConfig.mealplan, 'private');
        const mealplanType =
          mealplanPublicConfig &&
          Array.isArray(mealplanPublicConfig) &&
          mealplanPublicConfig.length > 0 &&
          mealplanPublicConfig[0] &&
          mealplanPublicConfig[0].type;
        const isAtrium = mealplanType === MEALPLAN_TYPES.ATRIUM;
        const isCbord = mealplanType && mealplanType.includes(MEALPLAN_TYPES.CBORD);
        const isBlackboard = this.isTypeBlackboard(mealplanType);
        if (isAtrium) {
          this.publicConfig.mealplan = mealplanPublicConfig;
          this.privateConfig.mealplan = mealplanPrivateConfig;
          // this.privateConfig.keystore = {};
        }
        if (isCbord) {
          this.publicConfig.mealplan = mealplanPublicConfig;
          // this.$set(this.privateConfig, 'keystore', {});
          this.$set(this.privateConfig, 'mealplan', []);
          // this.$set(this.privateConfigBackup, 'keystore', {});
          this.$set(this.privateConfigBackup, 'mealplan', []);
          this.modifiedPayload.public = true;
        }
        if (isBlackboard) {
          this.$set(this.publicConfig, 'mealplan', mealplanPublicConfig);
        }
        if (!isAtrium && !isCbord) {
          // for non-atrium, non-cbord types with no private config, reset back to blank since modifiedPayload.private will be false
          if (this.privateConfig.mealplan && !isEqual(this.privateConfig.mealplan, [])) {
            this.$set(this.privateConfig, 'mealplan', []);
          }
          // if (this.privateConfig.keystore && !isEqual(this.privateConfig.keystore, {})) {
          //   this.$set(this.privateConfig, 'keystore', {});
          // }
        }

        if (this.modifiedPayload.public) {
          const public_config_update_promise = this.$refs.kds
            .save()
            .then(() => this.saveConfig('public', this.publicConfig, this.brand_id));
          promises.push(public_config_update_promise);
        }

        if (this.modifiedPayload.private) {
          promises.push(this.saveConfig('private', this.privateConfig, this.brand_id));
        }

        if (this.modifiedPayload.assignedMenus || this.modifiedPayload.brand) {
          promises.push(this.saveAssignedMenus(this.brand_id));
        }
        if (this.modifiedPayload.menusToBeImported) {
          promises.push(this.importMenus(this.brand_id));
        }

        await Promise.all(promises);

        if (this.modifiedPayload.brand || this.modifiedPayload.public) {
          this.$store.commit('adminPanel/setLoading', true);
          const update_cached_data = [];
          update_cached_data.push(
            this.$store.dispatch('sites/fetchBrand', { id: this.active_brand.id, nocache: true }),
          );
          update_cached_data.push(
            this.$store.dispatch('sites/fetchSite', {
              id: this.active_site.id,
              multigroupId: this.$route.params.app,
              nocache: true,
            }),
          );
          Promise.all(update_cached_data).catch((error) => {
            console.error(error);
          });
          this.$store.commit('adminPanel/setLoading', false);
        }

        this.skipRouteCheck = true;
        this.$toast('Updated Brand settings');
        this.$router.push({
          name: 'brand-overview-menus',
          params: { site_id: this.active_site.id, brand_id: this.active_brand.id },
        });
      } catch (err) {
        if (err.response) {
          const {
            data: { code, error },
          } = err.response;
          if (code === 400.39 || code === 422) {
            this.$toast.error(error);
          }
        } else {
          this.$toast('Could not save');
        }
      }
      this.isSaving = false;
    },
    async create() {
      this.$refs.form.validate();
      if (!this.valid) {
        this.$toast('The form is not yet complete, please fix before saving');
        return;
      }
      this.isSaving = true;
      const location = this.active_site.locations.find(
        (e) => e.id === this.otherSettings.newLocation,
      );
      this.brand.location = location.id;
      this.brand.latitude = location.latitude;
      this.brand.longitude = location.longitude;
      this.brand.address = location.address;
      this.brand.label.en = this.brand.name;

      // these values must be defined
      this.brand.timeslots.delivery_time = this.brand.timeslots.delivery_time || '00:01:00';
      this.brand.timeslots.delivery_customers_per_slot =
        this.brand.timeslots.delivery_customers_per_slot || 1;
      this.brand.timeslots.delivery_menu_items_per_slot =
        this.brand.timeslots.delivery_menu_items_per_slot || 1;

      try {
        // set timezone
        await this.setTimezone();

        await this.$refs.brandInfo.save();
        if (!this.createdBrandID) {
          const external_partner =
            this.active_site && this.active_site.meta && this.active_site.meta.external_partner;
          if (external_partner) {
            const uuid = UUid().replace(/-/g, '');
            this.brand.id = `${external_partner}/${uuid}`;
          }
          this.brand.location = location.id;
          const brandCreated = await this.postLocationBrand(this.brand);
          this.createdBrandID = brandCreated.id;
        }

        await this.$refs.kds.save();
        // take care of mealplan (split into the configs appropriately)
        const mealplanPublicConfig = this.polishMealplan(this.publicConfig.mealplan, 'public');
        const mealplanPrivateConfig = this.polishMealplan(this.publicConfig.mealplan, 'private');
        const publicConfigCopy = cloneDeep(this.publicConfig);
        const privateConfigCopy = cloneDeep(this.privateConfig);
        const mealplanType =
          mealplanPublicConfig &&
          Array.isArray(mealplanPublicConfig) &&
          mealplanPublicConfig.length > 0 &&
          mealplanPublicConfig[0] &&
          mealplanPublicConfig[0].type;
        if (mealplanType === MEALPLAN_TYPES.ATRIUM) {
          publicConfigCopy.mealplan = mealplanPublicConfig;
          privateConfigCopy.mealplan = mealplanPrivateConfig;
        }
        if (this.isTypeBlackboard(mealplanType)) {
          publicConfigCopy.mealplan = mealplanPublicConfig;
          privateConfigCopy.mealplan = undefined;
        }
        await this.saveConfig('public', publicConfigCopy, this.createdBrandID);
        await this.saveConfig('private', privateConfigCopy, this.createdBrandID);
        await this.saveAssignedMenus(this.createdBrandID);
        if (this.menusToBeImported.length > 0) await this.importMenus(this.createdBrandID);

        this.$toast('Brand created successfully');
        await this.$store.dispatch('sites/fetchSite', {
          id: this.active_site.id,
          multigroupId: this.$route.params.app,
          fetchWithPermissions: true,
          nocache: true,
        });
        this.skipRouteCheck = true;
        this.$router.push({ name: 'site-info', params: { site_id: this.active_site.id } });
      } catch (err) {
        this.$toast('Could not create brand');
        console.error(err);
      }
      this.isSaving = false;
    },
    reset() {
      this.brand = cloneDeep(this.active_brand);
      this.publicConfig = cloneDeep(this.publicConfigBackup);
      this.privateConfig = cloneDeep(this.privateConfigBackup);
      this.assignedMenus = cloneDeep(this.assignedMenusBackup);
      this.$toast('All your changes have been reset');
    },
    visibilityChanged(isVisible, entry) {
      this.$refs.toc.visibilityChanged(isVisible, entry);
    },
    polishMealplan(mealplan, configType) {
      const m = mealplan && mealplan[0];
      if (m) {
        if (m.type === MEALPLAN_TYPES.ATRIUM) {
          let { id } = m.public;
          const decoded_id = id && ID(id) && ID(id).id;
          // decoded id should be in format site_id:brand_id
          const brand_part = decoded_id && decoded_id.split(':')[1];
          if (this.isNew && this.createdBrandID && !brand_part) {
            id = ID(
              'mealplan',
              'atrium',
              'mealplan',
              `${this.active_site.id}:${this.createdBrandID}`,
            );
          }
          if (configType === 'private') {
            /* tender configs have been moved to public config, cloning to private to maintain backward compatibility */
            const updated_private_config = cloneDeep(m.private);
            updated_private_config.config.tenders = cloneDeep(m.public.tenders);
            m.private.config.tenders = m.public.tenders;
            return [
              {
                ...updated_private_config,
                id,
                name: m.name,
                type: MEALPLAN_TYPES.ATRIUM,
              },
            ];
          }
          if (configType === 'public') {
            return [
              {
                id,
                name: m.name,
                tenders: m.public.tenders,
                type: MEALPLAN_TYPES.ATRIUM,
                note: m.note,
                valid_email_domains: m.valid_email_domains,
              },
            ];
          }
        }
        if (m.type === MEALPLAN_TYPES.CBORD || m.type === MEALPLAN_TYPES.CBORD_DIRECT) {
          if (configType === 'public') {
            const decoded = ID(this.active_site.id);
            decoded.service = 'mealplan';
            decoded.type = 'mealplan';
            decoded.provider = 'cbord';
            return [
              {
                id: ID(decoded),
                name: m.name,
                tenders: m.tenders,
                type: m.type,
                note: m.note,
                valid_email_domains: m.valid_email_domains,
                terminal_id: m.terminal_id,
                isBrandMEQ: m.isBrandMEQ || false,
                isBrandMX: m.isBrandMX || false,
              },
            ];
          }
        }
        if (
          this.isTypeBlackboard(m.type) ||
          this.isTypeItc(m.type) ||
          this.isTypeHeartland(m.type)
        ) {
          let { id } = m;
          const decoded_id = id && ID(id) && ID(id).id;
          // for blackboard: decoded id should be in format site_id:brand_id
          const brand_part = decoded_id && decoded_id.split(':')[1];
          if (this.isNew && this.createdBrandID && !brand_part) {
            const mealplanLocationId =
              this.isTypeItc(m.type) || this.isTypeHeartland(m.type)
                ? this.active_site.id
                : `${this.active_site.id}:${this.createdBrandID}`;
            id = ID(
              'mealplan',
              this.getMealplanProviderName(m.type),
              'mealplan',
              `${mealplanLocationId}`,
            );
          }
          if (configType === 'private') {
            return [
              {
                ...m.private,
                id,
              },
            ];
          }
          if (configType === 'public') {
            return [
              {
                ...m,
                private: undefined,
                id,
              },
            ];
          }
        }
      }

      return mealplan;
    },
    fuseMealplan(m1, m2) {
      if (!m1) return [];
      m2 = cloneDeep(m2);
      if (m1.type === MEALPLAN_TYPES.CBORD || m1.type === MEALPLAN_TYPES.CBORD_DIRECT) {
        return [
          {
            id: m1.id,
            name: m1.name,
            note: m1.note,
            tenders: m1.tenders,
            private: m2,
            type: m1.type,
            valid_email_domains: m1.valid_email_domains,
            terminal_id: m1.terminal_id,
            isBrandMEQ: m1.isBrandMEQ || false,
            isBrandMX: m1.isBrandMX || false,
          },
        ];
      }
      if (this.isTypeItc(m1.type) || this.isTypeHeartland(m1.type)) {
        return [
          {
            ...m1,
            private: { keystore: {}, ...m2 },
          },
        ];
      }
      if (m1.type !== MEALPLAN_TYPES.ATRIUM) return [m1];
      if (!m2.id) m2.id = m1.id;
      return [
        {
          id: m1.id,
          name: m1.name,
          note: m1.note,
          public: m1,
          private: m2,
          type: m1.type,
          valid_email_domains: m1.valid_email_domains,
          terminal_id: m2.config.terminal_id,
        },
      ];
    },
    async getCompanyMenus(companyId) {
      if (!companyId) return;

      this.isLoadingMenus = true;
      const { menus } = await this.fetchCompanyMenu({ id: companyId, nocache: true });
      this.allMenus = [...menus];
      const menusToBeAssigned = menus.filter(
        (e) =>
          this.$route.params.brand_id &&
          e.location_brand === this.$route.params.brand_id &&
          e?.is?.linked,
      );
      const requests = menusToBeAssigned.map(async (menu) => {
        await this.loadCategories(menu);
      });
      await Promise.all(requests);
      this.$set(this, 'assignedMenus', menusToBeAssigned);

      this.isLoadingMenus = false;
      this.assignedMenusBackup = cloneDeep(this.assignedMenus);
      this.showImportMenuDialog = false;
    },
    async loadCategories(menu) {
      // we get this global menu to get categories
      let globalCategories = [];
      let localCategories = [];
      try {
        const [globalMenuDetails, localMenuDetails] = await Promise.all([
          this.fetchMenu({
            id: menu.parent_id,
            _query: '{id,label,is,groups{id,label,is}}',
            nocache: true,
            show_unlinked: true,
          }),
          this.fetchMenu({
            id: menu.id,
            _query: '{id,label,is,groups{id,label,is}}',
            nocache: true,
            show_unlinked: true,
          }),
        ]);

        globalCategories = globalMenuDetails.groups.map((g) => {
          if (!g.label || !g.label.en) {
            g.label = { en: 'Untitled' };
          }
          return {
            id: g.id,
            value: g.id,
            text: g.label.en,
            isLinked: true,
            isDisabled: g.is.disabled || false,
          };
        });
        localCategories = localMenuDetails.groups
          .filter((g) => g.is && g.is.linked)
          .map((g) => {
            if (!g.label || !g.label.en) {
              g.label = { en: 'Untitled' };
            }
            return {
              id: g.id,
              value: g.id,
              text: g.label.en,
              isLinked: true,
              isDisabled: g.is.disabled || false,
            };
          });
        const disabledLinkedCategories = globalCategories.filter(
          (g) =>
            g.isDisabled &&
            localMenuDetails.groups.find((m) => m.id === g.id && m.is && m.is.linked),
        );
        localCategories.push(...disabledLinkedCategories);
      } catch {
        this.$toast.error('Could not fetch menu details for menu categories.');
      }
      this.$set(menu, 'groups', [...localCategories]);
      this.$set(menu, 'allGroups', [...globalCategories]);
    },
    getAvailableTendersByType(mealplan, tender_type = TENDER_TYPES.DECLINING_BALANCE) {
      const tenders = [];
      if (!mealplan) return tenders;
      if (mealplan.type !== MEALPLAN_TYPES.ATRIUM) {
        if (mealplan.tenders && mealplan.tenders.length) {
          tenders.push(...mealplan.tenders.filter((t) => t.type === tender_type).map((t) => t.id));
        }
        return tenders;
      }
      const atriumTenders =
        mealplan && mealplan.private && mealplan.private.config && mealplan.private.config.tenders;
      if (!atriumTenders || atriumTenders.length === 0) return tenders;
      atriumTenders.forEach((tender_in_private_config) => {
        if (tender_in_private_config.type !== tender_type) return;
        const tender_in_public_config = mealplan.public.tenders.find(
          (t) =>
            ID('mealplan', 'atrium', 'tender', t.id) === tender_in_private_config.tender_number,
        );
        if (!tender_in_public_config) return;
        tenders.push({
          text: tender_in_public_config.id,
          value: tender_in_private_config.tender_number,
        });
      });
      return tenders;
    },
    getDefaultCalorieDisclaimer() {
      if (this.active_site?.address?.country === 'US') {
        return {
          en:
            'Consuming raw or undercooked meats, poultry, seafood, shellfish, or eggs may increase your risk of foodborne illness, especially if you have a medical condition.',
          fr: '',
        };
      }
      return this.publicConfig.calorie_disclaimer;
    },

    // Update the 'brand' object with payload data
    updateBrand(payload) {
      const brand = { ...this.brand };

      brand.is = {
        ...brand.is,
        frictionless_supported: payload.frictionless_supported,
      };

      if (payload.frictionless_supported) {
        brand.meta = {
          ...brand.meta,
          frictionless_store_uuid: payload.frictionless_store_uuid,
        };
        brand.external_id = payload.frictionless_store_uuid;
      }

      this.brand = brand;
    },

    // Method to handle the frictionless store update
    submitFrictionlessStore(payload, resolve) {
      this.updateBrand(payload);
      resolve(payload);
    },

    // Method to change the frictionless allowed status
    changeFrictionlessAllowed(payload, resolve) {
      // Directly updating only the necessary part without affecting other properties
      this.$set(this.brand.is, 'frictionless_supported', payload.frictionless_supported);
      this.$set(
        this.brand.meta,
        'frictionless_third_party_partner',
        payload.frictionless_third_party_partner,
      );
      resolve(payload);
    },

    changeThirdPartyPartner(selectedOption) {
      const brand = { ...this.brand };

      brand.meta = {
        ...brand.meta,
        frictionless_third_party_partner: selectedOption,
      };

      this.brand = brand;
    },

    changeThirdPartyTechnology(selectedOption) {
      const brand = { ...this.brand };

      brand.meta = {
        ...brand.meta,
        frictionless_third_party_technology: selectedOption,
      };

      this.brand = brand;
    },

    intersect(intersected) {
      this.isHeaderVisible = intersected;
    },
    editKeystore(keystoreValues) {
      this.privateConfig.keystore = keystoreValues;
    },
    editPayment(paymentValues) {
      this.privateConfig.payment = paymentValues;
    },
  },
  watch: {
    availableMealSwipes(ms) {
      if (this.isSaving) return;
      const excludedMealSwipes =
        this.privateConfig &&
        this.privateConfig.excluded_payment_methods &&
        this.privateConfig.excluded_payment_methods.meal_swipes;
      if (excludedMealSwipes && excludedMealSwipes.length > 0) {
        const mealSwipesToDelete = [];
        excludedMealSwipes.forEach((m) => {
          const tenderFound = ms && ms.find((tender) => this.isTenderEqual(tender, m));
          if (!tenderFound) {
            mealSwipesToDelete.push(m);
          }
        });
        if (mealSwipesToDelete.length > 0) {
          mealSwipesToDelete.forEach((m) => {
            let indexToDelete = -1;
            excludedMealSwipes.forEach((et, index) => {
              if (indexToDelete >= 0) return;
              if (this.isTenderEqual(et, m)) {
                indexToDelete = index;
              }
            });
            if (indexToDelete < 0) return;
            this.privateConfig.excluded_payment_methods.meal_swipes.splice(indexToDelete, 1);
          });
        }
      }
    },
    availableTenders(t) {
      if (this.isSaving) return;
      const excludedTenders =
        this.privateConfig &&
        this.privateConfig.excluded_payment_methods &&
        this.privateConfig.excluded_payment_methods.mealplan;
      if (excludedTenders && excludedTenders.length > 0) {
        const tendersToDelete = [];
        excludedTenders.forEach((m) => {
          const tenderFound = t && t.find((tender) => this.isTenderEqual(tender, m));
          if (!tenderFound) {
            tendersToDelete.push(m);
          }
        });
        if (tendersToDelete.length > 0) {
          tendersToDelete.forEach((m) => {
            let indexToDelete = -1;
            excludedTenders.forEach((et, index) => {
              if (indexToDelete >= 0) return;
              if (this.isTenderEqual(et, m)) {
                indexToDelete = index;
              }
            });
            if (indexToDelete < 0) return;
            this.privateConfig.excluded_payment_methods.mealplan.splice(indexToDelete, 1);
          });
        }
      }
    },
    active_brand: {
      async handler(brand) {
        if (!brand || !brand.id || brand.id !== this.$route.params.brand_id) return;

        this.$store.commit('adminPanel/setViewTitle', {
          depth: 3,
          title: brand.name,
          breadcrumb: 'Settings',
          to: {
            name: 'brand-settings',
            params: {
              site_id: this.$route.params.site_id,
              brand_id: brand.id,
            },
          },
        });
        this.brand = Object.assign(this.brand, cloneDeep(brand));
      },
      immediate: true,
    },
    brandCompany: {
      async handler(newValue, oldValue) {
        if (!newValue || newValue === oldValue) return;
        this.getCompanyMenus(newValue);
      },
      immediate: true,
    },
    currentLocation: {
      async handler(newValue, oldValue) {
        if (!newValue || newValue === oldValue) return;
        const locationDetails = this.active_site?.locations.find((loc) => loc.id === newValue);

        if (
          locationDetails &&
          locationDetails.market_place &&
          locationDetails.market_place.delivery_details
        ) {
          this.$set(
            this.publicConfig,
            'show_single_timeslot',
            locationDetails.market_place.delivery_details.show_single_timeslot,
          );
          this.$set(
            this.publicConfig,
            'show_instructions',
            locationDetails.market_place.delivery_details.show_instructions,
          );
          this.$set(
            this.publicConfig,
            'runner_app_enabled',
            locationDetails.market_place.delivery_details.runner_app_enabled,
          );
        }
      },
      immediate: true,
    },
    willEnableMarketplaceDelivery: {
      immediate: true,
      async handler(newValue) {
        const currentLocation = this.locationMap[this.currentLocation];
        if (newValue && currentLocation) {
          const brandThatMeetsCriteria = currentLocation.brands.find(
            (brand) => brand.is.delivery_supported && !brand.timeslots.delivery_is_user_defined,
          );
          const brandConfig = await this.getConfigPublic({ id: brandThatMeetsCriteria.id });

          this.$set(this.publicConfig, 'delivery_destinations', brandConfig.delivery_destinations);
          this.$set(
            this.publicConfig,
            'delivery_destinations_automatic_timeslot',
            brandConfig.delivery_destinations_automatic_timeslot,
          );
          this.$set(this.publicConfig, 'show_single_timeslot', brandConfig.show_single_timeslot);
          this.$set(this.publicConfig, 'show_instructions', brandConfig.show_instructions);
          this.$set(this.publicConfig, 'runner_app_enabled', brandConfig.runner_app_enabled);
        }
      },
    },
    isMarketplaceDisabled: {
      immediate: true,
      handler(newValue) {
        if (!newValue) return;

        const currentLocation = this.locationMap[this.currentLocation];
        if (currentLocation?.market_place?.delivery_destinations) {
          this.$set(
            this.publicConfig,
            'delivery_destinations',
            currentLocation.market_place.delivery_destinations,
          );
          this.$set(
            this.publicConfig,
            'delivery_destinations_automatic_timeslot',
            currentLocation.market_place.delivery_destinations,
          );
        }

        if (currentLocation?.market_place?.delivery_details) {
          this.$set(
            this.publicConfig,
            'show_single_timeslot',
            currentLocation.market_place.delivery_details.show_single_timeslot,
          );
          this.$set(
            this.publicConfig,
            'show_instructions',
            currentLocation.market_place.delivery_details.show_instructions,
          );
          this.$set(
            this.publicConfig,
            'runner_app_enabled',
            currentLocation.market_place.delivery_details.runner_app_enabled,
          );
        }
      },
    },
    isCentricOSToggledOn: {
      async handler() {
        await this.$nextTick();
        this.$refs.form.validate();
      },
    },
  },
  async mounted() {
    // get delivery destinations at the site level
    let siteDeliveryDestinations = [];
    const { site_id } = this.$route.params;
    const isDH = this.isDHSite(site_id);
    if (!isDH) {
      siteDeliveryDestinations = await this.getDeliveryDestinations({
        id: site_id,
      });
    }
    this.$set(this, 'siteDeliveryDestinations', siteDeliveryDestinations);

    if (!this.isNew) {
      const [sitePublicConfig, sitePrivateConfig] = await Promise.all([
        this.getConfigPublic({ id: site_id }).catch((err) => {
          console.error(err);
        }),
        this.getConfigPrivate({ id: site_id }).catch((err) => {
          console.error(err);
        }),
        this.fetchPublicConfig(this.brand_id),
        this.fetchPrivateConfig(this.brand_id),
      ]);

      if (!sitePublicConfig.delivery_destinations) {
        sitePublicConfig.delivery_destinations = [];
      }

      // set frictionless brand config
      this.$set(
        this.frictionlessState,
        'brand_frictionless_supported',
        this.brand.is.frictionless_supported,
      );
      if (sitePublicConfig.cashless?.id)
        this.$set(this.publicConfig, 'cashless', { id: sitePublicConfig.cashless.id });

      this.sitePublicConfig = sitePublicConfig;
      this.sitePrivateConfig = sitePrivateConfig;

      if (this.brand && this.brand.id) {
        this.loading = false;
      }
    } else {
      this.$store.commit('sites/setBrand', cloneDeep(this.brand));
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 2,
        title: 'Add a new Station',
        to: { name: 'brand-new', params: { site_id } },
      });
      this.$set(this.publicConfig, 'calorie_disclaimer', this.getDefaultCalorieDisclaimer());
      await Promise.all([this.fetchPublicConfig(site_id), this.fetchPrivateConfig(site_id)]);
      this.sitePublicConfig = cloneDeep(this.publicConfig);
      this.$set(this.publicConfig, 'mealplan', []);
      if (this.publicConfig.cashless?.id)
        this.$set(this.publicConfig, 'cashless', { id: this.publicConfig.cashless.id });
      this.$set(this.publicConfigBackup, 'mealplan', []);
      this.sitePrivateConfig = cloneDeep(this.privateConfig);
      this.loading = false;
    }
    // need to account for mealplan changes which involve private/public configs
    const isCbord =
      this.publicConfig.mealplan &&
      this.publicConfig.mealplan[0] &&
      this.publicConfig.mealplan[0].type &&
      this.publicConfig.mealplan[0].type.includes(MEALPLAN_TYPES.CBORD);
    if (
      this.privateConfig.mealplan &&
      Array.isArray(this.privateConfig.mealplan) &&
      this.privateConfig.mealplan.length !== 0 &&
      this.mealplanType &&
      !this.isTypeItc(this.mealplanType)
    ) {
      this.publicConfig.mealplan = this.fuseMealplan(
        this.publicConfig.mealplan[0],
        this.privateConfig.mealplan[0],
      );
      this.publicConfigBackup = cloneDeep(this.publicConfig);
    }
    if (isCbord) {
      this.publicConfig.mealplan = this.fuseMealplan(
        this.publicConfig.mealplan[0],
        this.privateConfig,
      );
      this.publicConfigBackup = cloneDeep(this.publicConfig);
    }

    // set frictionless site config
    try {
      const frictionless_supported = await this.isSiteFrictionless(site_id);
      this.frictionlessState.site_frictionless_supported = frictionless_supported;
    } catch (error) {
      console.error('could not fetch frictionless config data', error);
      this.frictionlessState.site_frictionless_supported = false;
    }

    this.$store.commit('adminPanel/setLoading', false);
  },
};
</script>

<style>
.side-menu {
  position: relative;
  height: 100%;
}
</style>
