<template>
  <v-card class="v-card-custom">
    <v-layout row wrap fill-height>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs12>
            <div class="H5-Primary-Left">Scan & Go Setup</div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-layout mt-1 xs12>
          <v-flex style="flex-direction: column;">
            <div>
              <h5 class="Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium">
                Enable Scan & Go?
              </h5>
            </div>
            <toggle
              :position="'start'"
              class="ml-0"
              v-model="toggleScanAndGo"
              :disabled="isSectionDisabled"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
export default {
  data: () => ({}),
  props: {
    enable: {
      type: Boolean,
    },
    isSectionDisabled: {
      type: Boolean,
    },
  },
  computed: {
    toggleScanAndGo: {
      get() {
        return this.enable;
      },
      set(b) {
        this.$emit('update:enable', b);
      },
    },
  },
  methods: {
    log_site() {},
  },
};
</script>

<style scoped>
.custom-warning {
  position: relative;
  width: fit-content;
  background: #fff2cf;
  border: 1px solid #ed892c;
  box-sizing: border-box;
  border-radius: 4px;
}
.custom-warning-font {
  width: fit-content;
  font-family: Avenir, Sans-Serif;
  font-style: bold;
  font-weight: 500;
  font-size: 12px;
  color: #9d4c00;
}
.svg-logo {
  float: left;
  margin-right: 5px;
}
</style>
