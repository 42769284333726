import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { attrs: { row: "", wrap: "" } },
    [
      _c(
        VFlex,
        { attrs: { xs9: "" } },
        [
          _c(
            VDataTable,
            {
              ref: "timeslot-table",
              staticClass: "timeslot-table",
              attrs: {
                "hide-actions": "",
                headers: _vm.headers,
                items: _vm.timeslots,
                "custom-sort": _vm.sortTable
              },
              scopedSlots: _vm._u([
                {
                  key: "items",
                  fn: function(ref) {
                    var item = ref.item
                    var index = ref.index
                    return [
                      _c(
                        "tr",
                        {
                          staticClass: "timeslot-row",
                          on: {
                            click: function($event) {
                              return _vm.editTimeslot(item, index)
                            }
                          }
                        },
                        [
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.formatTime(item.start_time, item.end_time)
                              )
                            )
                          ]),
                          item.delivery_destinations
                            ? _c("td", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.formatLocation(
                                        item.delivery_destinations
                                      )
                                    ) +
                                    "\n          "
                                )
                              ])
                            : _c("td", [
                                !_vm.isBrandSectionDisabled
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "add-location",
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.editTimeslot(item, index)
                                          }
                                        }
                                      },
                                      [_vm._v("Add Location")]
                                    )
                                  : _vm._e()
                              ]),
                          _c(
                            "td",
                            [
                              !_vm.isBrandSectionDisabled
                                ? _c(
                                    VBtn,
                                    {
                                      attrs: { color: "primary", flat: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.removeTimeslot(item)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        {
                                          staticClass: "mr-2",
                                          attrs: { left: "" }
                                        },
                                        [_vm._v("mdi-delete-outline")]
                                      ),
                                      _vm._v("Remove\n            ")
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("template", { slot: "no-data" }, [
                _c("p", { staticClass: "Body-2-Black-High-Emphasis-Center" }, [
                  _vm._v("No data available")
                ])
              ])
            ],
            2
          )
        ],
        1
      ),
      !_vm.isBrandSectionDisabled
        ? _c(
            VDialog,
            {
              attrs: { width: "914px" },
              on: {
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape"
                    ])
                  ) {
                    return null
                  }
                  return _vm.handleDialogCancel($event)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          VFlex,
                          {
                            staticStyle: { "padding-bottom": "32px" },
                            attrs: { xs12: "" }
                          },
                          [
                            _c(
                              VBtn,
                              _vm._g(
                                {
                                  attrs: { color: "primary", width: "188px" },
                                  on: { click: _vm.resetDialog }
                                },
                                on
                              ),
                              [
                                _c(
                                  VIcon,
                                  { attrs: { left: "", dark: "" } },
                                  [_vm._v("mdi-plus")]
                                ),
                                _vm._v("Add Timeslot\n        ")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2873598576
              ),
              model: {
                value: _vm.open,
                callback: function($$v) {
                  _vm.open = $$v
                },
                expression: "open"
              }
            },
            [
              _c("timeslot-dialog", {
                attrs: {
                  siteDeliveryDestinations: _vm.siteDeliveryDestinations,
                  timeslot: _vm.activeTimeslot && _vm.activeTimeslot.timeslot
                },
                on: {
                  cancelDialog: _vm.handleDialogCancel,
                  saveDialog: _vm.handleDialogSave
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }