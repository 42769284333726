<template>
  <v-layout row wrap>
    <v-flex shrink xs2>
      <v-btn :disabled="isBrandSectionDisabled" icon ripple @click="$emit('menu:delete')">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
    </v-flex>
    <v-flex xs10>
      <v-select
        :items="getMenusToDisplay"
        :label="label"
        placeholder
        :rules="menuRules"
        v-model="menu_id_sync"
        :disabled="disabled || isBrandSectionDisabled || isCentricOS"
      ></v-select>
    </v-flex>
    <v-flex xs12>
      <v-layout row wrap justify-end v-if="isMenuSelected">
        <v-flex xs10>
          <assign-categories
            :assignedCategories.sync="this.assignedMenus[this.menuIndex].groups"
            :allCategories="this.assignedMenus[this.menuIndex].allGroups"
            :isBrandSectionDisabled="isBrandSectionDisabled"
            :menuDisabled="isMenuDisabled"
            :isCentricOS="isCentricOS"
            ref="assignedCategoriesComponent"
          />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex';
import assignCategories from './assign_categories';

export default {
  components: {
    assignCategories,
  },
  props: {
    menu_id: String,
    label: String,
    menuRules: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
    isBrandSectionDisabled: {
      type: Boolean,
      default: false,
    },
    assignedMenus: Array,
    allMenus: Array,
    menuIndex: Number,
    currentBrandMenus: Array,
    isCentricOS: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMenuDisabled() {
      const currentMenu = this.assignedMenus[this.menuIndex];
      return (currentMenu.is && currentMenu.is.disabled) || false;
    },
    isMenuSelected() {
      return !!this.menu_id;
    },
    getMenusToDisplay() {
      if (!this.allMenus || this.allMenus.length === 0) return [];
      const menus = this.allMenus.filter(
        (menu) => !this.assignedMenus.find((d) => d.parent_id === menu.value) && !menu.is.disabled,
      );
      const currentMenu = this.allMenus.find((m) => m.value === this.menu_id);
      if (currentMenu) {
        menus.push(currentMenu);
      }
      return menus;
    },
    menu_id_sync: {
      get() {
        return this.menu_id;
      },
      async set(v) {
        this.$emit('update:menu_id', v);
        if (v) await this.loadCategories(v);
      },
    },
  },
  methods: {
    ...mapActions('menus', ['fetchMenu']),
    async loadCategories(menu_id) {
      if (!menu_id) return;
      // we get this global menu to get categories
      let localCategories = [];
      let globalCategories = [];

      try {
        const globalMenuDetails = await this.fetchMenu({
          id: menu_id,
          _query: '{id,label,is,groups{id,label,is}}',
          nocache: true,
        });

        globalCategories = globalMenuDetails.groups.map((g) => {
          if (!g.label || !g.label.en) {
            g.label = { en: 'Untitled' };
          }
          return {
            id: g.id,
            value: g.id,
            text: g.label.en,
            isLinked: true,
            isDisabled: g.is.disabled || false,
          };
        });

        const localMenu = this.currentBrandMenus.find((m) => m.parent_id === menu_id);
        if (localMenu) {
          const localMenuDetails = await this.fetchMenu({
            id: localMenu.id,
            _query: '{id,label,is,groups{id,label,is}}',
            nocache: true,
          });
          localCategories = localMenuDetails.groups
            .filter((g) => g.is && g.is.linked)
            .map((g) => {
              if (!g.label || !g.label.en) {
                g.label = { en: 'Untitled' };
              }
              return {
                id: g.id,
                value: g.id,
                text: g.label.en,
                isLinked: true,
                isDisabled: g.is.disabled || false,
              };
            });
          const disabledLinkedCategories = globalCategories.filter(
            (g) =>
              g.isDisabled &&
              localMenuDetails.groups.find((m) => m.id === g.id && m.is && m.is.linked),
          );
          localCategories.push(...disabledLinkedCategories);
        } else {
          localCategories = globalCategories.filter((g) => !g.isDisabled);
        }
      } catch {
        this.$toast.error('Could not fetch menu details for menu categories.');
      }
      this.$set(this.assignedMenus[this.menuIndex], 'groups', [...localCategories]);
      this.$set(this.assignedMenus[this.menuIndex], 'allGroups', [...globalCategories]);
    },
  },
};
</script>
