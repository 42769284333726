import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "v-card-custom" },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "", "fill-height": "" } },
        [
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(
                VLayout,
                { attrs: { row: "", wrap: "" } },
                [
                  _c(VFlex, { attrs: { xs12: "" } }, [
                    _c("div", { staticClass: "H5-Primary-Left" }, [
                      _vm._v("Third Party Integration")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(
                VLayout,
                { attrs: { "mt-1": "", xs12: "" } },
                [
                  _c(
                    VFlex,
                    {
                      staticClass: "d-flex",
                      staticStyle: { "flex-direction": "column" },
                      attrs: { xs6: "" }
                    },
                    [
                      _c("div", [
                        _c(
                          "h5",
                          {
                            staticClass:
                              "Subtitle-1-Black-Medium-Emphasis-Left font-weight-medium"
                          },
                          [
                            _vm._v(
                              "\n              Request Customer Phone Number at Checkout\n            "
                            )
                          ]
                        )
                      ]),
                      _c("toggle", {
                        staticClass: "ml-0",
                        attrs: {
                          value: _vm.getPhoneNumber,
                          position: "start",
                          disabled: _vm.isSectionDisabled
                        },
                        on: {
                          input: function($event) {
                            return _vm.$emit("update:getPhoneNumber", $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }